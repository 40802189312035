import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Button,
    PageTitle,
    Panel,
    Row,
    Column,
    useAddToast,
    Loading,
    DraftJSOutput,
} from "@cortexglobal/rla-components";

import { CubeLoader, ButtonDropdown } from "@cortexglobal/lens-components";
import { useHistory } from "react-router";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CampaignContainer = styled.div`
    padding-top: 2em;
`;

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div`
    & td,
    th {
        padding-bottom: 1em;
    }
`;

const TagContainer = styled.div`
    display: flex;
`;

const Tag = styled.div`
    background: orange;
    color: white;
    border-radius: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0 1em;
    margin-left: 1em;
    text-transform: uppercase;
`;

const BoldTd = styled.td`
    font-weight: bold;
    width: 300px;
`;

const CampaignTable = styled.table`
    width: 100%;
    border-spacing: 0;

    tr:nth-child(odd) {
        background: #f7f7f7;
    }

    td {
        padding: 1rem;
    }
`;

const ViewCampaign = ({ match }) => {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [campaignData, setCampaignData] = useState();
    const [updateLoading, setUpdateLoading] = useState(false);
    const addToast = useAddToast();

    const getCampaign = () => {
        setLoading(true);

        axios
            .get(`/api/v1/campaigns/${match.params.id}`)
            .then(({ data }) => {
                setCampaignData(data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting campaigns.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const handleActivateCampaign = () => {
        axios
            .put(`/api/v1/campaigns/activate`, {
                ids: [campaignData.id],
            })
            .then(({ data }) => {
                updateStatusVisual("active");
                setUpdateLoading(false);

                addToast({
                    type: "success",
                    content: "Campaign successfully activated",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdateLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error activating your campaign.",
                    showFor: 5000,
                });
            });
    };

    const handleDisableCampaign = () => {
        axios
            .put(`/api/v1/campaigns/disable`, {
                ids: [campaignData.id],
            })
            .then(({ data }) => {
                updateStatusVisual("draft");
                setUpdateLoading(false);

                addToast({
                    type: "success",
                    content: "Campaign successfully disabled",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdateLoading(false);

                addToast({
                    type: "alert",
                    content: "There was an error disabling your campaign.",
                    showFor: 5000,
                });
            });
    };

    const handleDeleteCampaign = () => {
        axios
            .put(`/api/v1/campaigns/delete`, {
                ids: [campaignData.id],
            })
            .then(({ data }) => {
                updateStatusVisual("deleted");
                setUpdateLoading(false);

                addToast({
                    type: "success",
                    content: "Campaign successfully deleted",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                setUpdateLoading(false);

                addToast({
                    type: "alert",
                    content: "There was an error deleting your campaign.",
                    showFor: 5000,
                });
            });
    };

    const updateStatusVisual = (status) => {
        setCampaignData({
            ...campaignData,
            status: status,
        });
    };

    useEffect(() => {
        getCampaign();
    }, []);

    return (
        <>
            <PageTitle
                title={
                    <TagContainer>
                        View Campaign:{" "}
                        {campaignData ? campaignData.title : `Loading...`}
                        {campaignData && <Tag>{campaignData.status}</Tag>}
                    </TagContainer>
                }
                expanded
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: "1em" }}>
                        <ButtonDropdown
                            onClick={() => {
                                history.push("/campaigns");
                            }}
                            actions={[]}
                            name="primary"
                        >
                            <FontAwesomeIcon icon={faCaretLeft} /> Back to
                            Campaigns
                        </ButtonDropdown>
                    </span>
                    {campaignData && (
                        <span>
                            <ButtonDropdown
                                onClick={() => {
                                    history.push(
                                        `/campaigns/${match.params.id}/edit`
                                    );
                                }}
                                name="primary"
                                actions={[
                                    ...(campaignData.status !== "active"
                                        ? [
                                              {
                                                  name: "Activate",
                                                  onClick: () => {
                                                      setUpdateLoading(true);
                                                      handleActivateCampaign();
                                                  },
                                              },
                                          ]
                                        : []),
                                    ...(campaignData.status !== "draft"
                                        ? [
                                              {
                                                  name: "Disable",
                                                  onClick: () => {
                                                      setUpdateLoading(true);
                                                      handleDisableCampaign();
                                                  },
                                              },
                                          ]
                                        : []),
                                    ...(campaignData.status !== "deleted"
                                        ? [
                                              {
                                                  name: "Delete",
                                                  onClick: () => {
                                                      setUpdateLoading(true);
                                                      handleDeleteCampaign();
                                                  },
                                              },
                                          ]
                                        : []),
                                ]}
                            >
                                Edit
                            </ButtonDropdown>
                        </span>
                    )}

                    {updateLoading && (
                        <span
                            style={{
                                marginLeft: "2em",
                                marginTop: "0.5em",
                            }}
                        >
                            <CubeLoader size={20} margin="0" />
                        </span>
                    )}
                </div>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && campaignData && (
                <CampaignContainer>
                    <Row>
                        <Column>
                            <Panel style={{ padding: "0" }}>
                                <PanelHeader>Campaign Details</PanelHeader>
                                <PanelBody>
                                    <CampaignTable>
                                        <tbody>
                                            <tr>
                                                <BoldTd>ID:</BoldTd>
                                                <td>{campaignData.id}</td>
                                            </tr>
                                            <tr>
                                                <BoldTd>Title:</BoldTd>
                                                <td>{campaignData.title}</td>
                                            </tr>
                                            {campaignData.slug && (
                                                <tr>
                                                    <BoldTd>Slug:</BoldTd>
                                                    <td>{campaignData.slug}</td>
                                                </tr>
                                            )}
                                            {campaignData.campaign_type ? (
                                                <tr>
                                                    <BoldTd>Type:</BoldTd>
                                                    <td>
                                                        {
                                                            campaignData
                                                                .campaign_type
                                                                .name
                                                        }
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr>
                                                    <BoldTd>Type:</BoldTd>
                                                    <td>
                                                        {
                                                            campaignData.type_alias
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            {campaignData.date_from && (
                                                <tr>
                                                    <BoldTd>Date From:</BoldTd>
                                                    <td>
                                                        {new Date(
                                                            campaignData.date_from
                                                        ).toDateString()}
                                                    </td>
                                                </tr>
                                            )}
                                            {campaignData.date_to && (
                                                <tr>
                                                    <BoldTd>Date To:</BoldTd>
                                                    <td>
                                                        {new Date(
                                                            campaignData.date_to
                                                        ).toDateString()}
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <BoldTd>Banner Title:</BoldTd>
                                                <td>
                                                    {campaignData.bannerTitle}
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>Show in Banner:</BoldTd>
                                                <td>
                                                    {campaignData.show_in_banner ? (
                                                        <FontAwesomeIcon
                                                            style={{
                                                                color: "#11bb26",
                                                            }}
                                                            icon={faCheckCircle}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            style={{
                                                                color: "#d11717",
                                                            }}
                                                            icon={faTimesCircle}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>
                                                    Banner Short Description:
                                                </BoldTd>
                                                <td>
                                                    {
                                                        campaignData.bannerShortDescription
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>Banner Link:</BoldTd>
                                                <td>
                                                    {campaignData.bannerLink}
                                                </td>
                                            </tr>
                                            <tr>
                                                <BoldTd>Summary:</BoldTd>
                                                <td
                                                    dangerouslySetInnerHTML={{
                                                        __html: campaignData.summary,
                                                    }}
                                                />
                                            </tr>
                                            <tr>
                                                <BoldTd>
                                                    Terms and Conditions:
                                                </BoldTd>
                                                <td
                                                    dangerouslySetInnerHTML={{
                                                        __html: campaignData.terms_and_conditions,
                                                    }}
                                                />
                                            </tr>
                                            <tr>
                                                <BoldTd>How it Works:</BoldTd>
                                                <td
                                                    dangerouslySetInnerHTML={{
                                                        __html: campaignData.how,
                                                    }}
                                                />
                                            </tr>
                                            <tr>
                                                <BoldTd>
                                                    Dashboard Image:
                                                </BoldTd>
                                                <td>
                                                    {
                                                        campaignData.dashboardImage
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <BoldTd>
                                                    Show in Carousel:
                                                </BoldTd>
                                                <td>
                                                    {campaignData.show_in_carousel ? (
                                                        <FontAwesomeIcon
                                                            style={{
                                                                color: "#11bb26",
                                                            }}
                                                            icon={faCheckCircle}
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            style={{
                                                                color: "#d11717",
                                                            }}
                                                            icon={faTimesCircle}
                                                        />
                                                    )}
                                                </td>
                                            </tr>

                                            {campaignData.thumbnail_image_id && (
                                                <tr>
                                                    <BoldTd>
                                                        Thumbnail Image ID:
                                                    </BoldTd>
                                                    <td>
                                                        {
                                                            campaignData.thumbnail_image_id
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                            {campaignData.customers.length >
                                                0 && (
                                                <tr>
                                                    <BoldTd>
                                                        Customer List:
                                                    </BoldTd>
                                                    <td>
                                                        {campaignData.customers.map(
                                                            (c) => {
                                                                const details =
                                                                    [
                                                                        c.trade_team_id,
                                                                        c.trade_team_customer,
                                                                        c.dealer_name,
                                                                    ];
                                                                return (
                                                                    <>
                                                                        {details.join(
                                                                            ", "
                                                                        )}
                                                                        <br />
                                                                    </>
                                                                );
                                                            }
                                                        )}
                                                    </td>
                                                </tr>
                                            )}
                                            {campaignData.createdAt && (
                                                <tr>
                                                    <BoldTd>Created:</BoldTd>
                                                    <td>
                                                        {campaignData.createdAt &&
                                                            new Date(
                                                                campaignData.createdAt
                                                            ).toDateString()}
                                                    </td>
                                                </tr>
                                            )}
                                            {campaignData.updatedAt && (
                                                <tr>
                                                    <BoldTd>
                                                        Last Updated:
                                                    </BoldTd>
                                                    <td>
                                                        {campaignData.updatedAt &&
                                                            new Date(
                                                                campaignData.updatedAt
                                                            ).toDateString()}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </CampaignTable>
                                </PanelBody>
                            </Panel>
                        </Column>
                    </Row>
                </CampaignContainer>
            )}
        </>
    );
};

export default ViewCampaign;
