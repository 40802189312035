import React from "react";
import { Switch, Route } from "react-router-dom";

// routing
import PrivateRoute from "./components/global/PrivateRoute";
import PublicApp from "./components/global/PublicApp";
import PrivateApp from "./components/global/PrivateApp";

// content
import HeadContent from "./components/global/HeadContent";
import { ToastList } from "@cortexglobal/rla-components";



//styling
import { createGlobalStyle } from "styled-components";
import Theme from "./config/theme";
import globalCss from "./config/globalCss";

const GlobalStyle = createGlobalStyle`${globalCss(Theme)}`;

const App = () => {
    return (
        <div style={{ height: "100%" }}>
            <GlobalStyle />
            <HeadContent />
            <Switch>
                <Route path="/auth" component={PublicApp} />
                <PrivateRoute path="/" component={PrivateApp} />
            </Switch>
            <ToastList />
        </div>
    );
};

export default App;
