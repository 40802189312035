import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import {
    Button,
    Column,
    FormikInput,
    Panel,
    Row,
    SubmitButton,
    SelectField,
    FormLabel,
    MultiCheckbox,
    useAddToast,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    StyledTable,
    StyledTableRow,
    CubeLoader,
} from "@cortexglobal/lens-components";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpring, animated } from "react-spring";

const Schema = Yup.object().shape({
    company_number: Yup.string(),
    trade_team_customer: Yup.string().required("Required"),
    alternative_customer_name: Yup.string(),
    rrdi_code: Yup.string(),
    dealer_code_for_leads: Yup.string(),
    trade_team_customer_type: Yup.string(),
    type: Yup.string(),
    address_1: Yup.string(),
    address_2: Yup.string(),
    address_3: Yup.string(),
    town: Yup.string(),
    county: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
    mobile: Yup.string(),
    trade_team_id: Yup.number(),
});

const CompanyForm = ({ handleSubmit, loading, values }) => {
    const addToast = useAddToast();
    const expandableContainerRef = useRef(null);
    const [tradeTeamCheckLoading, setTradeTeamCheckLoading] = useState(false);
    const [tradeTeamCheckError, setTradeTeamCheckError] = useState(false);
    const [phoneMatchError, setPhoneMatchError] = useState(false);
    const [customerTypes, setCustomerTypes] = useState([]);

    const [customerTypesExpanded, setCustomerTypesExpanded] = useState(false);
    const [style, animate] = useSpring(() => ({ height: "0px" }), []);
    useEffect(() => {
        animate({
            height:
                (customerTypesExpanded
                    ? expandableContainerRef.current.offsetHeight + 2
                    : 300) + "px",
        });
    }, [animate, expandableContainerRef, customerTypesExpanded]);

    const [tradeTeamId, setTradeTeamId] = useState(
        values && values.trade_team_id ? values.trade_team_id : 0
    );

    const queryString = new URLSearchParams(useLocation().search);
    const id = queryString.get("id") || "";

    const getCustomerTypes = () => {
        axios
            .get(`/api/v1/customers/types`)
            .then(({ data }) => {
                setCustomerTypes(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting customer types.",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getCustomerTypes();
    }, []);

    const checkTradeTeamId = (setFieldValue) => {
        setTradeTeamCheckLoading(true);
        setTradeTeamCheckError(false);

        axios
            .get(`/api/v1/next-trade-team`)
            .then(({ data }) => {
                setTradeTeamCheckLoading(false);
                setTradeTeamId(data.data.trade_team_id);
                setFieldValue("trade_team_id", data.data.trade_team_id);
            })
            .catch((error) => {
                setTradeTeamId("");
                setTradeTeamCheckError(true);
                setTradeTeamCheckLoading(false);
            });
    };

    const csvStringToArray = (csvString) => {
        let csvArray = csvString.split(",").map(function (item) {
            return item.trim();
        });
        return csvArray;
    };

    const handlePhoneBlur = async (event, trade_team_id) => {
        const phone = event.target.value;
        const data = { phone };

        if (trade_team_id !== null && trade_team_id !== 0) {
            data.trade_team_id = trade_team_id;
        }
        try {
            const response = await axios.post(
                "/api/v1/customers/phone-number-check",
                data
            );
            const found = response.data.data.found;
            setPhoneMatchError(found);
        } catch (error) {
            console.error("Error checking phone number:", error);
            setPhoneMatchError(false);
        }
    };

    return (
        <Panel style={{ padding: "2em" }}>
            <Formik
                initialValues={{
                    company_number:
                        values && values.company_number
                            ? values.company_number
                            : "",
                    trade_team_customer:
                        values && values.trade_team_customer
                            ? values.trade_team_customer
                            : "",
                    alternative_customer_name:
                        values && values.alternative_customer_name
                            ? values.alternative_customer_name
                            : "",
                    rrdi_code:
                        values && values.rrdi_code ? values.rrdi_code : "",
                    dealer_code_for_leads:
                        values && values.dealer_code_for_leads
                            ? values.dealer_code_for_leads
                            : "",
                    trade_team_customer_type:
                        values && values.trade_team_customer_type
                            ? values.trade_team_customer_type
                            : "",
                    type: values && values.type ? values.type : "",
                    address_1:
                        values && values.address_1 ? values.address_1 : "",
                    address_2:
                        values && values.address_2 ? values.address_2 : "",
                    address_3:
                        values && values.address_3 ? values.address_3 : "",
                    town: values && values.town ? values.town : "",
                    county: values && values.county ? values.county : "",
                    postcode: values && values.postcode ? values.postcode : "",
                    email: values && values.email ? values.email : "",
                    phone: values && values.phone ? values.phone : "",
                    customer_type:
                        values && values.customer_type
                            ? values.customer_type
                            : "",
                    mobile: values && values.mobile ? values.mobile : "",
                    vat: values && values.vat ? values.vat : "",
                    brand_specialist:
                        values && values.brand_specialist
                            ? values.brand_specialist
                            : "",
                    mot_centre:
                        values && values.mot_centre ? values.mot_centre : "",
                    number_of_bays:
                        values && values.number_of_bays
                            ? values.number_of_bays
                            : "",
                    number_of_technicians:
                        values && values.number_of_technicians
                            ? values.number_of_technicians
                            : 0,
                    number_of_trade_team_programmes:
                        values && values.number_of_trade_team_programmes
                            ? values.number_of_trade_team_programmes
                            : "",
                    average_vehicles_per_week:
                        values && values.average_vehicles_per_week
                            ? values.average_vehicles_per_week
                            : "",
                    average_weekly_spend_brand_parts:
                        values && values.average_weekly_spend_brand_parts
                            ? values.average_weekly_spend_brand_parts
                            : "",
                    average_age_vehicles:
                        values && values.average_age_vehicles
                            ? values.average_age_vehicles
                            : "",

                    trade_team_id: tradeTeamId,
                }}
                onSubmit={handleSubmit}
                validationSchema={Schema}
            >
                {({
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleBlur,
                    errors,
                }) => {
                    return (
                        <Form>
                            <Row>
                                <Column>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ marginRight: "1em" }}>
                                            <FormikInput
                                                value={tradeTeamId}
                                                width="100%"
                                                labelWidth={100}
                                                name="trade_team_id"
                                                label="Trade Team ID"
                                            />
                                        </div>
                                        {!values.trade_team_id && (
                                            <Button
                                                type="button"
                                                onClick={() =>
                                                    checkTradeTeamId(
                                                        setFieldValue
                                                    )
                                                }
                                            >
                                                Generate Next Trade Team ID
                                            </Button>
                                        )}
                                        {tradeTeamCheckLoading && (
                                            <span
                                                style={{
                                                    marginRight: "2em",
                                                    marginTop: "0.5em",
                                                    marginLeft: "1.5em",
                                                }}
                                            >
                                                <CubeLoader
                                                    size={20}
                                                    margin="0"
                                                />
                                            </span>
                                        )}
                                    </div>

                                    <FormikInput
                                        value={values.company_number}
                                        width="100%"
                                        labelWidth={100}
                                        name="company_number"
                                        label="Company Number"
                                    />
                                    <FormikInput
                                        value={values.trade_team_customer}
                                        width="100%"
                                        labelWidth={100}
                                        name="trade_team_customer"
                                        label="Customer Name"
                                    />
                                    <FormikInput
                                        value={values.alternative_customer_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="alternative_customer_name"
                                        label="Alternative Customer Name"
                                    />
                                    <FormikInput
                                        value={values.address_1}
                                        width="100%"
                                        labelWidth={100}
                                        name="address_1"
                                        label="Address 1"
                                    />
                                    <FormikInput
                                        value={values.address_2}
                                        width="100%"
                                        labelWidth={100}
                                        name="address_2"
                                        label="Address 2"
                                    />
                                    <FormikInput
                                        value={values.address_3}
                                        width="100%"
                                        labelWidth={100}
                                        name="address_3"
                                        label="Address 3"
                                    />
                                    <FormikInput
                                        value={values.town}
                                        width="100%"
                                        labelWidth={100}
                                        name="town"
                                        label="Town"
                                    />
                                    <FormikInput
                                        value={values.county}
                                        width="100%"
                                        labelWidth={100}
                                        name="county"
                                        label="County"
                                    />
                                    <FormikInput
                                        type="text"
                                        name="postcode"
                                        label="Post Code"
                                        value={values.postcode}
                                    />

                                    <FormikInput
                                        type="text"
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                    />

                                    {phoneMatchError && (
                                        <span>Phone Number Already Exists</span>
                                    )}
                                    <FormikInput
                                        type="text"
                                        name="phone"
                                        label="Phone Number"
                                        value={values.phone}
                                        onBlur={(event) =>
                                            handlePhoneBlur(
                                                event,
                                                values.trade_team_id
                                            )
                                        }
                                        style={{
                                            backgroundColor: phoneMatchError
                                                ? "#D9544D"
                                                : "",
                                            color: phoneMatchError
                                                ? "white"
                                                : "",
                                        }}
                                    />

                                    <FormikInput
                                        type="text"
                                        name="customer_type"
                                        label="Type"
                                        value={values.customer_type}
                                    />

                                    <FormLabel>
                                        Distrigo Customer Type
                                    </FormLabel>

                                    <div style={{ position: "relative" }}>
                                        <Button
                                            style={{
                                                position: "absolute",
                                                top: "1rem",
                                                right: "1.4rem",
                                                padding: "0 0.6rem",
                                                zIndex: 1,
                                            }}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                setCustomerTypesExpanded(
                                                    !customerTypesExpanded
                                                );
                                            }}
                                            height={26}
                                        >
                                            {customerTypesExpanded ? (
                                                <>
                                                    Collapse{" "}
                                                    <FontAwesomeIcon
                                                        icon={faMinus}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    Expand{" "}
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                    />
                                                </>
                                            )}
                                        </Button>

                                        <animated.div
                                            style={{
                                                minHeight:
                                                    "calc(15px + 2.4rem)",
                                                margin: "0 auto 1rem",
                                                border: "1px solid #aaafb7",
                                                overflow: "auto",
                                                width: "100%",
                                                ...style,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    padding:
                                                        "1.2rem 1.2rem 1px",
                                                }}
                                                ref={expandableContainerRef}
                                            >
                                                {!customerTypes.length && (
                                                    <CubeLoader
                                                        size={20}
                                                        margin="0.5rem"
                                                    />
                                                )}
                                                <MultiCheckbox
                                                    name="trade_team_customer_type"
                                                    value={csvStringToArray(
                                                        values.trade_team_customer_type
                                                    )}
                                                    options={customerTypes}
                                                    onChange={({ value }) => {
                                                        setFieldValue(
                                                            "trade_team_customer_type",
                                                            value.join(", ")
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </animated.div>
                                    </div>

                                    <FormikInput
                                        type="text"
                                        name="vat"
                                        label="VAT Number"
                                        value={values.vat}
                                    />

                                    <FormikInput
                                        type="text"
                                        name="brand_specialist"
                                        label="Brand Specialist"
                                        value={values.brand_specialist}
                                    />
                                    <FormikInput
                                        type="text"
                                        name="mot_centre"
                                        label="Mot Centre"
                                        value={values.mot_centre}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="number_of_bays"
                                        label="Number Of Bays"
                                        value={values.number_of_bays}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="number_of_technicians"
                                        label="Number Of Technicians"
                                        value={values.number_of_technicians}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="number_of_trade_team_programmes"
                                        label="Number Of Trade Team Programmes"
                                        value={
                                            values.number_of_trade_team_programmes
                                        }
                                    />
                                    <FormikInput
                                        type="number"
                                        name="average_vehicles_per_week"
                                        label="Average Vehicles Per Week"
                                        value={values.average_vehicles_per_week}
                                    />

                                    <FormikInput
                                        type="number"
                                        name="average_vehicles_per_brand_parts"
                                        label="Average Vehicles Per Brand Part"
                                        value={
                                            values.average_vehicles_per_brand_parts
                                        }
                                    />

                                    <FormikInput
                                        type="number"
                                        name="average_age_vehicles"
                                        label="Average Age Of Vehicles"
                                        value={values.average_age_vehicles}
                                    />

                                    <FormikInput
                                        value={values.rrdi_code}
                                        width="100%"
                                        labelWidth={100}
                                        name="rrdi_code"
                                        label="RRDI Code"
                                    />
                                    <FormikInput
                                        value={values.dealer_code_for_leads}
                                        width="100%"
                                        labelWidth={100}
                                        name="dealer_code_for_leads"
                                        label="Dealer Code For Leads"
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <SubmitButton submitting={loading}>
                                        Save Customer
                                    </SubmitButton>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Panel>
    );
};

export default CompanyForm;
