import React from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Header from "./PublicHeader";
import { LoginForm } from "@cortexglobal/lens-auth";

import {
    ResetPassword
} from "@cortexglobal/lens-components";
// const Container = styled.div`
//     width: 100%;
//     max-width: 20rem;
//     margin: auto;
//     padding: 20px;
//     border: 1px solid #ddd;
//     background: white;
//     margin-top: 20px;
// `;


const AuthWrapper = styled.div`
    align-items: center;
    justify-content: center;

    height: 100%;
    display: flex;
    flex-direction: column;
`;

const AuthContainer = styled.div`
    padding: 3em;
    border-radius: 4px;
    width: 50%;
    max-width: 500px;
    flex-grow: 1;
`;

const PublicApp = () => {
    return (
        <>
            <Header />

            <Switch>
                <AuthWrapper>
                    <AuthContainer>
                        <Route path="/auth/login" component={LoginForm} />
                        <Route path="/auth/reset-password" component={ResetPassword} />
                    </AuthContainer>
                </AuthWrapper>

            </Switch>
        </>
    );
};

export default PublicApp;
