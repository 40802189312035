import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AddPointsCampaign from "./AddPointsCampaign";
import PointsCampaignsList from "./PointsCampaignsList";
import ViewPointsCampaign from "./ViewPointsCampaign";
import EditPointsCampaign from "./EditPointsCampaign";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";

const PointsCampaigns = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/add`} component={AddPointsCampaign}/>
                <Route path={`${match.path}/edit/:id`} component={EditPointsCampaign}/>
                <Route path={`${match.path}/:id`} component={ViewPointsCampaign}/>
                <Route path={`${match.path}`} component={PointsCampaignsList}/>
            </Switch>
        </>
    );
};

export default PointsCampaigns;
