import React, { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import {
    Button,
    useAddToast,
    SelectField,
    FormikCheckbox,
    Row,
    Column,
    SubmitButton,
} from "@cortexglobal/rla-components";
import { FormikInput } from "@cortexglobal/rla-components";
import * as Yup from "yup";

const SubscribeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    background: #f9f9f9;
`;

const StyledPanel = styled.div`
    background: white;
    box-shadow: 0 0 7px 2px rgba(37, 37, 37, 0.05);
    padding: 3em !important;
    margin-top: 3em;
    max-width: 500px;

    h1 {
        font-size: 1.5em;
        margin-bottom: 1em;
    }

    p {
        margin: 0;
    }
`;

const Logo = styled.img`
    width: 350px;
`;

const Schema = Yup.object().shape({
    code: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
});

const Unsubscribe = () => {
    const queryString = new URLSearchParams(useLocation().search);
    const email = queryString.get("email") || "";
    const code = queryString.get("code") || "";
    const id = queryString.get("id") || "";
    const [customerCheckError, setCustomerCheckError] = useState(false);

    const handleSubmit = (value) => {
        console.log("test");
        setLoading(true);

        axios
            .post(`/api/v1/distrigo/store/unsubscribe/${match.params.id}`, {
                code: code,
                email: email,
            })
            .then(({ data }) => {
                console.log(data);
                history.push(`/companies/${data.data.customer_id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error updating your company.",
                    showFor: 5000,
                });
            });
    };

    return (
        <SubscribeContainer>
            <StyledPanel>
                {/*                <Formik
                    initialValues={{
                        code: code,
                        email: email,
                        can_email: false,
                        can_sms: false,
                        can_dm: false,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Schema}
                >
                    {({
                          isSubmitting,
                          values,
                          setFieldValue,
                          handleBlur,
                          errors
                      }) => {
                        return (
                            <Form>
                                <Row>
                                    <Column>

                                        <FormikCheckbox
                                            type="text"
                                            name="channel_email"
                                            label="Can Send Email"
                                            value={values.can_email}
                                        />
                                        <FormikCheckbox
                                            type="text"
                                            name="channel_sms"
                                            label="Can Send SMS"
                                            value={values.can_sms}
                                        />
                                        <FormikCheckbox
                                            type="text"
                                            name="channel_post"
                                            label="Can Send Post"
                                            value={values.channel_post}
                                        />
                                    </Column>
                                </Row>
                                <Row>
                                    <Column style={{
                                        textAlign: 'right'
                                    }}>
                                        <SubmitButton
                                            submitting={loading}
                                        >
                                            Save Contact
                                        </SubmitButton>
                                    </Column>
                                </Row>
                            </Form>
                        )
                    }}
                </Formik>*/}
                <h1>Unsubscribe:</h1>
                <h2>I wish to unsubscribe from the following:</h2>
                {/*<FormikCheckbox*/}
                {/*    type="text"*/}
                {/*    name="channel_email"*/}
                {/*    label="Can Send Email"*/}
                {/*    value={customerCheckError}*/}
                {/*/>*/}
                {/*<FormikCheckbox*/}
                {/*    type="text"*/}
                {/*    name="channel_sms"*/}
                {/*    label="Can Send SMS"*/}
                {/*    value={values.channel_sms}*/}
                {/*/>*/}
                {/*<FormikCheckbox*/}
                {/*    type="text"*/}
                {/*    name="channel_post"*/}
                {/*    label="Can Send Post"*/}
                {/*    value={values.channel_post}*/}
                {/*/>*/}
                <p>
                    The email address <b>{email}</b> is now <b>unsubscribed</b>{" "}
                    form future email emarketing communications.
                </p>
                <p style={{ marginTop: "1em" }}>
                    If you unsubscribed in error, you can&nbsp;
                    <Link to={`/subscribe/id`}>resubscribe here</Link>
                </p>
            </StyledPanel>
        </SubscribeContainer>
    );
};

export default Unsubscribe;
