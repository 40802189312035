import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Formik, Form } from "formik";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    FormikInput,
    useAddToast,
    FormikSelect,
    SubmitButton,
    FormikDatePicker,
} from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";
import * as Yup from "yup";
import { CubeLoader } from "@cortexglobal/lens-components";
import { Title, Container } from "../../components/generalLayoutComponents";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const steps = ["Details", "Adjustment"];

const StepTitle = styled.h3`
    font-weight: normal;
    margin-bottom: 1em;
`;

const AdjustmentsContainer = styled.div`
    padding: 1rem 0;
`;

const ErrorText = styled.p`
    color: red;
`;

const Schema = Yup.object().shape({
    trade_team_id: Yup.string().required("Required"),
    points: Yup.number().required("Required"),
    date: Yup.date().required("Required"),
    reason: Yup.string(),
    title: Yup.string().required("Required"),
    scheme_id: Yup.number().required("Required"),
});

const AddAdjustment = (props) => {
    const [currentStep, setCurrentStep] = useState(1);
    const addToast = useAddToast();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [showFetchSummaryError, setShowFetchSummaryError] = useState(false);
    const [customerSummary, setCustomerSummary] = useState();

    const [fetchingScheme, setFetchingScheme] = useState(false);
    const [schemes, setSchemes] = useState([]);

    const getFormattedDate = (date) => {
        return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(
            -2
        )}-${("0" + date.getDate()).slice(-2)}`;
    };

    const handleSubmit = (value) => {
        setLoading(true);

        const formattedDate = getFormattedDate(new Date(value.date));

        axios
            .post(`/api/v1/points-adjustments`, {
                ...value,
                date: formattedDate,
                customer_id: customerSummary.customer_id,
            })
            .then(({ data }) => {
                history.push(`/rewards/adjustments/${data}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error creating your adjustment.",
                    showFor: 5000,
                });
            });
    };

    const nextStep = (event) => {
        event.preventDefault();
        setCurrentStep(currentStep + 1);
    };

    const previousStep = (event) => {
        event.preventDefault();
        setCurrentStep(currentStep - 1);
    };

    const handleFetchCustomerSummary = (id) => {
        setFetchingSummary(true);
        setShowFetchSummaryError(false);

        axios
            .get(`/api/v1/customers/${id}/summary`)
            .then(({ data }) => {
                console.log(data);
                setFetchingSummary(false);
                setCustomerSummary(data.data);
            })
            .catch((error) => {
                console.log(error);
                setCustomerSummary(undefined);
                setShowFetchSummaryError(true);
                setFetchingSummary(false);
            });
    };

    const CustomerSummary = () => {
        return (
            <Row>
                <Column>
                    <Container>
                        <Title>Customer Summary:</Title>
                        <p>
                            <b>Distrigo Number: </b>
                            {customerSummary.trade_team_id}
                        </p>
                        <p>
                            <b>Customer: </b>
                            {customerSummary.trade_team_customer}
                        </p>
                        <p>
                            <b>Customer Type: </b>
                            {customerSummary.type}
                        </p>
                        <p>
                            <b>Customer Tier: </b>
                            {customerSummary.tier}
                        </p>
                        <p>
                            <b>Customer Address: </b>
                            {customerSummary.address_1}, {customerSummary.town},{" "}
                            {customerSummary.postcode}
                        </p>
                        <p>
                            <b>Available Points: </b>
                            {customerSummary.available_points
                                ? customerSummary.available_points.toLocaleString(
                                      "en",
                                      { useGrouping: true }
                                  )
                                : ""}
                        </p>
                    </Container>
                </Column>
            </Row>
        );
    };

    const getScheme = (date) => {
        setFetchingScheme(true);

        const formattedDate = getFormattedDate(new Date(date));

        axios
            .get(`/api/v1/points`, {
                params: {
                    search_by: "date",
                    search_val: formattedDate,
                },
            })
            .then(({ data }) => {
                setFetchingScheme(false);
                setSchemes(
                    data.data.map((dataItem) => {
                        return {
                            value: dataItem.id,
                            text: dataItem.title,
                        };
                    })
                );
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting schemes.",
                    showFor: 5000,
                });
                setFetchingScheme(false);
            });
    };

    return (
        <>
            <PageTitle title="Points Adjustment" expanded>
                <Link to="/rewards/adjustments">
                    <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to Adjustments</Button>
                </Link>
            </PageTitle>
            <AdjustmentsContainer>
                <Row>
                    <Column>
                        <StepTitle>
                            <strong>Step {currentStep}:</strong>{" "}
                            {steps[currentStep - 1]}
                        </StepTitle>
                        <Panel style={{ padding: "2em" }}>
                            <Formik
                                initialValues={{
                                    trade_team_id: "",
                                    points: "",
                                    date: new Date().toDateString(),
                                    reason: "",
                                    title: "",
                                    scheme_id: [],
                                }}
                                onSubmit={handleSubmit}
                                validationScheme={Schema}
                            >
                                {({ values }) => {
                                    const secondStepComplete =
                                        !!values.points && !!values.date;

                                    const thirdStepComplete =
                                        !!values.title && !!values.scheme_id.length;

                                    return (
                                        <Form>
                                            {currentStep === 1 && (
                                                <>
                                                    <Row>
                                                        <Column
                                                            large={6}
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <div>
                                                                <FormikInput
                                                                    value={
                                                                        values.trade_team_id
                                                                    }
                                                                    width="100%"
                                                                    labelWidth={
                                                                        100
                                                                    }
                                                                    name="trade_team_id"
                                                                    label="Distrigo Number"
                                                                />
                                                            </div>
                                                            <SubmitButton
                                                                submitting={
                                                                    fetchingSummary
                                                                }
                                                                onClick={() =>
                                                                    handleFetchCustomerSummary(
                                                                        values.trade_team_id
                                                                    )
                                                                }
                                                                style={{
                                                                    marginLeft:
                                                                        "1rem",
                                                                }}
                                                            >
                                                                Fetch
                                                            </SubmitButton>
                                                        </Column>
                                                    </Row>
                                                    {showFetchSummaryError && (
                                                        <Row>
                                                            <Column>
                                                                <ErrorText>
                                                                    {trans(
                                                                        "There are no customer details matching this Distrigo Number."
                                                                    )}
                                                                </ErrorText>
                                                            </Column>
                                                        </Row>
                                                    )}

                                                    {customerSummary && (
                                                        <CustomerSummary />
                                                    )}

                                                    <Row>
                                                        <Column
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <Button
                                                                disabled={
                                                                    !customerSummary
                                                                }
                                                                onClick={
                                                                    nextStep
                                                                }
                                                            >
                                                                Next Step
                                                            </Button>
                                                        </Column>
                                                    </Row>
                                                </>
                                            )}

                                            {currentStep === 2 && (
                                                <>
                                                    <CustomerSummary />
                                                    <Row>
                                                        <Column large={6}>
                                                            <FormikInput
                                                                value={
                                                                    values.points
                                                                }
                                                                width="100%"
                                                                labelWidth={100}
                                                                name="points"
                                                                label="Points Adjustment"
                                                            />
                                                            <span>
                                                                You may enter a
                                                                positive or
                                                                negative value
                                                                here to adjust a
                                                                customer's
                                                                points balance.
                                                            </span>
                                                        </Column>
                                                        <Column large={6}>
                                                            <FormikDatePicker
                                                                maxDate={
                                                                    new Date()
                                                                }
                                                                value={
                                                                    values.date
                                                                }
                                                                width="100%"
                                                                labelWidth={100}
                                                                name="date"
                                                                label="When Applicable"
                                                            />
                                                        </Column>
                                                    </Row>
                                                    <Row>
                                                        <Column
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    marginRight:
                                                                        "1em",
                                                                }}
                                                                onClick={
                                                                    previousStep
                                                                }
                                                            >
                                                                Previous Step
                                                            </Button>
                                                            <Button
                                                                disabled={
                                                                    !secondStepComplete
                                                                }
                                                                onClick={(
                                                                    event
                                                                ) => {
                                                                    nextStep(
                                                                        event
                                                                    );
                                                                    getScheme(
                                                                        values.date
                                                                    );
                                                                }}
                                                            >
                                                                Next Step
                                                            </Button>
                                                        </Column>
                                                    </Row>
                                                </>
                                            )}

                                            {currentStep === 3 && (
                                                <>
                                                    {fetchingScheme ? (
                                                        <CubeLoader />
                                                    ) : (
                                                        <>
                                                            <CustomerSummary />
                                                            <Row>
                                                                <Column>
                                                                    <Container>
                                                                        <Title>
                                                                            Adjustment
                                                                            Details:
                                                                        </Title>
                                                                        <p>
                                                                            <b>
                                                                                Points
                                                                                Adjustment:{" "}
                                                                            </b>
                                                                            {
                                                                                values.points
                                                                            }
                                                                        </p>
                                                                        <p>
                                                                            <b>
                                                                                When
                                                                                Applicable:{" "}
                                                                            </b>
                                                                            {
                                                                                values.date
                                                                            }
                                                                        </p>
                                                                    </Container>
                                                                </Column>
                                                            </Row>
                                                            <Row>
                                                                <Column
                                                                    large={6}
                                                                >
                                                                    <FormikInput
                                                                        value={
                                                                            values.title
                                                                        }
                                                                        width="100%"
                                                                        labelWidth={
                                                                            100
                                                                        }
                                                                        name="title"
                                                                        label="Display Title"
                                                                    />
                                                                </Column>
                                                                <Column
                                                                    large={6}
                                                                >
                                                                    <FormikSelect
                                                                        value={
                                                                            values.scheme_id
                                                                        }
                                                                        label="Relevant Scheme"
                                                                        name="scheme_id"
                                                                        options={
                                                                            schemes
                                                                        }
                                                                    />
                                                                </Column>
                                                            </Row>
                                                            <Row>
                                                                <Column
                                                                    large={12}
                                                                >
                                                                    <FormikInput
                                                                        value={
                                                                            values.reason
                                                                        }
                                                                        width="100%"
                                                                        labelWidth={
                                                                            100
                                                                        }
                                                                        name="reason"
                                                                        label="Reason / Explanation"
                                                                    />
                                                                </Column>
                                                            </Row>
                                                            <Row>
                                                                <Column
                                                                    style={{
                                                                        textAlign:
                                                                            "right",
                                                                    }}
                                                                >
                                                                    <Button
                                                                        style={{
                                                                            marginRight:
                                                                                "1em",
                                                                        }}
                                                                        onClick={
                                                                            previousStep
                                                                        }
                                                                    >
                                                                        Previous
                                                                        Step
                                                                    </Button>
                                                                    <Button
                                                                        disabled={
                                                                            !thirdStepComplete
                                                                        }
                                                                        onClick={
                                                                            nextStep
                                                                        }
                                                                    >
                                                                        Next
                                                                        Step
                                                                    </Button>
                                                                    {/*<SubmitButton*/}
                                                                    {/*    submitting={loading}*/}
                                                                    {/*    disabled={!thirdStepComplete}>*/}
                                                                    {/*    Save Adjustment*/}
                                                                    {/*</SubmitButton>*/}
                                                                </Column>
                                                            </Row>
                                                        </>
                                                    )}
                                                </>
                                            )}

                                            {currentStep === 4 && (
                                                <>
                                                    <Row>
                                                        <Column>
                                                            <h3>
                                                                Points
                                                                Adjustment
                                                                Overview
                                                            </h3>
                                                        </Column>
                                                    </Row>

                                                    <CustomerSummary />

                                                    <Row>
                                                        <Column>
                                                            <Container>
                                                                <Title>
                                                                    Adjustment
                                                                    Details:
                                                                </Title>
                                                                <p>
                                                                    <b>
                                                                        Points
                                                                        Adjustment:{" "}
                                                                    </b>
                                                                    {
                                                                        values.points
                                                                    }
                                                                </p>
                                                                <p>
                                                                    <b>
                                                                        Points
                                                                        Balance
                                                                        Before:{" "}
                                                                        {
                                                                            customerSummary.available_points
                                                                        }
                                                                    </b>
                                                                </p>
                                                                <p>
                                                                    <b>
                                                                        Points
                                                                        Balance
                                                                        After:{" "}
                                                                    </b>
                                                                    {
                                                                        parseInt(values.points) +
                                                                        parseInt(customerSummary.available_points)
                                                                    }
                                                                </p>
                                                                <p>
                                                                    <b>
                                                                        When
                                                                        Applicable:{" "}
                                                                    </b>
                                                                    {
                                                                        values.date
                                                                    }
                                                                </p>
                                                            </Container>
                                                        </Column>
                                                    </Row>

                                                    <Row>
                                                        <Column>
                                                            <Container>
                                                                <Title>
                                                                    Information:
                                                                </Title>
                                                                <p>
                                                                    <b>
                                                                        Display
                                                                        Title:{" "}
                                                                    </b>
                                                                    {
                                                                        values.title
                                                                    }
                                                                </p>
                                                                <p>
                                                                    <b>
                                                                        Relevant
                                                                        Scheme:{" "}
                                                                    </b>
                                                                    {schemes.length &&
                                                                        schemes.filter(
                                                                            ({
                                                                                value,
                                                                            }) =>
                                                                                value.toString() ===
                                                                                values.scheme_id
                                                                        )[0]
                                                                            .text}
                                                                </p>
                                                                {values.reason && (
                                                                    <p>
                                                                        <b>
                                                                            Reason
                                                                            /
                                                                            Explanation:
                                                                        </b>{" "}
                                                                        {
                                                                            values.reason
                                                                        }
                                                                    </p>
                                                                )}
                                                            </Container>
                                                        </Column>
                                                    </Row>

                                                    <Row>
                                                        <Column
                                                            style={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        >
                                                            <Button
                                                                style={{
                                                                    marginRight:
                                                                        "1em",
                                                                }}
                                                                onClick={
                                                                    previousStep
                                                                }
                                                            >
                                                                Previous Step
                                                            </Button>
                                                            <SubmitButton
                                                                submitting={
                                                                    loading
                                                                }
                                                            >
                                                                Save Adjustment
                                                            </SubmitButton>
                                                        </Column>
                                                    </Row>
                                                </>
                                            )}
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Panel>
                    </Column>
                </Row>
            </AdjustmentsContainer>
        </>
    );
};

export default AddAdjustment;
