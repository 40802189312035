import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast,
    SelectField,
} from "@cortexglobal/rla-components";

import { useHistory, useParams, useLocation } from "react-router-dom";
import queryString from "querystring";
import { debounce } from "lodash";
import {
    StyledTable,
    currentPage,
    StyledTableRow,
    StyledTableHeader,
    CubeLoader,
    TrueFalseIcon,
} from "@cortexglobal/lens-components";
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
`;

const DealersList = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const { orderBy, orderDir, search, searchBy, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [searchValue, setSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);

    const [dealers, setDealers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalDealers, setTotalDealers] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [dealerTypes, setDealerTypes] = useState();
    const [dealerBrands, setDealerBrands] = useState();
    const [dealerZones, setDealerZones] = useState();
    const [dealerViews, setDealerViews] = useState();
    const [dealerTypesLoading, setDealerTypesLoading] = useState(false);

    const getDealerTypes = () => {
        setDealerTypesLoading(true);

        axios
            .get(`/api/v1/dealers/types`)
            .then(({ data }) => {
                console.log(data);
                setDealerTypesLoading(false);

                setDealerZones(data.data.zones);
                setDealerViews(data.data.group_views);
                setDealerBrands(data.data.brands);
                setDealerTypes(data.data.types);
            })
            .catch((error) => {
                console.log(error);
                setDealerTypesLoading(false);
            });
    };

    const getDealers = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/dealers`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setDealers(data.data);
                setTotalDealers(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                console.log(e);
                addToast({
                    type: "alert",
                    content: "There was an error getting dealers.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getDealers();
        getDealerTypes();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.search_val ? `&search=${data.search_val}` : ""
            }${data.search_val ? `&searchBy=${data.search_by}` : ""}`,
        });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch(() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
            getDealers({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getDealers({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getDealers({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getDealers({
            page: selected + 1,
        });
    };

    return (
        <>
            <PageTitle title="Dealers" expanded>
                <Link to="/dealers/create">
                    <Button height={38}>Add New Dealer</Button>
                </Link>
            </PageTitle>
            <Row collapse expanded>
                <Column collapse>
                    <Panel style={{ paddingTop: "0" }}>
                        <StyledTable className="dashboard-table">
                            <thead style={{ position: "sticky", top: "0" }}>
                                <tr>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "id"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "id"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="id"
                                    >
                                        ID
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "dealer_name"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "dealer_name"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="dealer_name"
                                    >
                                        Dealer Name
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "dealer_code"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "dealer_code"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="dealer_code"
                                    >
                                        Dealer Code
                                    </StyledTableHeader>

                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "type"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "type"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={dealerTypes}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="type"
                                    >
                                        Dealer Type
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "postcode"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "postcode"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="postcode"
                                    >
                                        Postcode
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "brand"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "brand"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={dealerBrands}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="brand"
                                    >
                                        Brand
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "parts_zone"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "parts_zone"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={dealerZones}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="parts_zone"
                                    >
                                        Zone
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "group_view"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "group_view"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={dealerViews}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="group_view"
                                    >
                                        Group View
                                    </StyledTableHeader>
                                    <StyledTableHeader />
                                </tr>
                            </thead>

                            {!loading && (
                                <tbody>
                                    {dealers.map((dealer) => (
                                        <StyledTableRow key={dealer.user_uuid}>
                                            <td>{dealer.id}</td>
                                            <td>{dealer.dealer_name}</td>
                                            <td>{dealer.dealer_code}</td>
                                            <td>{dealer.type}</td>
                                            <td>{dealer.postcode}</td>
                                            <td>{dealer.brand}</td>
                                            <td>{dealer.parts_zone}</td>
                                            <td>{dealer.group_view}</td>
                                            <td>
                                                <Link
                                                    to={`/dealers/${dealer.id}`}
                                                >
                                                    <Button>View</Button>
                                                </Link>
                                            </td>
                                        </StyledTableRow>
                                    ))}
                                </tbody>
                            )}
                        </StyledTable>

                        {loading && <CubeLoader />}

                        <PaginationContainer>
                            <PerPageContainer>
                                Per Page:
                                <SelectField
                                    name="perPage"
                                    style={{ margin: "0 0 0 1em" }}
                                    value={perPageValue}
                                    options={[
                                        { value: 10, text: "10" },
                                        { value: 20, text: "20" },
                                        { value: 30, text: "30" },
                                        { value: 40, text: "40" },
                                        { value: 50, text: "50" },
                                    ]}
                                    onChange={handlePerPage}
                                />
                            </PerPageContainer>
                            <Pagination
                                currentPage={currentPage(selectedPageValue)}
                                total={totalDealers}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                previousLabel="&laquo;"
                                nextLabel="&raquo;"
                            />
                            <TotalContainer>
                                Total: {totalDealers}
                            </TotalContainer>
                        </PaginationContainer>
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default DealersList;
