import React, { useState, useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    InputField,
    useAddToast,
} from "@cortexglobal/rla-components";
import PointsCampaignBuilder from "./PointsCampaignBuilder";
import { CubeLoader } from "@cortexglobal/lens-components";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CampaignContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const EditPointsCampaign = ({ match }) => {
    const addToast = useAddToast();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [campaignData, setCampaignData] = useState(false);

    const getCampaign = () => {
        setLoading(true);

        axios
            .get(`/api/v1/points/${match.params.id}`)
            .then(({ data }) => {
                setCampaignData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting your campaign.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const handleSubmit = (value) => {
        setLoading(true);

        axios
            .post(`/api/v1/points`, value)
            .then(({ data }) => {
                history.push(`/rewards/campaigns/${data}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error creating your campaign.",
                    showFor: 5000
                });
            });
    };

    useEffect(() => {
        getCampaign();
    }, []);

    return (
        <>
            <PageTitle title="Edit Points Campaign" expanded>
                <Link to={`/rewards/campaigns/${match.params.id}`}>
                    <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to Point Campaign</Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && campaignData && (
            <CampaignContainer>
                <Row>
                    <Column>
                        <PointsCampaignBuilder handleSubmit={handleSubmit} loading={loading} values={campaignData}/>
                    </Column>
                </Row>
            </CampaignContainer>
            )}
        </>
    );
};

export default EditPointsCampaign;
