import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    useAddToast,
    InputField,
    Button,
    SelectField,
    Pagination,
} from "@cortexglobal/rla-components";
import {
    StyledTable,
    StyledTableHeader,
    CubeLoader,
    ButtonDropdown,
    StyledTableRow,
    currentPage,
} from "@cortexglobal/lens-components";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "querystring";
import { debounce } from "lodash";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const MappableEntryData = styled.span`
    color: #555;
    font-style: italic;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SelectedSnapOnDispatches = styled.div`
    position: absolute;
    background: white;
    bottom: -10px;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    width: calc(100% - 150px);
    position: fixed;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const SnapOnVoucherDispatch = (props) => {
    const history = useHistory();
    const addToast = useAddToast();
    const { orderBy, orderDir, search, searchBy, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [multiLoading, setMultiLoading] = useState(false);

    const [searchValue, setSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);

    const [selectedSnapOnDispatches, setSelectedSnapOnDispatches] = useState(
        []
    );

    const [snapOnDispatch, setSnapOnDispatch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalSnapOnDispatches, setTotalSnapOnDispatches] = useState();
    const [totalPages, setTotalPages] = useState(0);

    const handleUpdatedSnapOnDispatches = (snapOnDispatch) => {
        axios
            .put(`/api/v1/snap-on-redemption`, {
                dispatches: snapOnDispatch,
            })
            .then(({ data }) => {
                getSnapOnDispatches();
                cancelAllLoading();
            })
            .catch((e) => {
                cancelAllLoading();
                addToast({
                    type: "alert",
                    content: "There was an error accepting snap on dispatches.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const getSnapOnDispatches = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/snap-on-redemption`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                console.log(data);
                setSnapOnDispatch(data.data);
                setTotalSnapOnDispatches(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting snap on dispatches.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getSnapOnDispatches();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.search_val ? `&search=${data.search_val}` : ""
            }${data.search_val ? `&searchBy=${data.search_by}` : ""}${
                data.global_search_val
                    ? `&globalSearch=${data.global_search_val}`
                    : ""
            }`,
        });
    };

    const cancelAllLoading = () => {
        setMultiLoading(false);
        setLoading(false);
        setSelectedSnapOnDispatches([]);
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSnapOnClickMapping = (snapOnMap) => {
        if (
            selectedSnapOnDispatches.some(
                ({ snap_id }) => snap_id === snapOnMap.id
            )
        ) {
            setSelectedSnapOnDispatches([
                ...selectedSnapOnDispatches.filter(
                    (selectedCustomerMap) =>
                        selectedCustomerMap.id !== snapOnMap.id
                ),
            ]);
        } else {
            setSelectedSnapOnDispatches([
                ...selectedSnapOnDispatches,
                {
                    ...convertMap(snapOnMap),
                },
            ]);
        }
    };

    const convertMap = (snapOnMap) => {
        return {
            id: snapOnMap.id,
            status: snapOnMap.status,
        };
    };

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch(() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
            getSnapOnDispatches({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getSnapOnDispatches({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getSnapOnDispatches({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getSnapOnDispatches({
            page: selected + 1,
        });
    };

    return (
        <>
            <PageTitle title="Snap On Voucher Dispatch" expanded />

            {loading && <CubeLoader />}

            {!loading && (
            <Row collapse expanded>
                <Column collapse>
                    <StyledTable selectable={true} className="dashboard-table">
                        <thead>
                            <tr>
                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="reference"
                                >
                                    Reference
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={
                                        orderByValue === "status"
                                            ? orderDirectionValue
                                            : undefined
                                    }
                                    searchValue={
                                        searchByValue === "status"
                                            ? searchValue
                                            : ""
                                    }
                                    searchOptions={[
                                        {
                                            label: "Ready To Dispatch",
                                            value: "ready_to_dispatch",
                                        },
                                    ]}
                                    onSortChange={handleSortChange}
                                    onSearchChange={handleSearch}
                                    hasSearch={true}
                                    name="status"
                                >
                                    Status
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="amount"
                                >
                                    Amount
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="contact_name"
                                >
                                    Recipient Name
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="full_address"
                                >
                                    Full Address Name
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="business_name"
                                >
                                    Business Name
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="email"
                                >
                                    Email
                                </StyledTableHeader>

                                <StyledTableHeader
                                    isSortable={false}
                                    hasSearch={false}
                                    name="ordered_at"
                                >
                                    Ordered At
                                </StyledTableHeader>
                            </tr>
                        </thead>
                        <tbody>
                            {snapOnDispatch && snapOnDispatch.length && (
                                <>
                                    {snapOnDispatch.map((snapOnDispatch) => (
                                        <StyledTableRow
                                            selected={selectedSnapOnDispatches.some(
                                                (selectedCustomerMap) =>
                                                    selectedCustomerMap.id ===
                                                    snapOnDispatch.id
                                            )}
                                            onClick={() =>
                                                handleSnapOnClickMapping(
                                                    snapOnDispatch
                                                )
                                            }
                                            key={snapOnDispatch.id}
                                        >
                                            <td>
                                                <MappableEntryData>
                                                    {snapOnDispatch.reference}
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {snapOnDispatch.status}
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {snapOnDispatch.amount}
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {
                                                        snapOnDispatch.contact_name
                                                    }
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {
                                                        snapOnDispatch.full_address
                                                    }
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {
                                                        snapOnDispatch.business_name
                                                    }
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {snapOnDispatch.email}
                                                </MappableEntryData>
                                            </td>
                                            <td>
                                                <MappableEntryData>
                                                    {new Date(
                                                        snapOnDispatch.ordered_at
                                                    ).toDateString()}
                                                </MappableEntryData>
                                            </td>
                                        </StyledTableRow>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </StyledTable>
                    <PaginationContainer>
                        <PerPageContainer>
                            Per Page:
                            <SelectField
                                name="perPage"
                                style={{ margin: "0 0 0 1em" }}
                                value={perPageValue}
                                options={[
                                    { value: 10, text: "10" },
                                    { value: 20, text: "20" },
                                    { value: 30, text: "30" },
                                    { value: 40, text: "40" },
                                    { value: 50, text: "50" },
                                ]}
                                onChange={handlePerPage}
                            />
                        </PerPageContainer>
                        <Pagination
                            currentPage={currentPage(selectedPageValue)}
                            total={totalSnapOnDispatches}
                            pageCount={totalPages}
                            onPageChange={handlePageChange}
                            previousLabel="&laquo;"
                            nextLabel="&raquo;"
                        />
                        <TotalContainer>
                            Total: {totalSnapOnDispatches}
                        </TotalContainer>
                    </PaginationContainer>
                </Column>
            </Row>
            )}

            {!!selectedSnapOnDispatches.length && !loading && (
                <SelectedSnapOnDispatches>
                    <span>
                        <b>
                            Selected {selectedSnapOnDispatches.length} Snap On
                            Dispatches
                        </b>
                    </span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "1em" }}>
                            <ButtonDropdown
                                onClick={() => setSelectedSnapOnDispatches([])}
                                actions={[]}
                                name="primary"
                            >
                                Cancel
                            </ButtonDropdown>
                        </span>
                        <span>
                            <Button
                                onClick={() => {
                                    setMultiLoading(true);
                                    handleUpdatedSnapOnDispatches(
                                        selectedSnapOnDispatches
                                    );
                                }}
                                name="Mark As Dispatched"
                            >
                                Mark As Dispatched
                            </Button>
                        </span>

                        {multiLoading && (
                            <span
                                style={{
                                    marginLeft: "2em",
                                }}
                            >
                                <CubeLoader size={20} margin="0" />
                            </span>
                        )}
                    </span>
                </SelectedSnapOnDispatches>
            )}
        </>
    );
};

export default SnapOnVoucherDispatch;
