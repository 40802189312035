import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

const SubscribeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    background: #f9f9f9;
`;

const StyledPanel = styled.div`
    background: white;
    box-shadow: 0 0 7px 2px rgba(37,37,37,.05);
    padding: 3em !important;
    margin-top: 3em;
    max-width: 500px;
    
    h1 {
        font-size: 1.5em;
        margin-bottom: 1em;
    }
    
    p {
        margin: 0;
    }
`;

const Logo = styled.img`
    width: 350px;
`;

const Subscribe = () => {

    const queryString = new URLSearchParams(useLocation().search);
    const email = queryString.get("email") || "";

    return (
        <SubscribeContainer>
            {/*<Logo src="/new_logo_eurorepar.png" />*/}
            <StyledPanel>
                <h1>You are now subscribed</h1>
                <p>Don't worry, we have resubscribed you to our mailing list and <b>{email}</b> will continue to receive communications from us.</p>

                <div style={{ marginTop: '1em' }}>
                    <Link
                        style={{ marginTop: '1em' }}
                        to={`/unsubscribe/id`}
                    >
                        Unsubscribe
                    </Link>
                </div>
            </StyledPanel>
        </SubscribeContainer>
    );
};

export default Subscribe;