import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import {
    StyledTable,
    StyledTableRow,
    CubeLoader,
    TrueFalseIcon
} from "@cortexglobal/lens-components";
import DealerForm from "./DealerForm";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const DealerContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const CreateDealer = ({ match }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);
    const [dealerData, setDealerData] = useState();

    const handleSubmit = (value) => {
        setLoading(true);

        axios
            .post(`/api/v1/dealers`, value)
            .then(({ data }) => {
                history.push(`/dealers/${data.data.id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error creating your dealer.",
                    showFor: 5000
                });
            });
    };

    return (
        <>
            <PageTitle title="Create New Dealer" expanded>
                <Link to="/dealers">
                    <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to dealers</Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && (
            <DealerContainer>
                <Row>
                    <Column>
                        <DealerForm
                            handleSubmit={handleSubmit}
                            loading={loading}
                            formType="create"
                        />
                    </Column>
                </Row>
            </DealerContainer>
            )}

        </>
    );
};

export default CreateDealer;