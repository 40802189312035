import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "lodash";
import { withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Header,
    UserInfoTitle,
    Row,
    Column,
    SidePanel,
    Menu,
    MenuItem,
    Button,
    CloseButton,
    UserInfoIcon,
    StyledLink,
} from "@cortexglobal/rla-components";

import { AuthContext, logout as logoutAction } from "@cortexglobal/lens-auth";

const MenuButton = (props) => {
    return (
        <Button
            {...props}
            style={{
                display: "block",
                margin: "1rem auto",
                maxWidth: "90%",
                padding: "0.75rem 1rem",
            }}
        >
            {props.children}
        </Button>
    );
};

const PrivateHeader = (theme) => {
    const [menuVisible, setMenuVisible] = useState(false);

    const { setLoadingState, user } = useContext(AuthContext);

    const logout = () => {
        setLoadingState("loading");
        logoutAction();
    };

    return (
        <>
            <Header>
                <div>
                    <Row>
                        <Column medium={10}>
                            <UserInfoTitle>
                                {get(user, "name", "...")}
                            </UserInfoTitle>
                        </Column>
                        <Column medium={2}>
                            <UserInfoIcon
                                onClick={() => setMenuVisible(!menuVisible)}
                            >
                                <FontAwesomeIcon icon="bars" size="lg" />
                            </UserInfoIcon>
                        </Column>
                    </Row>
                </div>
            </Header>

            <SidePanel
                visible={menuVisible}
                onClose={() => setMenuVisible(false)}
                width="350px"
                zIndex={2000}
            >
                <Row style={{ paddingTop: "1rem" }}>
                    <Column medium={9}>
                        <p>&nbsp;</p>
                    </Column>
                    <Column medium={3}>
                        <CloseButton onClick={() => setMenuVisible(false)} />
                    </Column>
                </Row>

                <Menu>
                    <MenuItem>
                        <StyledLink to="/">Home</StyledLink>
                    </MenuItem>
                    <MenuItem>
                        <StyledLink to="/uploads">Uploads</StyledLink>
                    </MenuItem>
                    <MenuItem>
                        <StyledLink to="/imports">Imports</StyledLink>
                    </MenuItem>
                </Menu>

                <MenuButton href="/auth/login" onClick={logout} expanded>
                    <FontAwesomeIcon icon="sign-out-alt" transform="grow-3" />{" "}
                    Logout
                </MenuButton>
            </SidePanel>
        </>
    );
};

export default withTheme(PrivateHeader);
