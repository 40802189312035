import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CampaignsList from "./CampaignsList";
import AddCampaign from "./AddCampaign";
import ViewCampaign from "./ViewCampaign";
import EditCampaign from "./EditCampaign";

const Campaigns = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route
                    path={`${match.path}/:id/edit`}
                    component={EditCampaign}
                />
                <Route path={`${match.path}/add`} component={AddCampaign} />
                <Route path={`${match.path}/:id`} component={ViewCampaign} />
                <Route path={`${match.path}`} component={CampaignsList} />
            </Switch>
        </>
    );
};

export default Campaigns;
