import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
    Button,
    Column,
    FormikDatePicker,
    FormikInput,
    Panel,
    Row,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";

import {
    FormikAdvancedSelect,
    CubeLoader,
} from "@cortexglobal/lens-components";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";

const steps = ["Details", "Points"];

const StepTitle = styled.h3`
    font-weight: normal;
    margin-bottom: 1em;
`;

const Schema = Yup.object().shape({
    title: Yup.string().required("Required"),
    date_from: Yup.date().required("Required"),
    data_to: Yup.date().required("Required"),
    frequency: Yup.string().required("Required"),
    source: Yup.string().required("Required"),
    calculation: Yup.string().required("Required"),
    minimum_spend: Yup.number(),
    multiplier: Yup.number(),
    points: Yup.number(),
    categories: Yup.array().of(Yup.number()).nullable(),
    sub_categories: Yup.array().of(Yup.number()).nullable(),
    ranges: Yup.array().of(Yup.string()).nullable(),
    customer_types: Yup.array().of(Yup.string()).nullable(),
});

const PointsCampaignBuilder = ({ handleSubmit, loading, values = {} }) => {
    const addToast = useAddToast();

    const [categories, setCategories] = useState([]);
    const [ranges, setRanges] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [rangesLoading, setRangesLoading] = useState(false);

    const [subCategories, setSubCategories] = useState([]);
    const [subCategoriesLoading, setSubCategoriesLoading] = useState(false);

    const [formOptions, setFormOptions] = useState({
        source: [],
        customer_types: [],
        calculation: [],
    });

    const onSaveCampaign = (values) => {
        // Remove values that aren't needed depending on calculation
        handleSubmit({
            ...values,
            ...((values.calculation === "Tactical" ||
                values.calculation === "TacticalAuthorisedRepairer") && {
                points: undefined,
                minimum_spend: undefined,
            }),
            ...(values.calculation === "TacticalBonus" && {
                multiplier: undefined,
            }),
            ...(values.calculation === "DynamicSpend" && {
                points: undefined,
                minimum_spend: undefined,
                categories: undefined,
                ranges: undefined,
                sub_categories: undefined,
            }),
        });
    };

    const getData = () => {
        setCategoriesLoading(true);
        setRangesLoading(true);
        axios
            .get(`/api/v1/getAllCategories`)
            .then(({ data }) => {
                setCategoriesLoading(false);
                setCategories(data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting categories.",
                    showFor: 5000,
                });
            });
        axios
            .get(`/api/v1/getRanges`)
            .then(({ data }) => {
                setRangesLoading(false);
                setRanges(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting ranges.",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getData();
        if (values.perks_type !== undefined) {
            defineFormOptions(values.perks_type);
        }
    }, []);

    const getSubCategories = ({
        event,
        selectedCategories,
        selectedRanges,
    }) => {
        event.preventDefault();
        setSubCategoriesLoading(true);

        axios
            .put(`/api/v1/getSubCategories`, {
                categories: selectedCategories,
                ranges: selectedRanges,
            })
            .then(({ data }) => {
                setSubCategoriesLoading(false);
                setSubCategories(data);
            })
            .catch((e) => {
                setSubCategoriesLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error getting sub categories.",
                    showFor: 5000,
                });
            });
    };

    const isMonday = (date) => {
        const myDate = new Date(date);
        const day = myDate.getDay();
        return day === 1;
    };

    const isSunday = (date) => {
        const myDate = new Date(date);
        const day = myDate.getDay();
        return day === 0;
    };

    const isLastDay = (date) => {
        const myDate = new Date(date);
        const test = new Date(myDate.getTime()),
            month = test.getMonth();

        test.setDate(test.getDate() + 1);
        return test.getMonth() !== month;
    };

    const isFirstDay = (date) => {
        const myDate = new Date(date);
        const test = new Date(myDate.getTime()),
            month = test.getMonth();

        test.setDate(test.getDate() - 1);
        return test.getMonth() !== month;
    };

    const defineFormOptions = (perksType) => {
        if (perksType === "perks") {
            setFormOptions({
                source: [
                    {
                        text: "Speeder",
                        value: "speeder",
                    },
                    {
                        text: "FaapJ",
                        value: "faapj",
                    },
                ],
                customer_types: [
                    {
                        text: "IMT",
                        value: "IMT",
                    },
                    {
                        text: "Bodyshop",
                        value: "Bodyshop",
                    },
                    {
                        text: "Transmission Specialist",
                        value: "Transmission Specialist",
                    },
                    {
                        text: "Euro Repar Car Service",
                        value: "Euro Repar Car Service",
                    },
                    {
                        text: "Used Car Sales",
                        value: "Used Car Sales",
                    },
                    {
                        text: "Vehicle Hire and Leasing",
                        value: "Vehicle Hire and Leasing",
                    },
                    {
                        text: "Recovery",
                        value: "Recovery",
                    },
                    {
                        text: "MOT Centre",
                        value: "MOT Centre",
                    },
                    {
                        text: "Citroen C1 Racing",
                        value: "Citroen C1 Racing",
                    },
                ],
                calculation: [
                    {
                        text: "Bonus",
                        value: "Bonus",
                    },
                    {
                        text: "Dynamic Spend",
                        value: "DynamicSpend",
                    },
                    {
                        text: "Tactical",
                        value: "Tactical",
                    },
                    {
                        text: "Tactical Bonus",
                        value: "TacticalBonus",
                    },
                ],
            });
        } else if (perksType === "ar-perks") {
            setFormOptions({
                source: [
                    {
                        text: "Speeder",
                        value: "speeder",
                    },
                ],
                customer_types: [
                    {
                        text: "Authorised Repairer",
                        value: "Authorised Repairer",
                    },
                ],
                calculation: [
                    {
                        text: "AR PERKS - Tactical Authorised Repairer",
                        value: "TacticalAuthorisedRepairer",
                    },
                ],
            });
        } else {
            setFormOptions({
                source: [],
                customer_types: [],
                calculation: [],
            });
        }
    };

    if (rangesLoading || categoriesLoading) return <CubeLoader />;

    return (
        <>
            <Panel style={{ padding: "2em" }}>
                <StepTitle>
                    <strong>Step 1:</strong> {steps[0]}
                </StepTitle>
                <Formik
                    initialValues={{
                        title: "",
                        date_from: new Date().toDateString(),
                        date_to: new Date().toDateString(),
                        frequency: "",
                        calculation: "",
                        minimum_spend: "",
                        multiplier: "",
                        points: "",
                        categories: [],
                        sub_categories: [],
                        ranges: [],
                        customer_types: [],
                        ...values,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Schema}
                >
                    {({ isSubmitting, values, setFieldValue, errors }) => {
                        const firstStepComplete =
                            !!values.title &&
                            !!values.frequency &&
                            !!values.date_from &&
                            !!values.date_to;

                        const secondStepComplete = !!values.calculation;

                        return (
                            <Form>
                                <>
                                    <Row>
                                        <Column>
                                            <FormikInput
                                                value={values.title}
                                                width="100%"
                                                labelWidth={100}
                                                name="title"
                                                label="Title"
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column>
                                            <FormikInput
                                                value={values.internal_name}
                                                width="100%"
                                                labelWidth={100}
                                                name="internal_name"
                                                label="Internal Name For Promotion"
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column>
                                            <FormikAdvancedSelect
                                                value={values.perks_type}
                                                options={[
                                                    {
                                                        text: "DISTRIGO PERKS",
                                                        value: "perks",
                                                    },
                                                    {
                                                        text: "AR PERKS",
                                                        value: "ar-perks",
                                                    },
                                                ]}
                                                name="perks_type"
                                                label="PERKS Type"
                                                onChange={(selected) => {
                                                    defineFormOptions(
                                                        selected.value
                                                    );
                                                    setFieldValue(
                                                        "perks_type",
                                                        selected.value
                                                    );
                                                }}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column>
                                            <FormikAdvancedSelect
                                                value={values.source}
                                                options={formOptions.source}
                                                name="source"
                                                label="Data Source"
                                            />
                                        </Column>
                                    </Row>

                                    <Row>
                                        <Column>
                                            <FormikAdvancedSelect
                                                multi={true}
                                                value={values.customer_types}
                                                name="customer_types"
                                                label="Customer Type"
                                                options={
                                                    formOptions.customer_types
                                                }
                                            />
                                        </Column>
                                    </Row>
                                </>
                                <br />
                                <StepTitle>
                                    <strong>Step 2:</strong> Points Calculation
                                </StepTitle>
                                <>
                                    <Row>
                                        <Column>
                                            <FormikAdvancedSelect
                                                value={values.frequency}
                                                options={[
                                                    {
                                                        text: "One Off",
                                                        value: "one-off",
                                                    },
                                                    {
                                                        text: "Daily",
                                                        value: "daily",
                                                    },
                                                    {
                                                        text: "Weekly",
                                                        value: "weekly",
                                                    },
                                                    {
                                                        text: "Monthly",
                                                        value: "monthly",
                                                    },
                                                ]}
                                                name="frequency"
                                                label="Points Frequency"
                                            />
                                        </Column>
                                    </Row>
                                    {values.frequency && (
                                        <Row>
                                            <Column large={6}>
                                                <FormikDatePicker
                                                    filterDate={
                                                        values.frequency ===
                                                        "weekly"
                                                            ? isMonday
                                                            : values.frequency ===
                                                              "monthly"
                                                            ? isFirstDay
                                                            : () => true
                                                    }
                                                    value={values.date_from}
                                                    name="date_from"
                                                    label="Date From"
                                                />
                                            </Column>
                                            <Column large={6}>
                                                <FormikDatePicker
                                                    filterDate={
                                                        values.frequency ===
                                                        "weekly"
                                                            ? isSunday
                                                            : values.frequency ===
                                                              "monthly"
                                                            ? isLastDay
                                                            : () => true
                                                    }
                                                    value={values.date_to}
                                                    name="date_to"
                                                    label="Date To"
                                                />
                                            </Column>
                                        </Row>
                                    )}
                                    <Row>
                                        <Column>
                                            <FormikAdvancedSelect
                                                value={values.calculation}
                                                options={
                                                    formOptions.calculation
                                                }
                                                name="calculation"
                                                label="Calculation"
                                            />
                                        </Column>
                                    </Row>

                                    {(values.calculation === "Tactical" ||
                                        values.calculation ===
                                            "TacticalBonus" ||
                                        values.calculation ===
                                            "TacticalAuthorisedRepairer") && (
                                        <>
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        multi={true}
                                                        value={
                                                            values.categories
                                                        }
                                                        name="categories"
                                                        label="Categories"
                                                        options={categories}
                                                    />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column>
                                                    <FormikAdvancedSelect
                                                        multi={true}
                                                        value={values.ranges}
                                                        name="ranges"
                                                        label="Ranges"
                                                        options={ranges}
                                                    />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column
                                                    style={{
                                                        marginBottom: "1em",
                                                    }}
                                                >
                                                    <SubmitButton
                                                        submitting={
                                                            subCategoriesLoading
                                                        }
                                                        onClick={(event) =>
                                                            getSubCategories({
                                                                event,
                                                                selectedCategories:
                                                                    values.categories,
                                                                selectedRanges:
                                                                    values.ranges,
                                                            })
                                                        }
                                                        disabled={
                                                            (!values.ranges ||
                                                                !values.ranges
                                                                    .length) &&
                                                            (!values.categories ||
                                                                !values
                                                                    .categories
                                                                    .length)
                                                        }
                                                    >
                                                        Get Sub Categories
                                                    </SubmitButton>
                                                </Column>
                                            </Row>
                                            {!!subCategories.length && (
                                                <Row>
                                                    <Column>
                                                        <FormikAdvancedSelect
                                                            multi={true}
                                                            value={
                                                                values.sub_categories
                                                            }
                                                            name="sub_categories"
                                                            label="Sub Categories"
                                                            options={subCategories.reduce(
                                                                (
                                                                    accumulator,
                                                                    currentValue
                                                                ) => {
                                                                    return [
                                                                        ...accumulator,
                                                                        ...currentValue.subCategories,
                                                                    ];
                                                                },
                                                                []
                                                            )}
                                                        />
                                                    </Column>
                                                </Row>
                                            )}
                                        </>
                                    )}

                                    {(values.calculation === "DynamicSpend" ||
                                        values.calculation === "Tactical" ||
                                        values.calculation ===
                                            "TacticalAuthorisedRepairer") && (
                                        <Row>
                                            <Column>
                                                <FormikInput
                                                    value={values.multiplier}
                                                    type="number"
                                                    width="100%"
                                                    labelWidth={100}
                                                    name="multiplier"
                                                    label="Additional Points per Pound"
                                                />
                                            </Column>
                                        </Row>
                                    )}

                                    {values.calculation === "TacticalBonus" && (
                                        <>
                                            <Row>
                                                <Column>
                                                    <FormikInput
                                                        value={
                                                            values.minimum_spend
                                                        }
                                                        type="number"
                                                        width="100%"
                                                        labelWidth={100}
                                                        name="minimum_spend"
                                                        label="Minimum Spend"
                                                    />
                                                </Column>
                                            </Row>
                                            <Row>
                                                <Column>
                                                    <FormikInput
                                                        value={values.points}
                                                        type="number"
                                                        width="100%"
                                                        labelWidth={100}
                                                        name="points"
                                                        label="Bonus Points"
                                                    />
                                                </Column>
                                            </Row>
                                        </>
                                    )}

                                    <Row>
                                        <Column
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <SubmitButton
                                                submitting={loading}
                                                disabled={!secondStepComplete}
                                                onClick={() =>
                                                    onSaveCampaign(values)
                                                }
                                            >
                                                Save Campaign
                                            </SubmitButton>
                                        </Column>
                                    </Row>
                                </>
                            </Form>
                        );
                    }}
                </Formik>
            </Panel>
        </>
    );
};

export default PointsCampaignBuilder;
