import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Row,
    Column,
    InputField,
    SubmitButton,
    useAddToast,
    Button,
    TextareaField,
    Modal,
} from "@cortexglobal/rla-components";
import { Title, Container } from "../components/generalLayoutComponents";
import { set } from "lodash/object";

const CustomerSummary = ({ customerSummary }) => {
    return (
        <>
            <p>
                <strong>Distrigo Number: </strong>
                {customerSummary.trade_team_id ?? "-"}
            </p>
            <p>
                <strong>Company Number: </strong>
                {customerSummary.company_number ?? "-"}
            </p>
            <p>
                <strong>Customer: </strong>
                {customerSummary.trade_team_customer ?? "-"}
            </p>
            <p>
                <strong>Customer Type: </strong>
                {customerSummary.trade_team_customer_type ?? "-"}
            </p>
            {/*
            <p>
                <strong>Customer Address: </strong>
                {[
                    customerSummary.address_1 ?? "",
                    customerSummary.address_2 ?? "",
                    customerSummary.town ?? "",
                    customerSummary.postcode ?? "",
                ]
                    .filter((n) => n)
                    .join(", ")}
            </p>
            */}
            <p>
                <strong>Address 1: </strong>
                {customerSummary.address_1}
            </p>
            <p>
                <strong>Address 2: </strong>
                {customerSummary.address_2}
            </p>
            <p>
                <strong>City/Town: </strong>
                {customerSummary.town}
            </p>
            <p>
                <strong>Postcode: </strong>
                {customerSummary.postcode}
            </p>
            <p>
                <strong>Phone: </strong>
                {customerSummary.phone}
            </p>
            <p>
                <strong>Mobile: </strong>
                {customerSummary.mobile}
            </p>
            <p>
                <strong>Email: </strong>
                {customerSummary.email}
            </p>
        </>
    );
};
const IndividualManualMatch = ({
    companyDetails,
    onClose,
    handleAcceptCustomerMerge,
    loading,
}) => {
    const [acceptWarning, setAcceptWarning] = useState(false);
    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [showFetchSummaryError, setShowFetchSummaryError] = useState(false);
    const [customerSummary, setCustomerSummary] = useState();

    const [searchTerm, setSearchTerm] = useState();
    const [reasonToBeMerge, setReasonToBeMerge] = useState();

    const addToast = useAddToast();

    const handleFetchCustomerSummary = () => {
        if (searchTerm) {
            setFetchingSummary(true);
            setShowFetchSummaryError(false);

            if (parseInt(searchTerm) === companyDetails.trade_team_id) {
                setCustomerSummary(undefined);
                setShowFetchSummaryError(
                    "Merge unavailable, supplied companies are the same"
                );
                setFetchingSummary(false);
                return;
            }

            axios
                .get(`/api/v1/customers/search?term=${searchTerm}`)
                .then(({ data }) => {
                    setFetchingSummary(false);
                    setCustomerSummary(data.data);
                })
                .catch((error) => {
                    setCustomerSummary(undefined);
                    setShowFetchSummaryError(error.response.data.error);
                    setFetchingSummary(false);
                });
        }
    };

    useEffect(() => {}, []);

    return (
        <Row>
            <Column>
                <Title>Merge Customers</Title>
            </Column>

            <Column medium={6}>
                <Container>
                    <Title>This Customer</Title>
                    <CustomerSummary customerSummary={companyDetails} />
                    <div
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                        }}
                    ></div>
                </Container>
            </Column>
            <Column medium={6}>
                <Container>
                    {customerSummary ? (
                        <>
                            <Title>Customer To Be Merged (Deleted)</Title>
                            <CustomerSummary
                                customerSummary={customerSummary}
                            />
                            <br />
                            <div>
                                <TextareaField
                                    width="100%"
                                    labelWidth={100}
                                    name="reason"
                                    label="Reason To Be Merged"
                                    onChange={({ value }) =>
                                        setReasonToBeMerge(value)
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    margin: "2rem",
                                    textAlign: "right",
                                }}
                            >
                                <SubmitButton
                                    onClick={() => setCustomerSummary()}
                                    disabled={!customerSummary || !!loading}
                                    style={{ marginRight: "1rem" }}
                                >
                                    Cancel
                                </SubmitButton>

                                <SubmitButton
                                    loading={!!loading}
                                    onClick={() => setAcceptWarning(true)}
                                    disabled={!customerSummary || !!loading}
                                >
                                    Accept
                                </SubmitButton>
                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                margin: "1rem 0 1rem 0",
                            }}
                        >
                            <div>
                                <InputField
                                    value={searchTerm}
                                    width="100%"
                                    labelWidth={100}
                                    name="searchTerm"
                                    label="Distrigo Number"
                                    onChange={({ value }) =>
                                        setSearchTerm(value)
                                    }
                                />
                            </div>
                            <SubmitButton
                                submitting={fetchingSummary}
                                onClick={() =>
                                    handleFetchCustomerSummary(searchTerm)
                                }
                                style={{
                                    marginLeft: "1rem",
                                }}
                            >
                                Search
                            </SubmitButton>
                        </div>
                    )}
                    {showFetchSummaryError ? (
                        <div>
                            <br />
                            <p style={{ color: "red", fontWeight: "bold" }}>
                                {showFetchSummaryError}
                            </p>
                        </div>
                    ) : (
                        ""
                    )}
                </Container>
            </Column>

            <Modal
                visible={!!acceptWarning}
                onClose={() => setAcceptWarning(null)}
                maxWidth="40%"
            >
                <div>
                    <Container style={{ marginTop: "2rem" }}>
                        <p style={{ textTransform: "uppercase" }}>
                            deleting distrigo id{" "}
                            {customerSummary?.trade_team_id}{" "}
                        </p>
                        <p style={{ textTransform: "uppercase" }}>
                            customer{" "}
                            <span style={{ fontWeight: "bold" }}>
                                {customerSummary?.trade_team_customer}
                            </span>
                        </p>
                        <p>This action will merge their</p>
                        <ul>
                            <li style={{ fontStyle: "italic" }}>Points</li>
                            <li style={{ fontStyle: "italic" }}>
                                Communications
                            </li>
                            <li style={{ fontStyle: "italic" }}>
                                Matched Links
                            </li>
                            <li style={{ fontStyle: "italic" }}>Sales</li>
                        </ul>
                        <p>
                            You will be able to see the progress of this action
                            on Company View under the Merge Status Tab
                        </p>
                        <br />
                        <SubmitButton
                            onClick={() => {
                                setAcceptWarning(null);
                            }}
                            disabled={!customerSummary || !!loading}
                            style={{ marginRight: "1rem" }}
                        >
                            Cancel
                        </SubmitButton>

                        <SubmitButton
                            loading={!!loading}
                            onClick={() =>
                                handleAcceptCustomerMerge({
                                    customer_id_from: customerSummary.id,
                                    reason: reasonToBeMerge,
                                })
                            }
                            disabled={!customerSummary || !!loading}
                        >
                            Still Accept
                        </SubmitButton>
                    </Container>
                </div>
            </Modal>
        </Row>
    );
};

export default IndividualManualMatch;
