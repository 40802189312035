import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Row,
    Column,
    Button,
    InputField,
    useAddToast,
} from "@cortexglobal/rla-components";
import styled from "styled-components";
import { CubeLoader } from "@cortexglobal/lens-components";

const DetailsTable = styled.table`
    border-spacing: 0;

    tr:nth-child(odd) {
        background: #f7f7f7;
    }

    td {
        padding: 1rem;
    }
`;

const BoldTd = styled.td`
    font-weight: bold;
`;

const ViewCreditOrder = ({ rewardOrderData }) => {
    const addToast = useAddToast();
    const [creditDetails, setCreditDetails] = useState({
        voucher_code: "HIDDEN",
        voucher_value: "HIDDEN",
    });
    const [revealStatus, setRevealStatus] = useState("");

    useEffect(() => {
        if (rewardOrderData.id !== undefined) {
            revealCreditCode(rewardOrderData.id);
        }
    }, []);

    const revealCreditCode = (rewardOrderId) => {
        setRevealStatus("loading");
        axios
            .post(`/api/v1/reward-orders/${rewardOrderId}/reveal-code`, {
                reveal_purpose: "Viewing credit voucher details",
            })
            .then(({ data }) => {
                setCreditDetails(data.data);
                setRevealStatus("loaded");
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error getting credit voucher details.",
                    showFor: 5000,
                });
                setCreditDetails({
                    voucher_code: "HIDDEN",
                    voucher_value: "HIDDEN",
                });
                setRevealStatus("error");
            });
    };

    return (
        <>
            <DetailsTable style={{ width: "100%" }}>
                {revealStatus === "loading" ? (
                    <tbody>
                        <tr>
                            <td>
                                <CubeLoader />
                                Loading...
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <BoldTd>Voucher Code:</BoldTd>
                            <td>{creditDetails.voucher_code}</td>
                        </tr>
                        <tr>
                            <BoldTd>Voucher Value:</BoldTd>
                            <td>{creditDetails.voucher_value}</td>
                        </tr>
                    </tbody>
                )}
            </DetailsTable>
        </>
    );
};

export default ViewCreditOrder;
