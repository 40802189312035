import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button
} from "@cortexglobal/rla-components";

import { faFile } from "@fortawesome/free-regular-svg-icons/faFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReportingContainer = styled.div`
    padding: 1em 0;
`;

const reports = [
    {
        name: 'All Activated Customers',
        link: '/'
    },
    {
        name: 'All Customers in Tier 2',
        link: '/'
    },
    {
        name: 'Communication Unsubscribes Report',
        link: '/'
    },
    {
        name: 'Communications Statistics',
        link: '/'
    },
    {
        name: 'Competition Entries',
        link: '/'
    },
    {
        name: 'Customer Explorer Activation Emails',
        link: '/'
    },
    {
        name: 'Customer Explorer Searches',
        link: '/'
    },
    {
        name: 'Customer Explorer Views',
        link: '/'
    }
];

const Report = styled.div`
    padding: 1em 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
`;

const PointsReport = (props) => {
    return (
        <>
            <PageTitle title="Points Reports" expanded>
            </PageTitle>
            <ReportingContainer>
                <Row>
                    <Column>
                        <p>Select the start and end date for your report (or leave the date fields blank to see all data), then select your report from the options below. The report will download in your browser as a Microsoft Excel CSV file.</p>

                        {reports.map(report => (
                            <Report>
                                <span>
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: '1.5em',
                                        marginRight: '1em'
                                    }}
                                    icon={faFile}
                                />
                                    {report.name}
                                </span>
                                <Button>Download</Button>
                            </Report>
                        ))}
                    </Column>
                </Row>
            </ReportingContainer>

        </>
    );
};

export default PointsReport;


