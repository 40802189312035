import React, { useState } from "react";
import styled from "styled-components";
import {
    Button,
    Column,
    FormikCheckbox,
    FormikDatePicker,
    FormikInput,
    FormikSelect,
    FormikTextarea,
    Panel,
    Row,
    SubmitButton,
    DraftJSField,
    FormLabel,
    StyledDropzone,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import {
    CubeLoader
} from "@cortexglobal/lens-components";

const Schema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    job_title: Yup.string(),
    email: Yup.string().required("Required"),
    phone: Yup.string(),
    mobile_phone: Yup.string(),
    primary_contact: Yup.boolean(),
    legitimate_interest: Yup.boolean(),
    business_email: Yup.boolean(),
    ar_perks_permission: Yup.boolean(),
    can_nominate_ar: Yup.boolean(),
    channel_email: Yup.boolean(),
    channel_sms: Yup.boolean(),
    channel_post: Yup.boolean(),
    customer_id: Yup.number(),
});

const ContactForm = ({ handleSubmit, loading, values }) => {
    const [customerCheckLoading, setCustomerCheckLoading] = useState(false);
    const [customerCheckError, setCustomerCheckError] = useState(false);

    const [customerName, setCustomerName] = useState(
        values && values.customer_name ? values.customer_name : ""
    );

    const queryString = new URLSearchParams(useLocation().search);
    const id = queryString.get("id") || "";

    const checkCustomer = (tradeTeamId) => {
        setCustomerCheckLoading(true);
        setCustomerCheckError(false);

        axios
            .get(`/api/v1/customers/${tradeTeamId}/summary`)
            .then(({ data }) => {
                setCustomerCheckLoading(false);
                setCustomerName(data.data.trade_team_customer);
            })
            .catch((error) => {
                console.log(error);
                setCustomerName("");
                setCustomerCheckError(true);
                setCustomerCheckLoading(false);
            });
    };

    return (
        <Panel style={{ padding: "2em" }}>
            <Formik
                initialValues={{
                    first_name:
                        values && values.first_name ? values.first_name : "",
                    last_name:
                        values && values.last_name ? values.last_name : "",
                    job_title:
                        values && values.job_title ? values.job_title : "",
                    email: values && values.email ? values.email : "",
                    phone: values && values.phone ? values.phone : "",
                    mobile_phone:
                        values && values.mobile_phone
                            ? values.mobile_phone
                            : "",
                    primary_contact: values && values.primary_contact === "Yes",
                    legitimate_interest: values && values.legitimate_interest === "Yes",
                    business_email: values && values.business_email === "Yes",
                    ar_perks_permission: values && values.ar_perks_permission === "Yes",
                    can_nominate_ar: values && values.can_nominate_ar === "Yes",
                    channel_email: values && values.channel_email === "Yes",
                    channel_sms: values && values.channel_sms === "Yes",
                    channel_post: values && values.channel_post === "Yes",
                    trade_team_id:
                        values && values.trade_team_id
                            ? values.trade_team_id
                            : id,
                }}
                onSubmit={handleSubmit}
                validationSchema={Schema}
            >
                {({
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleBlur,
                    errors,
                }) => {
                    return (
                        <Form>
                            <Row>
                                <Column>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ marginRight: "1em" }}>
                                            <FormikInput
                                                value={values.trade_team_id}
                                                width="100%"
                                                labelWidth={100}
                                                name="trade_team_id"
                                                label="Trade Team ID (Optional)"
                                            />
                                        </div>
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                checkCustomer(
                                                    values.trade_team_id
                                                )
                                            }
                                        >
                                            Check ID
                                        </Button>
                                        {customerCheckLoading && (
                                            <span
                                                style={{
                                                    marginRight: "2em",
                                                    marginTop: "0.5em",
                                                    marginLeft: "1.5em",
                                                }}
                                            >
                                                <CubeLoader
                                                    size={20}
                                                    margin="0"
                                                />
                                            </span>
                                        )}
                                    </div>

                                    {customerName && (
                                        <p style={{ marginBottom: "2em" }}>
                                            <b>Customer: </b>
                                            {customerName}
                                        </p>
                                    )}

                                    {customerCheckError && (
                                        <p
                                            style={{
                                                color: "red",
                                                marginBottom: "2em",
                                            }}
                                        >
                                            Please enter a valid Trade Team ID.
                                        </p>
                                    )}

                                    <FormikInput
                                        value={values.first_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="first_name"
                                        label="First Name"
                                    />
                                    <FormikInput
                                        value={values.last_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="last_name"
                                        label="Last Name"
                                    />
                                    <FormikInput
                                        value={values.job_title}
                                        width="100%"
                                        labelWidth={100}
                                        name="job_title"
                                        label="Job Title"
                                    />
                                    <FormikInput
                                        value={values.email}
                                        width="100%"
                                        labelWidth={100}
                                        name="email"
                                        label="Email"
                                    />
                                    <FormikInput
                                        value={values.phone}
                                        width="100%"
                                        labelWidth={100}
                                        name="phone"
                                        label="Phone"
                                    />
                                    <FormikInput
                                        value={values.mobile_phone}
                                        width="100%"
                                        labelWidth={100}
                                        name="mobile_phone"
                                        label="Mobile Phone"
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="primary_contact"
                                        label="Is Primary Contact"
                                        value={values.primary_contact}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="can_nominate_ar"
                                        label="Can Norminate AR"
                                        value={values.can_nominate_ar}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="business_email"
                                        label="Is Business Email"
                                        value={values.business_email}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="legitimate_interest"
                                        label="Has Legitimate Interest"
                                        value={values.legitimate_interest}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="ar_perks_permission"
                                        label="Has AR PERKS Permission"
                                        value={values.ar_perks_permission}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="channel_email"
                                        label="Can Send Email"
                                        value={values.channel_email}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="channel_sms"
                                        label="Can Send SMS"
                                        value={values.channel_sms}
                                    />
                                    <FormikCheckbox
                                        type="text"
                                        name="channel_post"
                                        label="Can Send Post"
                                        value={values.channel_post}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <SubmitButton submitting={loading}>
                                        Save Contact
                                    </SubmitButton>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Panel>
    );
};

export default ContactForm;
