import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import AdjustmentsList from "./AdjustmentsList";
import AddAdjustment from "./AddAdjustment";
import ViewAdjustment from "./ViewAdjustment";

const Adjustments = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/add`} component={AddAdjustment} />
                <Route path={`${match.path}/:id`} component={ViewAdjustment} />
                <Route path={`${match.path}`} component={AdjustmentsList} />
            </Switch>
        </>
    );
};

export default Adjustments;
