import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    FormikInput,
    FormikSelect,
    FormikDatePicker,
    FormikCheckbox,
    FormikTextarea,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";

import { CubeLoader } from "@cortexglobal/lens-components";
import CompanyForm from "./CompanyForm";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const EditCompany = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState();
    const history = useHistory();
    const addToast = useAddToast();

    const getContact = () => {
        setLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}`)
            .then(({ data }) => {
                setCompanyData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting compaany.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const handleSubmit = (value) => {
        setLoading(true);
        axios
            .put(`/api/v1/customers/${match.params.id}`, {
                company_number: value.company_number,
                trade_team_id: value.trade_team_id,
                trade_team_customer: value.trade_team_customer,
                alternative_customer_name: value.alternative_customer_name,
                customer_status: value.customer_status,
                trade_team_customer_type: value.trade_team_customer_type,
                rrdi_code: value.rrdi_code,
                dealer_code_for_leads: value.dealer_code_for_leads,
                address_1: value.address_1,
                address_2: value.address_2,
                address_3: value.address_3,
                town: value.town,
                county: value.county,
                postcode: value.postcode,
                email: value.email,
                phone: value.phone,
                mobile: value.mobile,
                vat: value.vat,
                brand_specialist: value.brand_specialist,
                mot_centre: value.mot_centre,
                number_of_bays: value.number_of_bays,
                number_of_technicians: value.number_of_technicians,
                number_of_trade_team_programmes:
                    value.number_of_trade_team_programmes,
                average_vehicles_per_week: value.average_vehicles_per_week,
                average_weekly_spend_brand_parts:
                    value.average_weekly_spend_brand_parts,
                average_age_vehicles: value.average_age_vehicles,
            })
            .then(({ data }) => {
                history.push(`/companies/${data.data.customer_id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error updating your company.",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getContact();
    }, []);

    return (
        <>
            <PageTitle
                title={
                    companyData
                        ? `Editing: ${companyData.trade_team_customer}`
                        : `Editing`
                }
                expanded
            >
                <Link to={`/companies/${match.params.id}`}>
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Customer
                    </Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && companyData && (
                <ContactContainer>
                    <Row>
                        <Column>
                            <CompanyForm
                                handleSubmit={handleSubmit}
                                loading={loading}
                                values={companyData}
                            />
                        </Column>
                    </Row>
                </ContactContainer>
            )}
        </>
    );
};

export default EditCompany;
