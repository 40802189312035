import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {
    Button,
    Column,
    FormikCheckbox,
    FormikDatePicker,
    FormikInput,
    FormikSelect,
    FormikTextarea,
    Panel,
    Row,
    Tabordion,
    SubmitButton,
    DraftJSField,
    FormLabel,
    StyledDropzone, HeaderWithErrorIndicated,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useLocation } from "react-router-dom";

import {
    CubeLoader
} from "@cortexglobal/lens-components";
import {parseBool} from "@cortexglobal/cortex-utilities/lib/helpers";
import {TabordionGroup} from "../components/TabordionGroup";

const Schema = Yup.object().shape({
    rrdi_code: Yup.string(),
    brand: Yup.string().required("Required"),
    brand_for_comms: Yup.string(),
    dealer_code: Yup.string().required("Required"),
    parent_code: Yup.string(),
    dealer_name: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    hub_code: Yup.string(),
    hub_id: Yup.string(),
    buy_from_hub: Yup.string(),
    buy_from_hub_id: Yup.string(),
    name_for_comms: Yup.string(),
    group_code: Yup.string(),
    group_name: Yup.string(),
    other_dealer_codes: Yup.string(),
    dealer_hub_id: Yup.string(),
    parts_zone: Yup.string(),
    group_view: Yup.string(),
    address_1: Yup.string(),
    address_2: Yup.string(),
    town: Yup.string(),
    county: Yup.string(),
    postcode: Yup.string(),
    single_address: Yup.string(),
    single_opening_hours: Yup.string(),
    latitude: Yup.string(),
    longitude: Yup.string(),
    phone_number: Yup.string(),
    parts_phone_number: Yup.string(),
    fax_number: Yup.string(),
    website: Yup.string(),
    email: Yup.string(),
    genuine: Yup.boolean(),
    distrigo: Yup.boolean(),
    dealer_logo: Yup.string(),
    parts_manager_email: Yup.string(),
    new_vehicle_distributor: Yup.boolean(),
    authorised_repairer: Yup.boolean(),
    market: Yup.boolean(),
    parts_distributor: Yup.boolean(),
    sub_brand_fiat: Yup.boolean(),
    sub_brand_alfa_romeo: Yup.boolean(),
    sub_brand_abarth: Yup.boolean(),
    sub_brand_fiat_professional: Yup.boolean(),
    sub_brand_jeep: Yup.boolean(),
    sub_brand_chrysler: Yup.boolean(),
    sub_brand_dodge: Yup.boolean(),
    relay: Yup.boolean(),
    vauxhall: Yup.boolean(),
    peugeot: Yup.boolean(),
    citroen: Yup.boolean(),
    ds_automobiles: Yup.boolean(),
    eurorepar: Yup.boolean(),
    fiat: Yup.boolean(),
    fiat_professional: Yup.boolean(),
    jeep: Yup.boolean(),
    alfa_romeo: Yup.boolean(),
    abarth: Yup.boolean(),
    supplier_brands: Yup.boolean(),
    renew: Yup.boolean(),
    club_ricambi: Yup.boolean(),
    motion: Yup.boolean(),
    inactive_or_terminated: Yup.boolean(),
    shown_in_centre_locator: Yup.boolean(),
    can_be_picked_as_preferred_pd: Yup.boolean(),
});

const DealerForm = ({ handleSubmit, loading, values, formType }) => {
    const [dealerTypeCheckLoading, setDealerTypeCheckLoading] = useState(false);
    const [dealerTypeCheckError, setDealerTypeCheckError] = useState(false);

    const [parentDealerName, setParentDealerName] = useState(
        values && values.customer_name ? values.customer_name : ""
    );
    const [parentDealerCheckLoading, setParentDealerCheckLoading] = useState(false);
    const [parentDealerCheckError, setParentDealerCheckError] = useState(false);

    const [dealerTypes, setDealerTypes] = useState();
    const [dealerBrands, setDealerBrands] = useState();
    const [tab, setTab] = useState(0);

    const queryString = new URLSearchParams(useLocation().search);
    const id = queryString.get("id") || "";

    const checkParentDealer = (parentDealerCode) => {
        setParentDealerCheckLoading(true);
        setParentDealerCheckError(false);

        axios
            .get(`/api/v1/dealers/by-code/${parentDealerCode}`)
            .then(({ data }) => {
                console.log(data);
                setParentDealerCheckLoading(false);
                setParentDealerName(`${data.data.dealer_name} - (${data.data.dealer_code})` );
            })
            .catch((error) => {
                console.log(error);
                setParentDealerName("");
                setParentDealerCheckError(true);
                setParentDealerCheckLoading(false);
            });
    };


    useEffect(() => {
        checkDealerTypes();
    }, []);

    const checkDealerTypes = () => {
        setDealerTypeCheckLoading(true);
        setDealerTypeCheckError(false);

        axios
            .get(`/api/v1/dealers/types`)
            .then(({ data }) => {
                console.log(data);
                setDealerTypeCheckLoading(false);

                setDealerBrands(data.data.brands);
                setDealerTypes(data.data.types);
            })
            .catch((error) => {
                console.log(error);

                setDealerTypeCheckError(true);
                setDealerTypeCheckLoading(false);
            });
    };

    return (
        <Panel style={{ padding: "2em" }}>
            <Formik
                initialValues={{
                    rrdi_code:
                        values && values.rrdi_code ? values.rrdi_code : "",
                    brand:
                        values && values.brand ? values.brand : "",
                    brand_for_comms:
                        values && values.brand_for_comms ? values.brand_for_comms : "",
                    dealer_code: values && values.dealer_code ? values.dealer_code : "",
                    parent_code: values && values.parent_code ? values.parent_code : "",
                    dealer_name:
                        values && values.dealer_name
                            ? values.dealer_name
                            : "",
                    type: values && values.type ? values.type : "",
                    hub_code: values && values.hub_code ? values.hub_code : "",
                    hub_id: values && values.hub_id ? values.hub_id : "",
                    buy_from_hub: values && values.buy_from_hub ? values.buy_from_hub : "",
                    name_for_comms: values && values.name_for_comms ? values.name_for_comms : "",
                    dealer_name_for_comms: values && values.dealer_name_for_comms ? values.dealer_name_for_comms : "",
                    area_for_comms: values && values.area_for_comms ? values.area_for_comms : "",
                    group_code: values && values.group_code ? values.group_code : "",
                    group_name: values && values.group_name ? values.group_name : "",
                    other_dealer_codes: values && values.other_dealer_codes ? values.other_dealer_codes : "",
                    dealer_hub_code: values && values.dealer_hub_code ? values.dealer_hub_code : "",
                    dealer_hub_id: values && values.dealer_hub_id ? values.dealer_hub_id : "",
                    parts_zone: values && values.parts_zone ? values.parts_zone : "",
                    group_view: values && values.group_view ? values.group_view : "",
                    address_1: values && values.address_1 ? values.address_1 : "",
                    address_2: values && values.address_2 ? values.address_2 : "",
                    town: values && values.town ? values.town : "",
                    county: values && values.county ? values.county : "",
                    postcode: values && values.postcode ? values.postcode : "",
                    single_address: values && values.single_address ? values.single_address : "",
                    single_opening_hours: values && values.single_opening_hours ? values.single_opening_hours : "",
                    latitude: values && values.latitude ? values.latitude : "",
                    longitude: values && values.longitude ? values.longitude : "",
                    phone_number: values && values.phone_number ? values.phone_number : "",
                    parts_phone_number: values && values.parts_phone_number ? values.parts_phone_number : "",
                    fax_number: values && values.fax_number ? values.fax_number : "",
                    website: values && values.website ? values.website : "",
                    email: values && values.email ? values.email : "",
                    genuine: values && values.genuine ? values.genuine : false,

                    sub_brand_fiat: values && values.sub_brand_fiat ? values.sub_brand_fiat : false,
                    sub_brand_alfa_romeo: values && values.sub_brand_alfa_romeo ? values.sub_brand_alfa_romeo : false,
                    sub_brand_abarth: values && values.sub_brand_abarth ? values.sub_brand_abarth : false,
                    sub_brand_fiat_professional: values && values.sub_brand_fiat_professional ? values.sub_brand_fiat_professional : false,
                    sub_brand_jeep: values && values.sub_brand_jeep ? values.sub_brand_jeep : false,
                    sub_brand_chrysler: values && values.sub_brand_chrysler ? values.sub_brand_chrysler : false,
                    sub_brand_dodge: values && values.sub_brand_dodge ? values.sub_brand_dodge : false,

                    distrigo: values && values.distrigo ? values.distrigo : "",
                    dealer_logo: values && values.dealer_logo ? values.dealer_logo : "",
                    parts_manager_email: values && values.parts_manager_email ? values.parts_manager_email : "",
                    new_vehicle_distributor: values && values.new_vehicle_distributor ? values.new_vehicle_distributor : "",
                    authorised_repairer: values && values.authorised_repairer ? values.authorised_repairer : false,
                    market: values && values.market ? values.market : false,
                    parts_distributor: values && values.parts_distributor ? values.parts_distributor : false,
                    relay: values && values.relay ? values.relay : false,
                    vauxhall: values && values.vauxhall ? values.vauxhall : false,

                    peugeot: values && values.peugeot ? values.peugeot : false,
                    citroen: values && values.citroen ? values.citroen : false,
                    ds_automobiles: values && values.ds_automobiles ? values.ds_automobiles : false,
                    eurorepar: values && values.eurorepar ? values.eurorepar : false,
                    fiat: values && values.fiat ? values.fiat : false,
                    fiat_professional: values && values.fiat_professional ? values.fiat_professional : false,
                    jeep: values && values.jeep ? values.jeep : false,
                    abarth: values && values.abarth ? values.abarth : false,
                    alfa_romeo: values && values.alfa_romeo ? values.alfa_romeo : false,
                    supplier_brands: values && values.supplier_brands ? values.supplier_brands : false,
                    renew: values && values.renew ? values.renew : false,
                    club_ricambi: values && values.club_ricambi ? values.club_ricambi : false,
                    motion: values && values.motion ? values.motion : false,
                    trade_team_id: values && values.trade_team_id ? values.trade_team_id : "",
                    inactive_or_terminated: values && values.inactive_or_terminated ? values.inactive_or_terminated : false,
                    shown_in_centre_locator: values && values.shown_in_centre_locator ? values.shown_in_centre_locator : false,
                    can_be_picked_as_preferred_pd: values && values.can_be_picked_as_preferred_pd ? values.can_be_picked_as_preferred_pd : false,
                }}
                onSubmit={handleSubmit}
                validationSchema={Schema}
            >
                {({
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleBlur,
                    errors,
                }) => {
                    console.log(errors)
                    return (
                        <Form>
                            <Tabordion current={tab} unmounts={false}>
                                <TabordionGroup
                                    heading={
                                        "Details"
                                    }
                                    onTabClick={() => setTab(0)}
                                >
                                    <Row>

                                        <Column>

                                            <FormikInput
                                                value={values.dealer_name}
                                                width="100%"
                                                labelWidth={100}
                                                name="dealer_name"
                                                label="Dealer Name"
                                            />
                                            <FormikInput
                                                value={values.dealer_code}
                                                width="100%"
                                                labelWidth={100}
                                                disabled={formType === "edit"}
                                                name="dealer_code"
                                                label="Dealer Code"
                                            />

                                            <FormikSelect
                                                value={
                                                    values.brand
                                                }
                                                label="Brand"
                                                name="brand"
                                                options={
                                                    dealerBrands
                                                }
                                            />

                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div style={{ marginRight: "1em" }}>
                                                    <FormikInput
                                                        value={values.parent_code}
                                                        width="100%"
                                                        labelWidth={100}
                                                        name="parent_code"
                                                        label="Parent Dealer Code (Optional)"
                                                    />
                                                </div>
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        checkParentDealer(
                                                            values.parent_code
                                                        )
                                                    }
                                                >
                                                    Check Dealer Code
                                                </Button>
                                                {parentDealerCheckLoading && (
                                                    <span
                                                        style={{
                                                            marginRight: "2em",
                                                            marginTop: "0.5em",
                                                            marginLeft: "1.5em",
                                                        }}
                                                    >
                                                <CubeLoader
                                                    size={20}
                                                    margin="0"
                                                />
                                            </span>
                                                )}
                                            </div>

                                            {parentDealerName && (
                                                <p style={{ marginBottom: "2em" }}>
                                                    <b>Potential Parent Dealer: </b>
                                                    {parentDealerName}
                                                </p>
                                            )}

                                            {parentDealerCheckError && (
                                                <p
                                                    style={{
                                                        color: "red",
                                                        marginBottom: "2em",
                                                    }}
                                                >
                                                    Please enter a valid Dealer Code.
                                                </p>
                                            )}
                                            <FormikInput
                                                value={values.rrdi_code}
                                                width="100%"
                                                labelWidth={100}
                                                name="rrdi_code"
                                                label="RRDI Code"
                                            />

                                            <FormikSelect
                                                value={
                                                    values.type
                                                }
                                                label="Dealer Type"
                                                name="type"
                                                options={
                                                    dealerTypes
                                                }
                                            />
                                            <FormikInput
                                                value={values.address_1}
                                                width="100%"
                                                labelWidth={100}
                                                name="address_1"
                                                label="Address 1"
                                            />
                                            <FormikInput
                                                value={values.address_2}
                                                width="100%"
                                                labelWidth={100}
                                                name="address_2"
                                                label="Address 2"
                                            />
                                            <FormikInput
                                                value={values.town}
                                                width="100%"
                                                labelWidth={100}
                                                name="town"
                                                label="Town"
                                            />
                                            <FormikInput
                                                value={values.county}
                                                width="100%"
                                                labelWidth={100}
                                                name="county"
                                                label="County"
                                            />
                                            <FormikInput
                                                value={values.postcode}
                                                width="100%"
                                                labelWidth={100}
                                                name="postcode"
                                                label="Postcode"
                                            />

                                            <FormikInput
                                                value={values.parts_zone}
                                                width="100%"
                                                labelWidth={100}
                                                name="parts_zone"
                                                label="Parts Zone"
                                            />

                                            <FormikInput
                                                value={values.group_view}
                                                width="100%"
                                                labelWidth={100}
                                                name="group_view"
                                                label="Group View"
                                            />

                                            <FormikInput
                                                value={values.parts_phone_number}
                                                width="100%"
                                                labelWidth={100}
                                                name="parts_phone_number"
                                                label="Parts Phone Number"
                                            />

                                            <FormikInput
                                                value={values.phone_number}
                                                width="100%"
                                                labelWidth={100}
                                                name="phone_number"
                                                label="Phone Number"
                                            />

                                            <FormikInput
                                                value={values.fax_number}
                                                width="100%"
                                                labelWidth={100}
                                                name="fax_number"
                                                label="Fax Number"
                                            />

                                            <FormikInput
                                                value={values.website}
                                                width="100%"
                                                labelWidth={100}
                                                name="website"
                                                label="Website"
                                            />

                                            <FormikInput
                                                value={values.email}
                                                width="100%"
                                                labelWidth={100}
                                                name="email"
                                                label="Email"
                                            />

                                            <FormikInput
                                                value={values.parts_manager_email}
                                                width="100%"
                                                labelWidth={100}
                                                name="parts_manager_email"
                                                label="Parts Manager Email"
                                            />

                                            <FormikInput
                                                value={values.hub_code}
                                                width="100%"
                                                labelWidth={100}
                                                name="hub_code"
                                                label="Hub Code"
                                            />

                                            <FormikInput
                                                value={values.hub_id}
                                                width="100%"
                                                labelWidth={100}
                                                name="hub_id"
                                                label="Hub Id"
                                            />

                                            <FormikInput
                                                value={values.group_code}
                                                width="100%"
                                                labelWidth={100}
                                                name="group_code"
                                                label="Group Code"
                                            />
                                            <FormikInput
                                                value={values.group_name}
                                                width="100%"
                                                labelWidth={100}
                                                name="group_name"
                                                label="Group Name"
                                            />
                                            <FormikInput
                                                value={values.other_dealer_codes}
                                                width="100%"
                                                labelWidth={100}
                                                name="other_dealer_codes"
                                                label="Other Dealer Codes"
                                            />

                                            <FormikInput
                                                value={values.single_address}
                                                width="100%"
                                                labelWidth={100}
                                                name="single_address"
                                                label="Single Address"
                                            />
                                            <FormikInput
                                                value={values.single_opening_hours}
                                                width="100%"
                                                labelWidth={100}
                                                name="single_opening_hours"
                                                label="Single Opening Hours"
                                            />

                                            <FormikInput
                                                value={values.trade_team_id}
                                                width="100%"
                                                labelWidth={100}
                                                name="trade_team_id"
                                                label="Trade Team ID"
                                            />

                                            <FormikInput
                                                value={values.latitude}
                                                width="100%"
                                                labelWidth={100}
                                                name="latitude"
                                                label="Latitude"
                                            />
                                            <FormikInput
                                                value={values.longitude}
                                                width="100%"
                                                labelWidth={100}
                                                name="longitude"
                                                label="Longitute"
                                            />
                                            <FormikInput
                                                value={values.dealer_logo}
                                                width="100%"
                                                labelWidth={100}
                                                name="dealer_logo"
                                                label="Dealer Logo"
                                            />

                                        </Column>
                                    </Row>
                                </TabordionGroup>
                                {values.brand === 'Multi Brand' && (
                                    <TabordionGroup
                                        heading={
                                            "Brands"
                                        }
                                        onTabClick={() => setTab(1)}
                                    >
                                        <Row>

                                            <Column>

                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_fiat"
                                                    label="Fiat"
                                                    value={values.sub_brand_fiat}
                                                />
                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_alfa_romeo"
                                                    label="Alfa Romeo"
                                                    value={values.sub_brand_alfa_romeo}
                                                />
                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_abarth"
                                                    label="Abarth"
                                                    value={values.sub_brand_abarth}
                                                />
                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_fiat_professional"
                                                    label="Fiat Professional"
                                                    value={values.sub_brand_fiat_professional}
                                                />

                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_jeep"
                                                    label="Jeep"
                                                    value={values.sub_brand_jeep}
                                                />

                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_chrysler"
                                                    label="Chrysler"
                                                    value={values.sub_brand_chrysler}
                                                />

                                                <FormikCheckbox
                                                    type="text"
                                                    name="sub_brand_dodge"
                                                    label="Dodge"
                                                    value={values.sub_brand_dodge}
                                                />

                                            </Column>
                                        </Row>
                                    </TabordionGroup>
                                )}
                                <TabordionGroup
                                    heading={
                                        "Type"
                                    }
                                    onTabClick={() => setTab(1)}
                                >
                                    <Row>

                                        <Column>

                                            <FormikCheckbox
                                                type="text"
                                                name="new_vehicle_distributor"
                                                label="New Vehicle Distributor"
                                                value={values.new_vehicle_distributor}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="authorised_repairer"
                                                label="Authorised Repairer"
                                                value={values.authorised_repairer}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="market"
                                                label="Market"
                                                value={values.market}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="parts_distributor"
                                                label="Parts Distributor"
                                                value={values.parts_distributor}
                                            />

                                            <FormikCheckbox
                                                type="text"
                                                name="relay"
                                                label="Relay"
                                                value={values.relay}
                                            />

                                            <FormikCheckbox
                                                type="text"
                                                name="club_ricambi"
                                                label="Club Ricambi"
                                                value={values.club_ricambi}
                                            />

                                            <FormikCheckbox
                                                type="text"
                                                name="motion"
                                                label="Motion"
                                                value={values.motion}
                                            />


                                        </Column>
                                    </Row>
                                </TabordionGroup>
                            {values.parts_distributor && (

                                <TabordionGroup
                                heading={
                                    "Part Brands"
                                }
                                onTabClick={() => setTab(2)}
                                >
                                    <Row>

                                        <Column>

                                            <FormikCheckbox
                                                value={values.genuine}
                                                type="text"
                                                name="genuine"
                                                label="Genuine"
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="distrigo"
                                                label="Distrigo"
                                                value={values.distrigo}
                                            />

                                            <FormikCheckbox
                                                type="text"
                                                name="vauxhall"
                                                label="Vauxhall"
                                                value={values.vauxhall}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="peugeot"
                                                label="Peugeot"
                                                value={values.peugeot}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="citroen"
                                                label="Citroen"
                                                value={values.citroen}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="ds_automobiles"
                                                label="DS Automobiles"
                                                value={values.ds_automobiles}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="eurorepar"
                                                label="Eurorepar"
                                                value={values.eurorepar}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="fiat"
                                                label="Fiat"
                                                value={values.fiat}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="fiat_professional"
                                                label="Fiat Professional"
                                                value={values.fiat_professional}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="jeep"
                                                label="Jeep"
                                                value={values.jeep}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="abarth"
                                                label="Abarth"
                                                value={values.abarth}
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="alfa_romeo"
                                                label="Alfa Romeo"
                                                value={values.alfa_romeo}
                                            />

                                            <FormikCheckbox
                                                type="text"
                                                name="supplier_brands"
                                                label="Supplier Brands"
                                                value={values.supplier_brands}
                                            />

                                            <FormikCheckbox
                                                type="text"
                                                name="renew"
                                                label="SUSTAINera"
                                                value={values.renew}
                                            />

                                        </Column>
                                    </Row>
                                </TabordionGroup>
                            )}
                                <TabordionGroup
                                    heading={
                                        "Comms"
                                    }
                                    onTabClick={() => setTab(3)}
                                >
                                    <Row>

                                        <Column>
                                            <FormikInput
                                                value={values.brand_for_comms}
                                                width="100%"
                                                labelWidth={100}
                                                name="brand_for_comms"
                                                label="Brand for Comms"
                                            />
                                            <FormikInput
                                                value={values.dealer_name_for_comms}
                                                width="100%"
                                                labelWidth={100}
                                                name="dealer_name_for_comms"
                                                label="Dealer Name for Comms"
                                            />

                                            <FormikInput
                                                value={values.area_for_comms}
                                                width="100%"
                                                labelWidth={100}
                                                name="area_for_comms"
                                                label="Area for Comms"
                                            />
                                        </Column>
                                    </Row>
                                </TabordionGroup>
                                <TabordionGroup
                                    heading={
                                        "Distrigo"
                                    }
                                    onTabClick={() => setTab(4)}
                                >
                                    <Row>

                                        <Column>
                                            <FormikCheckbox
                                                value={values.shown_in_centre_locator}
                                                type="text"
                                                name="shown_in_centre_locator"
                                                label="Shown In Centre Locator"
                                            />
                                            <FormikCheckbox
                                                value={values.can_be_picked_as_preferred_pd}
                                                type="text"
                                                name="can_be_picked_as_preferred_pd"
                                                label="Can Be Picked As Preferred PD"
                                            />
                                        </Column>
                                    </Row>
                                </TabordionGroup>
                            </Tabordion>
                            <Row>
                                <Column
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <SubmitButton submitting={loading}>
                                        Save Dealer
                                    </SubmitButton>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Panel>
    );
};

export default DealerForm;
