import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { debounce } from "lodash";
import queryString from "querystring";

import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    InputField,
    Pagination,
    SelectField,
    Loading,
    useAddToast,
} from "@cortexglobal/rla-components";

import { Subscribe } from "@cortexglobal/pref-centre";

import {
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    CubeLoader,
    ButtonDropdown,
    currentPage,
} from "@cortexglobal/lens-components";

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SelectedCampaigns = styled.div`
    position: absolute;
    background: white;
    bottom: -10px;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    width: calc(100% - 150px);
    position: fixed;
`;

const CampaignsList = () => {
    const history = useHistory();
    const addToast = useAddToast();

    const { orderBy, orderDir, search, searchBy, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [totalCampaigns, setTotalCampaigns] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [searchValue, setSearchValue] = useState(search);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );

    const [loading, setLoading] = useState(false);
    const [multiLoading, setMultiLoading] = useState(false);
    const [singleLoading, setSingleLoading] = useState();

    const getCampaigns = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/campaigns`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setCampaigns(data.data);
                setTotalCampaigns(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting campaigns.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getCampaigns();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.search_val ? `&search=${data.search_val}` : ""
            }${data.search_val ? `&searchBy=${data.search_by}` : ""}${
                data.global_search_val
                    ? `&globalSearch=${data.global_search_val}`
                    : ""
            }`,
        });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch(() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
            getCampaigns({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
        });
    };

    const handleGlobalSearch = ({ value }) => {
        setGlobalSearchValue(value);
        setSearchValue("");
        setSearchByValue("");

        debounceSearch(() => {
            setParams({
                global_search_val: value,
                page: 1,
            });
            getCampaigns({
                global_search_val: value,
                page: 1,
            });
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getCampaigns({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getCampaigns({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getCampaigns({
            page: selected + 1,
        });
    };

    const handleClickCampaign = (id) => {
        if (selectedCampaigns.includes(id)) {
            setSelectedCampaigns([
                ...selectedCampaigns.filter((customer) => customer !== id),
            ]);
        } else {
            setSelectedCampaigns([...selectedCampaigns, id]);
        }
    };

    const handleSelectAll = () => {
        setSelectedCampaigns(campaigns.map((campaign) => campaign.id));
    };

    const updateStatusVisuals = (updatedIds, status) => {
        setCampaigns(
            campaigns.map((campaign) => {
                if (updatedIds.includes(campaign.id)) {
                    return {
                        ...campaign,
                        status: status,
                    };
                }
                return campaign;
            })
        );
    };

    const cancelAllLoading = () => {
        setMultiLoading(false);
        setSingleLoading();
        setSelectedCampaigns([]);
    };

    const handleActivateCampaigns = (campaignsToActivate) => {
        axios
            .put(`/api/v1/campaigns/activate`, {
                ids: campaignsToActivate,
            })
            .then(({ data }) => {
                updateStatusVisuals(campaignsToActivate, "active");
                cancelAllLoading();

                addToast({
                    type: "success",
                    content: "Campaigns successfully activated",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                cancelAllLoading();
                addToast({
                    type: "alert",
                    content: "There was an error activating your campaigns.",
                    showFor: 5000,
                });
            });
    };

    const handleDisableCampaigns = (campaignsToDisable) => {
        axios
            .put(`/api/v1/campaigns/disable`, {
                ids: campaignsToDisable,
            })
            .then(({ data }) => {
                updateStatusVisuals(campaignsToDisable, "draft");
                cancelAllLoading();

                addToast({
                    type: "success",
                    content: "Campaigns successfully disabled",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                cancelAllLoading();

                addToast({
                    type: "alert",
                    content: "There was an error disabling your campaigns.",
                    showFor: 5000,
                });
            });
    };

    const handleDeleteCampaigns = (campaignsToDelete) => {
        axios
            .put(`/api/v1/campaigns/delete`, {
                ids: campaignsToDelete,
            })
            .then(({ data }) => {
                updateStatusVisuals(campaignsToDelete, "deleted");
                cancelAllLoading();

                addToast({
                    type: "success",
                    content: "Campaigns successfully deleted",
                    showFor: 5000,
                });
            })
            .catch((e) => {
                cancelAllLoading();

                addToast({
                    type: "alert",
                    content: "There was an error deleting your campaigns.",
                    showFor: 5000,
                });
            });
    };

    return (
        <>
            <PageTitle title="Campaigns" expanded>
                <Link to="/campaigns/add">
                    <Button>Add Campaign</Button>
                </Link>
            </PageTitle>
            <Row collapse expanded>
                <Column collapse>
                    <Row expanded>
                        <Column>
                            <SearchSection>
                                <Button onClick={() => handleSelectAll()}>
                                    Select All
                                </Button>
                                <InputField
                                    name="search"
                                    placeholder="Search"
                                    style={{
                                        maxWidth: "400px",
                                        margin: "0",
                                    }}
                                    value={globalSearchValue}
                                    onChange={handleGlobalSearch}
                                />
                                <Button>Search</Button>
                            </SearchSection>
                        </Column>
                    </Row>
                    <Panel style={{ paddingTop: "0" }}>
                        <StyledTable
                            selectable={true}
                            className="dashboard-table"
                        >
                            <thead>
                                <tr>
                                    <StyledTableHeader name="id">
                                        ID
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "title"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "title"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="title"
                                    >
                                        Title
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "status"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "status"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={[
                                            {
                                                label: "Live",
                                                value: "active",
                                            },
                                            {
                                                label: "Draft",
                                                value: "draft",
                                            },
                                            {
                                                label: "Expired",
                                                value: "expired",
                                            },
                                            {
                                                label: "Deleted",
                                                value: "deleted",
                                            },
                                        ]}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="status"
                                    >
                                        Status
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "type"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "type"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={[
                                            {
                                                label: "Points Archive",
                                                value: "points-archive",
                                            },
                                            {
                                                label: "Customer",
                                                value: "customer",
                                            },
                                            {
                                                label: "Parts Distributor",
                                                value: "parts-distributor",
                                            },
                                        ]}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="type"
                                    >
                                        Type
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "date_from"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "date_from"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        name="date_from"
                                    >
                                        From
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "date_to"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "date_to"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        name="date_to"
                                    >
                                        To
                                    </StyledTableHeader>
                                    <StyledTableHeader />
                                </tr>
                            </thead>
                            {!loading && (
                                <tbody>
                                    {campaigns.map((campaign) => (
                                        <StyledTableRow
                                            selected={selectedCampaigns.includes(
                                                campaign.id
                                            )}
                                            onClick={() =>
                                                handleClickCampaign(campaign.id)
                                            }
                                            key={campaign.id}
                                        >
                                            <td>{campaign.id}</td>
                                            <td>{campaign.title}</td>
                                            <td>{campaign.status}</td>
                                            <td>{campaign.type}</td>
                                            <td>
                                                {new Date(
                                                    campaign.date_from
                                                ).toDateString()}
                                            </td>
                                            <td>
                                                {new Date(
                                                    campaign.date_to
                                                ).toDateString()}
                                            </td>
                                            <td>
                                                <div
                                                    style={
                                                        singleLoading
                                                            ? {
                                                                  minWidth:
                                                                      "125px",
                                                                  display:
                                                                      "flex",
                                                                  alignItems:
                                                                      "center",
                                                              }
                                                            : {
                                                                  minWidth:
                                                                      "75px",
                                                              }
                                                    }
                                                >
                                                    <ButtonDropdown
                                                        onClick={() => {
                                                            history.push(
                                                                `/campaigns/${campaign.id}`
                                                            );
                                                        }}
                                                        name="primary"
                                                        actions={[
                                                            ...(campaign.status !==
                                                            "active"
                                                                ? [
                                                                      {
                                                                          name: "Activate",
                                                                          onClick:
                                                                              () => {
                                                                                  setSingleLoading(
                                                                                      campaign.id
                                                                                  );
                                                                                  handleActivateCampaigns(
                                                                                      [
                                                                                          campaign.id,
                                                                                      ]
                                                                                  );
                                                                              },
                                                                      },
                                                                  ]
                                                                : []),
                                                            ...(campaign.status !==
                                                            "draft"
                                                                ? [
                                                                      {
                                                                          name: "Disable",
                                                                          onClick:
                                                                              () => {
                                                                                  setSingleLoading(
                                                                                      campaign.id
                                                                                  );
                                                                                  handleDisableCampaigns(
                                                                                      [
                                                                                          campaign.id,
                                                                                      ]
                                                                                  );
                                                                              },
                                                                      },
                                                                  ]
                                                                : []),
                                                            ...(campaign.status !==
                                                            "deleted"
                                                                ? [
                                                                      {
                                                                          name: "Delete",
                                                                          onClick:
                                                                              () => {
                                                                                  setSingleLoading(
                                                                                      campaign.id
                                                                                  );
                                                                                  handleDeleteCampaigns(
                                                                                      [
                                                                                          campaign.id,
                                                                                      ]
                                                                                  );
                                                                              },
                                                                      },
                                                                  ]
                                                                : []),
                                                        ]}
                                                    >
                                                        View
                                                    </ButtonDropdown>
                                                    {singleLoading ===
                                                        campaign.id && (
                                                        <span
                                                            style={{
                                                                marginLeft:
                                                                    "2em",
                                                            }}
                                                        >
                                                            <CubeLoader
                                                                size={20}
                                                                margin="0"
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                        </StyledTableRow>
                                    ))}
                                </tbody>
                            )}
                        </StyledTable>

                        {loading && <CubeLoader />}

                        {!campaigns.length && !loading ? (
                            <p
                                style={{
                                    textAlign: "center",
                                    margin: "3em 0",
                                }}
                            >
                                There are no campaigns
                            </p>
                        ) : (
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{ margin: "0 0 0 1em" }}
                                        value={perPageValue}
                                        options={[
                                            { value: 10, text: "10" },
                                            { value: 20, text: "20" },
                                            { value: 30, text: "30" },
                                            { value: 40, text: "40" },
                                            { value: 50, text: "50" },
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={currentPage(selectedPageValue)}
                                    total={totalCampaigns}
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                                <TotalContainer>
                                    Total: {totalCampaigns}
                                </TotalContainer>
                            </PaginationContainer>
                        )}
                    </Panel>
                </Column>
            </Row>
            {!!selectedCampaigns.length && (
                <SelectedCampaigns>
                    <span>
                        <b>Selected {selectedCampaigns.length} Campaign(s)</b>
                    </span>
                    <span style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "1em" }}>
                            <ButtonDropdown
                                onClick={() => setSelectedCampaigns([])}
                                actions={[]}
                                name="primary"
                            >
                                Cancel
                            </ButtonDropdown>
                        </span>
                        <span>
                            <ButtonDropdown
                                onClick={() => {
                                    setMultiLoading(true);
                                    handleActivateCampaigns(selectedCampaigns);
                                }}
                                name="primary"
                                actions={[
                                    {
                                        name: "Disable",
                                        onClick: () => {
                                            setMultiLoading(true);
                                            handleDisableCampaigns(
                                                selectedCampaigns
                                            );
                                        },
                                    },
                                    {
                                        name: "Delete",
                                        onClick: () => {
                                            setMultiLoading(true);
                                            handleDeleteCampaigns(
                                                selectedCampaigns
                                            );
                                        },
                                    },
                                ]}
                            >
                                Activate
                            </ButtonDropdown>
                        </span>

                        {multiLoading && (
                            <span
                                style={{
                                    marginLeft: "2em",
                                }}
                            >
                                <CubeLoader size={20} margin="0" />
                            </span>
                        )}
                    </span>
                </SelectedCampaigns>
            )}
        </>
    );
};

export default CampaignsList;
