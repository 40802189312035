import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import {
    InputError,
    FormLabel,
    StyledSelectAdvanced,
} from "@cortexglobal/rla-components";

const FormikAdvancedSelect = ({
    type,
    name,
    label,
    disabled,
    options,
    ...rest
}) => {
    return (
        <>
            <Field name={name}>
                {({
                    field: { name, onChange },
                    form: {
                        values,
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue,
                    },
                }) => {
                    const error = !!touched[name] && errors[name];
                    return (
                        <div style={{ marginBottom: "1.2em" }}>
                            {label && (
                                <FormLabel name={name}>{label}</FormLabel>
                            )}{" "}
                            <StyledSelectAdvanced
                                name={name}
                                options={options}
                                id={name}
                                value={values[name]}
                                onChange={(selected) => {
                                    let value = "";
                                    if (Array.isArray(selected)) {
                                        value = selected.map(
                                            (selected) => selected.value
                                        );
                                    } else if (selected && selected.value) {
                                        value = selected.value;
                                    }

                                    setFieldValue(name, value);
                                    setFieldTouched(name, true);
                                }}
                                onBlur={() => {
                                    setFieldTouched(name, true);
                                }}
                                error={error}
                                disabled={disabled}
                                {...rest}
                            />
                        </div>
                    );
                }}
            </Field>
            <ErrorMessage name={name}>
                {(msg) => <InputError error={msg} />}
            </ErrorMessage>
        </>
    );
};

FormikAdvancedSelect.displayName = "FormikAdvancedSelect";

FormikAdvancedSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
        })
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.string,
};

FormikAdvancedSelect.defaultProps = {
    label: "",
};

export default FormikAdvancedSelect;
