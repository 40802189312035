import React, { Component } from "react";
import { Helmet } from "react-helmet-async";

class HeadContent extends Component {
    render() {
        return (
            <Helmet>
                <title>
                    {process.env.REACT_APP_ENV === "local" ? "L: " : ""}Lens
                    Data Centre 2.0
                </title>
                <meta name="description" content="PSA Data Centre 2.0" />
            </Helmet>
        );
    }
}

export default HeadContent;
