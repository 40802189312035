import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewCustomer from "./ViewCustomer";

const Customers = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/:id`} component={ViewCustomer} />
            </Switch>
        </>
    );
};

export default Customers;
