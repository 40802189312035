import React, {
    useEffect,
    useState,
} from "react";
import {
    Link,
    useHistory
} from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    FormikInput,
    FormikSelect,
    FormikDatePicker,
    FormikCheckbox,
    FormikTextarea,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";

import { CubeLoader } from "@cortexglobal/lens-components";
import ContactForm from "./ContactForm";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const EditContact = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState();
    const history = useHistory();
    const addToast = useAddToast();

    const getContact = () => {
        setLoading(true);

        axios
            .get(`/api/v1/contacts/${match.params.id}`)
            .then(({ data }) => {
                setContactData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting contact.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const handleSubmit = (value) => {
        setLoading(true);

        console.log(value)
        axios
            .put(`/api/v1/contacts/${match.params.id}`, {
                first_name: value.first_name,
                last_name: value.last_name,
                job_title: value.job_title,
                email: value.email,
                phone: value.phone,
                mobile_phone: value.mobile_phone,
                primary_contact: value.primary_contact ? "Yes" : "No",
                legitimate_interest: value.legitimate_interest ? "Yes" : "No",
                business_email: value.business_email ? "Yes" : "No",
                ar_perks_permission: value.ar_perks_permission ? "Yes" : "No",
                can_nominate_ar: value.can_nominate_ar ? "Yes" : "No",
                channel_email: value.channel_email === null ? null : (value.channel_email ? "Yes" : "No"),
                channel_sms: value.channel_sms === null ? null : (value.channel_sms ? "Yes" : "No"),
                channel_post: value.channel_email === null ? null : (value.channel_email ? "Yes" : "No"),
                trade_team_id: value.trade_team_id,
            })
            .then(({ data }) => {
                console.log(data);
                history.push(`/contacts/${data.data.id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error updating your contact.",
                    showFor: 5000
                });
            });
    };

    useEffect(() => {
        getContact();
    }, []);

    return (
        <>
                    <PageTitle title={contactData ? `Editing: ${contactData.first_name} ${contactData.last_name}` : `Editing`} expanded>
                        <Link to={`/contacts/${match.params.id}`}>
                            <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to Contact</Button>
                        </Link>
                    </PageTitle>

            {loading && <CubeLoader />}

            {!loading && contactData && (
                    <ContactContainer>
                        <Row>
                            <Column>
                                <ContactForm handleSubmit={handleSubmit} loading={loading} values={contactData}/>
                            </Column>
                        </Row>
                    </ContactContainer>
            )}
        </>
    );
}

export default EditContact;