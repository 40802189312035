import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { PageTitle, Button, useAddToast } from "@cortexglobal/rla-components";

import CampaignBuilder from "./CampaignBuilder";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingPage from "../components/LoadingPage";

const EditCampaign = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [campaignData, setCampaignData] = useState();
    const addToast = useAddToast();

    const getCampaign = () => {
        setLoading(true);

        axios
            .get(`/api/v1/campaigns/${match.params.id}`)
            .then(({ data }) => {
                setCampaignData(data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting campaign.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getCampaign();
    }, []);

    return (
        <>
            <PageTitle title="Edit Campaign" expanded>
                <Link to={`/campaigns/${match.params.id}`}>
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Campaign
                    </Button>
                </Link>
            </PageTitle>

            {loading ? (
                <LoadingPage title="Loading campaign details" />
            ) : (
                <CampaignBuilder
                    loading={loading}
                    setLoading={setLoading}
                    values={campaignData}
                    setValues={setCampaignData}
                />
            )}
        </>
    );
};

export default EditCampaign;
