import React, { createElement, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { Error } from "@cortexglobal/rla-components";
import LoadingPage from "../LoadingPage";

// auth
import { AuthContext } from "@cortexglobal/lens-auth";
import { setupAuth } from "@cortexglobal/lens-auth";

const PrivateRoute = (props) => {
    const { component } = props;

    const [auth, setAuth] = useState({
        loadingState: "loading",
        loggedIn: false,
        user: {},
    });

    useEffect(() => {
        setupAuth().then((authDetails) => {
            setAuth(authDetails);
        });
    }, []);

    const setLoadingState = state => {
        const states = [
            'loading',
            'loaded',
            'error'
        ];

        if(!states.includes(state)){
            throw new Error("Auth loading state not recognised");
        }

        setAuth({...auth, loadingState: state});
    }

    const render = (loadingState, loggedIn) => {
        switch(true){
            case loadingState === "loading":
                return <LoadingPage />

            case loadingState === "loaded" && loggedIn:
                return <Route {...props} render={() => createElement(component, props)} />

            case loadingState === "loaded" && !loggedIn:
                return <Redirect to={{
                        pathname: "/auth/login",
                        state: { from: props.location },
                    }} />

            default: 
                return <Error />
        }
    }

    return (
        <AuthContext.Provider value={{...auth, setLoadingState}}>
            {render(auth.loadingState, auth.loggedIn)}
        </AuthContext.Provider>
    );
};

export default PrivateRoute;
