import React, {
    useState,
    useRef,
    useReducer,
    useEffect,
    useCallback,
} from "react";
import axios from "axios";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    SelectField,
    useAddToast,
} from "@cortexglobal/rla-components";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    currentPage,
    CubeLoader,
} from "@cortexglobal/lens-components";

import queryString from "querystring";
import { debounce } from "lodash";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const StyledTitleSpan = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0.1rem;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
`;

const CompanyList = () => {
    const history = useHistory();
    const addToast = useAddToast();

    const {
        orderBy,
        orderDir,
        globalSearch: search,
        perPage,
        page,
        searchWithoutLinks,
    } = queryString.parse(useLocation().search.substring(1));

    let { searchFilter } = queryString.parse(useLocation().search.substring(1));

    if (typeof searchFilter === "string") {
        try {
            searchFilter = JSON.parse(searchFilter);
        } catch (err) {
            console.log(err);
        }
    }

    const [hasLink, setHasLink] = useState(searchWithoutLinks);
    const [searchFilterValue, setSearchFilterValue] = useState(
        searchFilter ? searchFilter : {}
    );
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);
    const [customerTypes, setCustomerTypes] = useState([]);

    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [typesLoading, setTypesLoading] = useState(false);

    const [totalCompanies, setTotalCompanies] = useState();
    const [totalPages, setTotalPages] = useState(0);

    const getCustomerTypes = () => {
        setTypesLoading(true);

        axios
            .get(`/api/v1/customers/types`)
            .then(({ data }) => {
                setCustomerTypes(data.data);
                setTypesLoading(false);
            })
            .catch((e) => {
                setTypesLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error getting customer types.",
                    showFor: 5000,
                });
            });
    };

    const getCompanies = (newData) => {
        setLoading(true);
        axios
            .get(`/api/v1/customers`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_filter: searchFilterValue,
                    global_search_val: globalSearchValue,
                    search_without_links: hasLink,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setCompanies(data.data);
                setTotalCompanies(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting companies.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getCustomerTypes();
        handleSearch();
    }, [hasLink]);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_filter: searchFilterValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            search_without_links: hasLink,
            ...newData,
        };

        let queryString = `?page=${data.page}&perPage=${data.per_page}`;

        if (data.order_by) {
            queryString += `&orderBy=${data.order_by}`;
        }

        if (data.order_dir) {
            queryString += `&orderDir=${data.order_dir}`;
        }

        if (data.search_val) {
            queryString += `&search=${data.search_val}`;
        }

        if (data.search_by) {
            queryString += `&searchBy=${data.search_by}`;
        }

        if (data.search_filter) {
            const searchFilter =
                typeof data.search_filter !== "string"
                    ? JSON.stringify(data.search_filter)
                    : data.search_filter;

            queryString += `&searchFilter=${searchFilter}`;
        }

        if (data.global_search_val) {
            queryString += `&globalSearch=${data.global_search_val}`;
        }

        if (data.search_without_links) {
            queryString += `&searchWithoutLinks=${data.search_without_links}`;
        }

        history.push({ search: encodeURIComponent(queryString) });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSearch = (value, searchByValue) => {
        setGlobalSearchValue("");

        let searchFilterJson = searchFilterValue;

        try {
            if (
                typeof searchFilterJson !== "undefined" &&
                typeof searchFilterJson !== "object"
            ) {
                searchFilterJson = JSON.parse(searchFilterJson);
            }
        } catch (err) {
            console.log(err);
        }

        // Add or remove search value to field
        if (value !== "" && value !== undefined) {
            // Escape the search value
            searchFilterJson[searchByValue] = value;
        } else {
            delete searchFilterJson[searchByValue];
        }

        // Save the string for the parameter and update the state
        let searchFilterString = JSON.stringify(searchFilterJson);

        setSearchFilterValue(searchFilterString);

        let searchWithoutLinks = {};

        if (hasLink) {
            searchWithoutLinks = {
                search_without_links: true,
            };
        }

        const params = {
            search_filter: searchFilterString, // Encoding searchFilter
            page: 1,
            ...searchWithoutLinks,
        };

        debounceSearch(() => {
            setParams(params);
            getCompanies(params);
        });
    };

    const handleGlobalSearch = ({ value }) => {
        setGlobalSearchValue(value);

        let searchWithoutLinks = {};

        if (hasLink) {
            searchWithoutLinks = {
                search_without_links: true,
            };
        }

        const params = {
            ...{
                global_search_val: value,
                page: 1,
            },
            ...searchWithoutLinks,
        };

        debounceSearch(() => {
            setParams(params);
            getCompanies(params);
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getCompanies({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getCompanies({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getCompanies({
            page: selected + 1,
        });
    };

    return (
        <>
            <PageTitle title="Customers" expanded>
                <Link to="/companies/create">
                    <Button>Add New Customer</Button>
                </Link>
            </PageTitle>
            <Row collapse expanded>
                <Column collapse>
                    <Row expanded>
                        <Column>
                            <SearchSection>
                                <StyledTitleSpan>
                                    Search Only Those Without Links
                                </StyledTitleSpan>
                                <InputField
                                    type="checkbox"
                                    name={"search_without_links"}
                                    style={{
                                        maxWidth: "400px",
                                        margin: "0",
                                    }}
                                    checked={!!hasLink}
                                    onChange={() => {
                                        setHasLink(!hasLink);
                                    }}
                                />
                                <InputField
                                    name="search"
                                    placeholder="Search"
                                    style={{
                                        maxWidth: "400px",
                                        margin: "0",
                                    }}
                                    value={globalSearchValue}
                                    onChange={handleGlobalSearch}
                                />
                                <Button>Search</Button>
                            </SearchSection>
                        </Column>
                    </Row>
                    <Panel style={{ paddingTop: "0" }}>
                        <StyledTable className="dashboard-table">
                            <thead style={{ position: "sticky", top: "0" }}>
                                <tr>
                                    <StyledTableHeader
                                        isSortable={false}
                                        name="id"
                                    >
                                        ID
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "trade_team_id"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchFilterValue.trade_team_id
                                        }
                                        style={{
                                            color: "black",
                                        }}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="trade_team_id"
                                    >
                                        Distrigo Number
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue ===
                                            "trade_team_customer"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        style={{
                                            color: "black",
                                        }}
                                        searchValue={
                                            searchFilterValue.trade_team_customer
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="trade_team_customer"
                                    >
                                        Customer
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue ===
                                            "alternative_customer_name"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        style={{
                                            color: "black",
                                        }}
                                        searchValue={
                                            searchFilterValue.alternative_customer_name
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="alternative_customer_name"
                                    >
                                        Alternative Customer Name
                                    </StyledTableHeader>
                                    {!typesLoading && (
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={
                                                orderByValue ===
                                                "trade_team_customer_type"
                                                    ? orderDirectionValue
                                                    : undefined
                                            }
                                            style={{
                                                color: "black",
                                            }}
                                            searchValue={
                                                searchFilterValue.trade_team_customer_type
                                            }
                                            searchOptions={customerTypes}
                                            onSortChange={handleSortChange}
                                            onSearchChange={handleSearch}
                                            hasSearch={true}
                                            name="trade_team_customer_type"
                                        >
                                            Customer Type
                                        </StyledTableHeader>
                                    )}

                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "address_1"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        style={{
                                            color: "black",
                                        }}
                                        searchValue={
                                            searchFilterValue.address_1
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="address_1"
                                    >
                                        Address
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "postcode"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        style={{
                                            color: "black",
                                        }}
                                        searchValue={searchFilterValue.postcode}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="postcode"
                                    >
                                        Postcode
                                    </StyledTableHeader>
                                    <StyledTableHeader />
                                </tr>
                            </thead>

                            {!loading && (
                                <tbody>
                                    {companies.map((customer) => (
                                        <StyledTableRow
                                            key={customer.trade_team_id}
                                        >
                                            <td>
                                                <Link
                                                    to={`/companies/${customer.customer_id}`}
                                                >
                                                    {customer.customer_id}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/companies/${customer.customer_id}`}
                                                >
                                                    {customer.trade_team_id}
                                                </Link>
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/companies/${customer.customer_id}`}
                                                >
                                                    {
                                                        customer.trade_team_customer
                                                    }
                                                </Link>
                                            </td>
                                            <td>
                                                {
                                                    customer.alternative_customer_name
                                                }
                                            </td>
                                            <td>
                                                {
                                                    customer.trade_team_customer_type
                                                }
                                            </td>
                                            <td>{customer.address_1}</td>
                                            <td>{customer.postcode}</td>
                                            <td>
                                                <Link
                                                    to={`/companies/${customer.customer_id}`}
                                                >
                                                    <Button>View</Button>
                                                </Link>
                                            </td>
                                        </StyledTableRow>
                                    ))}
                                </tbody>
                            )}
                        </StyledTable>

                        {loading && <CubeLoader />}

                        {!companies.length && !loading ? (
                            <p style={{ textAlign: "center", margin: "3em 0" }}>
                                No results
                            </p>
                        ) : (
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{ margin: "0 0 0 1em" }}
                                        value={perPageValue}
                                        options={[
                                            { value: 10, text: "10" },
                                            { value: 20, text: "20" },
                                            { value: 30, text: "30" },
                                            { value: 40, text: "40" },
                                            { value: 50, text: "50" },
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={currentPage(selectedPageValue)}
                                    total={totalCompanies}
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                                <TotalContainer>
                                    Total: {totalCompanies}
                                </TotalContainer>
                            </PaginationContainer>
                        )}
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default CompanyList;
