import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    useAddToast,
} from "@cortexglobal/rla-components";

import { CubeLoader } from "@cortexglobal/lens-components";

import { Title, Container } from "../../components/generalLayoutComponents";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ViewAdjustment = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [customerSummary, setCustomerSummary] = useState();

    const [fetchingSchema, setFetchingSchema] = useState(false);
    const [schema, setSchema] = useState([]);

    const [adjustment, setAdjustment] = useState();
    const addToast = useAddToast();

    const getSchema = (id) => {
        setFetchingSchema(true);

        axios
            .get(`/api/v1/points/${id}`)
            .then(({ data }) => {
                setFetchingSchema(false);
                setSchema(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting schema.",
                    showFor: 5000,
                });
                setFetchingSchema(false);
            });
    };

    const handleFetchCustomerSummary = (id) => {
        setFetchingSummary(true);

        axios
            .get(`/api/v1/customers/${id}/summary`)
            .then(({ data }) => {
                setFetchingSummary(false);
                setCustomerSummary(data.data);
            })
            .catch((error) => {
                console.log(error);
                addToast({
                    type: "alert",
                    content: "There was an error getting the customer summary.",
                    showFor: 5000,
                });
                setCustomerSummary(undefined);
                setFetchingSummary(false);
            });
    };

    const getAdjustment = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/points-adjustments/${match.params.id}`)
            .then(({ data }) => {
                setLoading(false);
                handleFetchCustomerSummary(data.data.trade_team_id);
                if (data.data.scheme_id) {
                    getSchema(data.data.scheme_id);
                }
                setAdjustment(data.data);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting the adjustment.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getAdjustment();
    }, []);

    const CustomerSummary = () => {
        return (
            <Row>
                <Column>
                    <Container>
                        <Title>Customer Summary:</Title>
                        <p>
                            <b>Distrigo Number: </b>
                            {customerSummary.trade_team_id}
                        </p>
                        <p>
                            <b>Customer: </b>
                            {customerSummary.trade_team_customer}
                        </p>
                        <p>
                            <b>Customer ID: </b>
                            <Link
                                to={`/companies/${customerSummary.customer_id}`}
                            >
                                {customerSummary.customer_id}
                            </Link>
                        </p>
                        <p>
                            <b>Customer Type: </b>
                            {customerSummary.trade_team_customer_type}
                        </p>
                        <p>
                            <b>Customer Address: </b>
                            {customerSummary.address_1}, &nbsp;
                            {customerSummary.address_2}, {customerSummary.town}
                            {customerSummary.county} &nbsp;
                            {customerSummary.postcode}
                        </p>
                    </Container>
                </Column>
            </Row>
        );
    };

    if (loading || fetchingSummary || fetchingSchema) {
        return <CubeLoader />;
    }

    return (
        <>
            {adjustment && (
                <>
                    <PageTitle title={adjustment.title} expanded>
                        <Link to="/rewards/adjustments">
                            <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to Points Adjustments</Button>
                        </Link>
                    </PageTitle>

                    <Row expanded>
                        <Column>
                            <Panel>
                                {customerSummary && <CustomerSummary />}
                                <Row stlye={{ marginBottom: "2em" }}>
                                    <Column>
                                        <Container>
                                            <Title>Adjustment Details:</Title>
                                            <p>
                                                <b>Points Adjustment: </b>
                                                {adjustment.points.toLocaleString()}
                                            </p>

                                            <p>
                                                <b>Point Balance Before: </b>
                                                {adjustment.previous_points.toLocaleString()}
                                            </p>
                                            <p>
                                                <b>Point Balance After: </b>
                                                {
                                                    parseInt(adjustment.previous_points) +
                                                    parseInt(adjustment.points)
                                                }
                                            </p>
                                            <p>
                                                <b>When Applicable: </b>
                                                {new Date(
                                                    adjustment.date
                                                ).toDateString()}
                                            </p>
                                        </Container>
                                    </Column>
                                </Row>

                                <Row stlye={{ marginBottom: "2em" }}>
                                    <Column>
                                        <Container>
                                            <Title>Information:</Title>
                                            <p>
                                                <b>Display Title:</b>{" "}
                                                {adjustment.title}
                                            </p>
                                            <p>
                                                <b>Relevant Schema: </b>
                                                points {schema.title}
                                            </p>
                                        </Container>
                                    </Column>
                                </Row>
                            </Panel>
                        </Column>
                    </Row>
                </>
            )}
        </>
    );
};

export default ViewAdjustment;
