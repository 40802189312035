import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import ReportListing from "./ReportListing";
import "react-toggle/style.css"
import Toggle from 'react-toggle'

import {
    Row,
    Column,
    PageTitle,
    Button, DatePicker, useAddToast
} from "@cortexglobal/rla-components";

import { faFile } from "@fortawesome/free-regular-svg-icons/faFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReportingContainer = styled.div`
    padding: 1em 0;
`;

const reports = [
    {
        name: 'All Activated Customers',
        packet: {
            type: 'all_activated_customers_report'
        }
    },
    {
        name: 'All Customers in Tier 2',
        packet: {
            type: 'all_customers_in_tier_two_report'
        }
    },
    {
        name: 'Communication Unsubscribes Report',
        packet: {
            type: 'communication_unsubscribes_report'
        }
    },
    {
        name: 'Communications Statistics',
        packet: {
            type: 'communications_statistics_report'
        }
    },
    {
        name: 'Competition Entries',
        packet: {
            type: 'competition_entries_report'
        }
    },
    {
        name: 'Customer Explorer Activation Emails',
        packet: {
            type: 'customer_explorer_activation_emails_report'
        }
    },
    {
        name: 'Customer Explorer Searches',
        packet: {
            type: 'customer_explorer_searches_report'
        }
    },
    {
        name: 'Customer Explorer Views',
        packet: {
            type: 'customer_explorer_views_report'
        }
    },
    {
        name: 'Customer User Accounts With The Activation Status Of The Customer',
        packet: {
            type: 'customer_user_accounts_with_activation_status_report'
        }
    },
    {
        name: 'Dealer - Geographic Data',
        packet: {
            type: 'dealers_geographic_data_report'
        }
    },
    {
        name: 'Duplicate Customer Email Report',
        packet: {
            type: 'duplicate_customer_emails_report'
        }
    },
    {
        name: 'PD Activations',
        packet: {
            type: 'p_d_activation_report'
        }
    },
    {
        name: 'Rewards Orders',
        packet: {
            type: 'reward_orders_report'
        }
    },
    {
        name: 'Trade Team Customers - Activation Codes',
        packet: {
            type: 'trade_team_customers_activation_codes_report'
        }
    },
    {
        name: 'Trade Team Customers - Geographic Data',
        packet: {
            type: 'trade_team_customers_geographic_data_report'
        }
    },
    {
        name: 'Travel Information Between A Dealer And A Trade Team Customer',
        packet: {
            type: 'travel_info_report'
        }
    },
    {
        name: 'User Accounts Set Up For Trade Team Customers',
        packet: {
            type: 'user_accounts_set_up_for_trade_team_customers_report'
        }
    },
    {
        name: 'User With Permission To Send Them Emails',
        packet: {
            type: 'users_with_permission_to_email_report'
        }
    }
];

const Report = styled.div`
    padding: 1em 0;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
`;

const ToggleContainer = styled.div`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: right;
`;

const Reporting = (props) => {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [noDate, setNoDate] = useState(true);
    const addToast = useAddToast();
    const [loading, setLoading] = useState(false);

    const handleReporting = (reportType) => {
        setLoading(true);

        axios
            .post(`/api/v1/reporting`, {
                date_from: startDate,
                date_to: endDate,
                no_date: noDate,
                type: reportType
            })
            .then(({ data }) => {
                window.location = "/user-reports"
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error processing your report.",
                    showFor: 500
                });
            });
    };

    return (
        <>
            <PageTitle title="Reporting">
            </PageTitle>
            <ReportingContainer>
                <Row>
                    <Column>
                        <p>Select the start and end date for your report (or leave the date fields blank to see all data), then select your report from the options below. The report will download in your browser as a Microsoft Excel CSV file.</p>
                        {!noDate &&
                            <div>
                                <DatePicker
                                    label="From"
                                    id="date-from-datepicker"
                                    name='date-from'
                                    value={startDate.toString()}
                                    onChange={({value}) => setStartDate(value)}
                                />
                                <DatePicker
                                    label="To"
                                    id="date-to-datepicker"
                                    name='date-to'
                                    value={endDate.toString()}
                                    onChange={({value}) => setEndDate(value)}
                                />
                            </div>

                        }

                        <ToggleContainer>
                            <Toggle
                                id='no-date'
                                defaultChecked={noDate}
                                className='custom-classname'
                                aria-labelledby='no-date-label'
                                onChange={(event) => {
                                    setNoDate(event.target.checked)
                                }}

                            />

                            <span
                                style={{fontWeight: 'bold', marginLeft: '1rem'}}
                                id='no-date'>Disable Date / Enable Date<
                            /span>
                        </ToggleContainer>



                        {reports.map((report, index) => (
                            <Report key={index}>
                                <span>
                                <FontAwesomeIcon
                                    style={{
                                        fontSize: '1.5em',
                                        marginRight: '1em'
                                    }}
                                    icon={faFile}
                                />
                                {report.name}
                                </span>
                                <Button
                                    onClick={() => {
                                    handleReporting(report.packet.type);
                                }}>
                                    Download
                                </Button>
                            </Report>
                        ))}
                    </Column>
                </Row>
            </ReportingContainer>

        </>
    );
};

export default Reporting;


