import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast,
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import {
    StyledTable,
    StyledTableRow,
    CubeLoader,
    TrueFalseIcon,
} from "@cortexglobal/lens-components";
import ContactForm from "./ContactForm";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const CreateContact = ({ match }) => {
    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState();

    const handleSubmit = (value) => {
        setLoading(true);



        console.log(value);
        axios
            .post(`/api/v1/contacts`, {
                first_name: value.first_name,
                last_name: value.last_name,
                job_title: value.job_title,
                email: value.email,
                phone: value.phone,
                mobile_phone: value.mobile_phone,
                primary_contact: value.primary_contact ? "Yes" : "No",
                legitimate_interest: value.legitimate_interest ? "Yes" : "No",
                business_email: value.business_email ? "Yes" : "No",
                ar_perks_permission: value.ar_perks_permission ? "Yes" : "No",
                can_nominate_ar: value.can_nominate_ar ? "Yes" : "No",
                channel_email: (value.channel_email === undefined) ? null : (value.channel_email ? "Yes" : "No"),
                channel_sms: (value.channel_email === undefined) ? null : (value.channel_email ? "Yes" : "No"),
                channel_post: (value.channel_email === undefined) ? null : (value.channel_email ? "Yes" : "No"),
                trade_team_id: value.trade_team_id,
            })
            .then(({ data }) => {
                history.push(`/contacts/${data.data.id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error creating your contact.",
                    showFor: 5000,
                });
            });
    };

    return (
        <>
            <PageTitle title="Create New Contact" expanded>
                <Link to="/contacts">
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Contacts
                    </Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && (
                <ContactContainer>
                    <Row>
                        <Column>
                            <ContactForm
                                handleSubmit={handleSubmit}
                                loading={loading}
                            />
                        </Column>
                    </Row>
                </ContactContainer>
            )}
        </>
    );
};

export default CreateContact;
