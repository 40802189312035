import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Pagination,
    SelectField,
} from "@cortexglobal/rla-components";
import axios from "axios";
import queryString from "querystring";
import { Link, useLocation } from "react-router-dom";

import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    currentPage,
    CubeLoader
} from "@cortexglobal/lens-components";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanySales = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    handleSortChange,
    orderByValue,
    orderDirectionValue,
    sales,
    meta,
}) => {
    const [lineData, setLineData] = useState();

    return (
        <>
            {!lineData && (
                <>
                    <StyledTable className="sales-table">
                        <thead>
                            <tr>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={
                                        orderByValue === "invoice_date"
                                            ? orderDirectionValue
                                            : undefined
                                    }
                                    onSortChange={handleSortChange}
                                    name="invoice_date"
                                >
                                    Invoice Date
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={
                                        orderByValue === "dealer_name"
                                            ? orderDirectionValue
                                            : undefined
                                    }
                                    onSortChange={handleSortChange}
                                    name="dealer_name"
                                >
                                    Dealer Name
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={
                                        orderByValue === "invoice_number"
                                            ? orderDirectionValue
                                            : undefined
                                    }
                                    onSortChange={handleSortChange}
                                    name="invoice_number"
                                >
                                    Invoice Number
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={
                                        orderByValue === "total_price"
                                            ? orderDirectionValue
                                            : undefined
                                    }
                                    onSortChange={handleSortChange}
                                    name="total_price"
                                >
                                    Total Price
                                </StyledTableHeader>
                                <StyledTableHeader
                                    isSortable={true}
                                    sortDirection={
                                        orderByValue === "total_quantity"
                                            ? orderDirectionValue
                                            : undefined
                                    }
                                    onSortChange={handleSortChange}
                                    name="total_quantity"
                                >
                                    Number Of Lines
                                </StyledTableHeader>
                                <StyledTableHeader />
                            </tr>
                        </thead>
                        <tbody>
                            {sales.map(({ invoice }) => (
                                <StyledTableRow key={invoice.invoice_number}>
                                    <td>
                                        {new Date(
                                            invoice.invoice_date
                                        ).toDateString()}
                                    </td>
                                    <td>{invoice.dealer_name}</td>
                                    <td>
                                        <Link
                                            to={`#`}
                                            onClick={() => {
                                                setLineData(
                                                    invoice.invoice_line
                                                );
                                            }}
                                        >
                                            {invoice.invoice_number}
                                        </Link>
                                    </td>
                                    <td>{invoice.total_price}</td>
                                    <td>{invoice.total_lines}</td>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setLineData(
                                                    invoice.invoice_line
                                                );
                                            }}
                                        >
                                            View
                                        </Button>
                                    </td>
                                </StyledTableRow>
                            ))}
                        </tbody>
                    </StyledTable>
                    <PaginationContainer>
                        <PerPageContainer>
                            Per Page:
                            <SelectField
                                name="perPage"
                                style={{ margin: "0 0 0 1em" }}
                                value={perPageValue}
                                options={[
                                    { value: 10, text: "10" },
                                    { value: 20, text: "20" },
                                    { value: 30, text: "30" },
                                    { value: 40, text: "40" },
                                    { value: 50, text: "50" },
                                ]}
                                onChange={handlePerPage}
                            />
                        </PerPageContainer>
                        <Pagination
                            currentPage={currentPage(selectedPageValue)}
                            total={meta.total}
                            pageCount={meta.last_page}
                            onPageChange={handlePageChange}
                            previousLabel="&laquo;"
                            nextLabel="&raquo;"
                        />
                        <TotalContainer>Total: {meta.total}</TotalContainer>
                    </PaginationContainer>
                </>
            )}
            {lineData && (
                <>
                    <div style={{ textAlign: "right", paddingRight: "1rem" }}>
                        <Button onClick={() => setLineData()}>Back</Button>
                    </div>
                    <StyledTable className="lines-table">
                        <thead>
                            <tr>
                                <StyledTableHeader name="dealer_name">
                                    Dealer Name
                                </StyledTableHeader>
                                <StyledTableHeader name="account_number">
                                    Account Number
                                </StyledTableHeader>
                                <StyledTableHeader name="category">
                                    Category
                                </StyledTableHeader>
                                <StyledTableHeader name="sub_category">
                                    Subcategory
                                </StyledTableHeader>
                                <StyledTableHeader name="invoice_date">
                                    Invoice Date
                                </StyledTableHeader>
                                <StyledTableHeader name="total_price">
                                    Total Price
                                </StyledTableHeader>
                                <StyledTableHeader name="total_quantity">
                                    Total Quantity
                                </StyledTableHeader>
                                <StyledTableHeader name="part_number">
                                    Part Number
                                </StyledTableHeader>
                                <StyledTableHeader />
                            </tr>
                        </thead>
                        <tbody>
                            {lineData.map((line) => (
                                <StyledTableRow key={line.invoice_number}>
                                    <td>{line.dealer}</td>
                                    <td>{line.account_number}</td>
                                    <td>{line.category_id}</td>
                                    <td>{line.sub_category_id}</td>
                                    <td>
                                        {new Date(
                                            line.invoice_date
                                        ).toDateString()}
                                    </td>
                                    <td>{line.total_price}</td>
                                    <td>{line.total_quantity}</td>
                                    <td>{line.part_number}</td>
                                </StyledTableRow>
                            ))}
                        </tbody>
                    </StyledTable>
                </>
            )}
        </>
    );
};

export default CompanySales;
