import React from "react";
import styled from "styled-components";
import { Route, Switch, withRouter } from "react-router-dom";

import { SiteWrapper, MainContentWrapper } from "@cortexglobal/rla-components";

import Header from "./PrivateHeader";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import Home from "../../modules/home/Home";

import {
    PageNotFound,
    Uploads,
    Admin,
    Imports,
    RewardOrders,
    SnapOnVoucherDispatch,
    UserDataCentreReports,
    StyledTable,
    ErrorLogs,
    Users,
    Validation,
} from "@cortexglobal/lens-components";

import Campaigns from "../../campaigns/Campaigns";
import Rewards from "../../rewards/Rewards";
import CustomerMapping from "../../customer-mapping/CustomerMapping";
import FaapJCustomerMapping from "../../customer-mapping/FaapJCustomerMapping";
import Companies from "../../companies/Companies";
import Customers from "../../companies/Customers";
import Contacts from "../../contacts/Contacts";
import Dealers from "../../dealers/Dealers";
import Reporting from "../../reporting/Reporting";
import UserReports from "../../reporting/UserReports";

const StyledSiteWrapper = styled(SiteWrapper)`
    flex-direction: row;
`;

const SiteMain = styled.div`
    flex-grow: 1;
    flex-direction: column;
    display: flex;
`;

const PrivateApp = (props) => {
    return (
        <StyledSiteWrapper>
            <Sidebar />
            <SiteMain>
                <Header />
                <MainContentWrapper style={{ position: "relative" }}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/uploads" component={Uploads} />
                        <Route path="/campaigns" component={Campaigns} />
                        <Route path="/customers" component={Customers} />
                        <Route path="/companies" component={Companies} />
                        <Route path="/contacts" component={Contacts} />
                        <Route path="/dealers" component={Dealers} />
                        <Route path="/reporting" component={Reporting} />
                        <Route path="/user-reports" component={UserReports} />
                        <Route path="/validation" component={Validation} />
                        <Route
                            exact
                            path="/user-reports"
                            component={UserDataCentreReports}
                        />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/imports" component={Imports} />
                        <Route
                            exact
                            path="/speeder-customer-mapping"
                            component={CustomerMapping}
                        />
                        <Route
                            exact
                            path="/faap-j-customer-mapping"
                            component={FaapJCustomerMapping}
                        />
                        <Route path="/admin" component={Admin} />
                        <Route path="/rewards" component={Rewards} />
                        <Route path="*" component={PageNotFound} />
                    </Switch>
                </MainContentWrapper>
                <Footer />
            </SiteMain>
        </StyledSiteWrapper>
    );
};

export default withRouter(PrivateApp);
