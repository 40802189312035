import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";

import RewardOrdersList from "./RewardOrdersList";
import ViewRewardOrder from "./ViewRewardOrder"

const RewardOrders = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/:id`} component={ViewRewardOrder}/>
                <Route path={`${match.path}`} component={RewardOrdersList}/>
            </Switch>
        </>
    );
};

export default RewardOrders;
