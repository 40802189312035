import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Button,
    Column,
    FormikCheckbox,
    FormikDatePicker,
    FormikInput,
    FormikSelect,
    FormikTextarea,
    Panel,
    Row,
    SubmitButton,
    DraftJSField,
    FormLabel,
    StyledDropzone,
    StyledInput,
    useAddToast,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    convertFromHTML,
    ContentState,
    EditorState,
    convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const CampaignContainer = styled.div`
    padding: 1rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const steps = ["Details", "Promotion Box", "Campaign Information", "Customers"];

const StepTitle = styled.h3`
    font-weight: normal;
    margin-bottom: 1em;
`;

const Schema = Yup.object().shape({
    title: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    promotion_from: Yup.date().required("Required"),
    promotion_to: Yup.date().required("Required"),
    banner_title: Yup.string().required("Required"),
    show_in_banner: Yup.boolean(),
    banner_short_description: Yup.string().required("Required"),
    summary: Yup.string().required("Required"),
    terms_and_conditions: Yup.string().required("Required"),
    how_it_works: Yup.string().required("Required"),
    campaign_information_image: Yup.string().required("Required"),
    show_in_carousel: Yup.boolean(),
    dashboard_image: Yup.string(),
});

const CampaignBuilder = ({ loading, setLoading, values, setValues }) => {
    const history = useHistory();

    const [saving, setSaving] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [customersFile, setCustomersFile] = useState(null);

    const setCustomersFiles = (files) => {
        const file = files[0];
        setCustomersFile(file);
    };

    const addToast = useAddToast();

    const moveOn = (id) => {
        history.push(`/campaigns/${id}`);
    };

    const dateInstance = new Date();

    const firstDayOfNextMonth = new Date(
        dateInstance.getFullYear(),
        dateInstance.getMonth() + 1,
        1
    );
    const lastDayOfNextMonth = new Date(
        dateInstance.getFullYear(),
        dateInstance.getMonth() + 2,
        0
    );

    const HTMLToDraftJS = (value) => {
        const blocksFromHTML = convertFromHTML(value);
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
        );
        const editorState = EditorState.createWithContent(state);
        return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    };

    const handleUploadCustomers = () => {
        setSaving(true);

        const { id } = values;

        const fd = new FormData();
        fd.append("file", customersFile);

        axios
            .post(`/api/v1/campaigns/${id}/upload-customers`, fd)
            .then(({ data }) => {
                const { id } = data;
                history.push(`/campaigns/${id}`);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error creating your campaign. " +
                        e.message,
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSaving(false);
            });
    };

    // add
    const handleSubmit = (values) => {
        setSaving(true);

        const { id } = values;
        const type = !!id ? "updating" : "creating";

        const attributes = {
            title: values.title,
            type: values.type,
            slug: values.slug,
            terms_and_conditions: draftToHtml(
                JSON.parse(values.terms_and_conditions)
            ),
            summary: draftToHtml(JSON.parse(values.summary)),
            date_from: values.promotion_from,
            date_to: values.promotion_to,
            how_it_works: draftToHtml(JSON.parse(values.how_it_works)),
            show_in_carousel: values.show_in_carousel,
            banner_title: values.banner_title,
            banner_short_description: values.banner_short_description,
            show_in_banner: values.show_in_banner,
            dashboard_image: values.dashboard_image,
        };

        const json = JSON.stringify(attributes);
        const formData = new FormData();
        formData.append("carousel_image", values.carousel_image);
        formData.append("campaign_information_image", values.campaign_information_image);
        formData.append("document", json);

        (type === "updating"
            ? axios.post(`/api/v1/campaigns/${id}`, formData)
            : axios.post(`/api/v1/campaigns`, formData)
        )
            .then(({ data }) => {
                const campaignData = data.id !== undefined ? data : data.data;
                setValues({ ...campaignData });
                setCurrentStep(4);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        e.message +
                        `: there was an error ${type} your campaign.`,
                    showFor: 5000,
                });
            })
            .finally(() => {
                setSaving(false);
            });
    };

    return (
        <CampaignContainer>
            <StepTitle>
                <strong>Step {currentStep}:</strong> {steps[currentStep - 1]}
            </StepTitle>
            <Panel style={{ padding: "2em" }}>
                <Formik
                    initialValues={{
                        id: values && values.id ? values.id : null,
                        title: values && values.title ? values.title : "",
                        type: values && values.type ? values.type : "",
                        promotion_from:
                            values && values.promotion_from
                                ? values.promotion_from
                                : firstDayOfNextMonth.toDateString(),
                        promotion_to:
                            values && values.promotion_to
                                ? values.promotion_to
                                : lastDayOfNextMonth.toDateString(),
                        banner_title:
                            values && values.banner_title
                                ? values.banner_title
                                : "",
                        banner_short_description:
                            values && values.banner_short_description
                                ? values.banner_short_description
                                : "",
                        show_in_banner:
                            values && values.show_in_banner
                                ? values.show_in_banner
                                : false,
                        dashboard_image:
                            values && values.dashboard_image
                                ? values.dashboard_image
                                : "",
                        carousel_image:
                            values && values.carousel_image
                                ? values.carousel_image
                                : "",
                        campaign_information_image:
                            values && values.campaign_information_image
                                ? values.campaign_information_image
                                : "",
                        show_in_carousel:
                            values && values.show_in_carousel
                                ? values.show_in_carousel
                                : false,
                        summary:
                            values && values.summary
                                ? HTMLToDraftJS(values.summary)
                                : "",
                        how_it_works:
                            values && values.how_it_works
                                ? HTMLToDraftJS(values.how_it_works)
                                : "",
                        terms_and_conditions:
                            values && values.terms_and_conditions
                                ? HTMLToDraftJS(values.terms_and_conditions)
                                : "",
                        customers:
                            values && values.customers ? values.customers : [],
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={Schema}
                >
                    {({
                        isSubmitting,
                        values,
                        setFieldValue,
                        handleBlur,
                        errors,
                    }) => {
                        const firstStepComplete =
                            !!values.title &&
                            !!values.type &&
                            !!values.promotion_from &&
                            !!values.promotion_to;

                        const secondStepComplete =
                            !!values.banner_title &&
                            !!values.banner_short_description;

                        const thirdStepComplete =
                            !!values.summary &&
                            !!values.terms_and_conditions &&
                            !!values.how_it_works;

                        return (
                            <Form>
                                {(currentStep === 1 || showAll === true) && (
                                    <>
                                        <Row>
                                            <Column>
                                                <FormikInput
                                                    value={values.title}
                                                    width="100%"
                                                    labelWidth={100}
                                                    name="title"
                                                    label="Title"
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column>
                                                <FormikSelect
                                                    value={values.type}
                                                    label="Type"
                                                    name="type"
                                                    options={[
                                                        {
                                                            value: "customer",
                                                            text: "Customer",
                                                        },
                                                        {
                                                            value: "parts-distributor",
                                                            text: "Parts Distributor",
                                                        },
                                                    ]}
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column>
                                                <FormikDatePicker
                                                    value={
                                                        values.promotion_from
                                                    }
                                                    name="promotion_from"
                                                    label="Promotion From"
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column>
                                                <FormikDatePicker
                                                    value={values.promotion_to}
                                                    name="promotion_to"
                                                    label="Promotion To"
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Button
                                                    disabled={
                                                        !firstStepComplete
                                                    }
                                                    onClick={() =>
                                                        setCurrentStep(2)
                                                    }
                                                >
                                                    Next Step
                                                </Button>
                                            </Column>
                                        </Row>
                                    </>
                                )}

                                {(currentStep === 2 || showAll === true) && (
                                    <>
                                        <Row>
                                            <Column>
                                                <FormikInput
                                                    width="100%"
                                                    labelWidth={100}
                                                    name="banner_title"
                                                    label="Banner Title"
                                                    value={values.banner_title}
                                                />
                                            </Column>
                                            <Column>
                                                <FormikCheckbox
                                                    type="text"
                                                    name="show_in_banner"
                                                    label="Show on Customer Dashboard"
                                                    value={
                                                        !!values.show_in_banner
                                                    }
                                                    checked={
                                                        !!values.show_in_banner
                                                    }
                                                />
                                            </Column>
                                            <Column>
                                                <FormikTextarea
                                                    width="100%"
                                                    labelWidth={100}
                                                    name="banner_short_description"
                                                    label="Short Description"
                                                    value={
                                                        values.banner_short_description
                                                    }
                                                />
                                            </Column>

                                            <Column
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        marginRight: "1em",
                                                    }}
                                                    onClick={() =>
                                                        setCurrentStep(1)
                                                    }
                                                >
                                                    Previous Step
                                                </Button>
                                                <Button
                                                    disabled={
                                                        !secondStepComplete
                                                    }
                                                    onClick={() =>
                                                        setCurrentStep(3)
                                                    }
                                                >
                                                    Next Step
                                                </Button>
                                            </Column>
                                        </Row>
                                    </>
                                )}

                                {(currentStep === 3 || showAll === true) && (
                                    <Row>
                                        <Column>
                                            <FormLabel name="summary">
                                                Campaign Summary
                                            </FormLabel>
                                            <DraftJSField
                                                name="summary"
                                                value={values.summary}
                                                // onChange={() => {}}
                                                onChange={(input) => {
                                                    const { name, value } =
                                                        input;
                                                    setFieldValue(name, value);
                                                }}
                                                toolbar={{
                                                    options: [
                                                        "inline",
                                                        "blockType",
                                                        "fontSize",
                                                        "list",
                                                        "textAlign",
                                                        "link",
                                                        "embedded",
                                                    ],
                                                }}
                                            />
                                        </Column>
                                        <Column>
                                            <FormLabel name="terms_and_conditions">
                                                Terms and Conditions
                                            </FormLabel>
                                            <DraftJSField
                                                name="terms_and_conditions"
                                                value={
                                                    values.terms_and_conditions
                                                }
                                                onChange={(input) => {
                                                    const { name, value } =
                                                        input;
                                                    setFieldValue(name, value);
                                                }}
                                                toolbar={{
                                                    options: [
                                                        "inline",
                                                        "blockType",
                                                        "fontSize",
                                                        "list",
                                                        "textAlign",
                                                        "link",
                                                        "embedded",
                                                    ],
                                                }}
                                            />
                                        </Column>
                                        <Column>
                                            <FormLabel name="how_it_works">
                                                How it Works
                                            </FormLabel>
                                            <DraftJSField
                                                name="how_it_works"
                                                value={values.how_it_works}
                                                onChange={(input) => {
                                                    const { name, value } =
                                                        input;
                                                    setFieldValue(name, value);
                                                }}
                                                toolbar={{
                                                    options: [
                                                        "inline",
                                                        "blockType",
                                                        "fontSize",
                                                        "list",
                                                        "textAlign",
                                                        "link",
                                                        "embedded",
                                                    ],
                                                }}
                                            />
                                        </Column>
                                        <Column>
                                            <p name="summary">
                                                <br />
                                                <strong>
                                                    Upload A Carousel Image
                                                </strong>
                                            </p>

                                            <StyledDropzone
                                                name="carousel_image"
                                                onDrop={(files) => {
                                                    setFieldValue("carousel_image", files[0]);
                                                }}
                                            >
                                                Click or drop a file here to upload an image
                                            </StyledDropzone>
                                            {(values.carousel_image.size) && (
                                                <img src={URL.createObjectURL(values.carousel_image)} alt={values.carousel_image.name} className="img-thumbnail mt-2" height={200} width={200} />
                                            )}
                                            {(typeof values.carousel_image === "string" && values.carousel_image.startsWith("http")) && (
                                                <img src={(values.carousel_image)} alt={values.carousel_image.name} className="img-thumbnail mt-2" height={200} width={200} />
                                            )}
                                        </Column>

                                        <Column>
                                            <p name="summary">
                                                <br />
                                                <strong>
                                                    Upload A Campaign Information Image
                                                </strong>
                                            </p>

                                            <StyledDropzone
                                                name="campaign_information_image"
                                                onDrop={(files) => {
                                                    setFieldValue("campaign_information_image", files[0]);
                                                }}
                                            >
                                                Click or drop a file here to upload an image
                                            </StyledDropzone>
                                            {(values.campaign_information_image.size) && (
                                                <img src={URL.createObjectURL(values.campaign_information_image)} alt={values.campaign_information_image.name} className="img-thumbnail mt-2" height={200} width={200} />
                                            )}
                                            {(typeof values.campaign_information_image === "string" && values.campaign_information_image.startsWith("http")) && (
                                                <img src={(values.campaign_information_image)} alt={values.campaign_information_image.name} className="img-thumbnail mt-2" height={200} width={200} />
                                            )}
                                        </Column>

                                        <Column>
                                            <FormikCheckbox
                                                type="text"
                                                name="show_in_carousel"
                                                label="Show in Carousel"
                                                value={
                                                    !!values.show_in_carousel
                                                }
                                                checked={
                                                    !!values.show_in_carousel
                                                }
                                            />
                                        </Column>
                                        <Column>
                                            <FormikInput
                                                width="100%"
                                                labelWidth={100}
                                                name="dashboard_image"
                                                label="Dashboard Image URL"
                                                value={values.dashboard_image}
                                            />
                                        </Column>

                                        <Column
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    marginRight: "1em",
                                                }}
                                                onClick={() =>
                                                    setCurrentStep(2)
                                                }
                                            >
                                                Previous Step
                                            </Button>
                                            <SubmitButton
                                                submitting={saving}
                                                disabled={!thirdStepComplete}
                                            >
                                                Next Step
                                            </SubmitButton>
                                        </Column>
                                    </Row>
                                )}

                                {(currentStep === 4 || showAll === true) && (
                                    <>
                                        {values.id !== undefined && (
                                            <Row>
                                                <Column>
                                                    <p name="summary">
                                                        <br />
                                                        <strong>
                                                            Upload a new
                                                            customer list
                                                        </strong>
                                                    </p>

                                                    <StyledDropzone
                                                        name="customers_file"
                                                        onDrop={(files) => {
                                                            setCustomersFiles(
                                                                files
                                                            );
                                                        }}
                                                    >
                                                        {customersFile &&
                                                        customersFile.name
                                                            ? customersFile.name
                                                            : "Click or drop a file here to upload a customer list"}
                                                    </StyledDropzone>
                                                    <p>
                                                        Note: this must be a
                                                        spreadsheet (.xlsx or
                                                        .csv file) with a the
                                                        column named
                                                        'trade_team_id'.
                                                    </p>
                                                </Column>
                                            </Row>
                                        )}

                                        <Row>
                                            <Column
                                                style={{
                                                    textAlign: "right",
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        marginRight: "1em",
                                                    }}
                                                    onClick={() =>
                                                        setCurrentStep(3)
                                                    }
                                                >
                                                    Previous Step
                                                </Button>
                                                <Button
                                                    style={{
                                                        marginRight: "1em",
                                                    }}
                                                    onClick={
                                                        !!customersFile
                                                            ? handleUploadCustomers
                                                            : () =>
                                                                  moveOn(
                                                                      values.id
                                                                  )
                                                    }
                                                >
                                                    Finish
                                                </Button>
                                            </Column>
                                        </Row>
                                    </>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </Panel>
            {(currentStep === 4 || showAll === true) &&
                Array.isArray(values.customers) &&
                values.customers.length > 0 && (
                    <Panel style={{ padding: "2em" }}>
                        <Row>
                            <Column>
                                <p name="summary">
                                    <strong>Existing customer list</strong>
                                </p>

                                {values.customers.map((c) => {
                                    const details = [
                                        c.trade_team_id,
                                        c.trade_team_customer,
                                        c.dealer_name,
                                    ];
                                    return (
                                        <>
                                            {details.join(", ")}
                                            <br />
                                        </>
                                    );
                                })}
                            </Column>
                        </Row>
                    </Panel>
                )}
        </CampaignContainer>
    );
};

export default CampaignBuilder;
