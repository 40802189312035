import styled from "styled-components";

export const Title = styled.h3`
    font-size: 1.1em;
    font-weight: bold;
    margin-bottom: 1em;
    border-bottom: 1px solid #e0e0e0;
    display: inline-block;
    padding-right: 2em;
    padding-bottom: 0.5em;
`;

export const Container = styled.div`
    border: 1px solid #b3b3b3;
    padding: 1.8em 2em 1em 2em;
    border-radius: 5px;
    margin-bottom: 2em;
`;
