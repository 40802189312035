import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Column, PageTitle, Button } from "@cortexglobal/rla-components";
import CampaignBuilder from "./CampaignBuilder";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddCampaign = (props) => {
    const [loading, setLoading] = useState(false);
    const [campaignData, setCampaignData] = useState({});

    return (
        <>
            <PageTitle title="Add New Campaign" expanded>
                <Link to="/campaigns">
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Campaigns
                    </Button>
                </Link>
            </PageTitle>

            <CampaignBuilder
                loading={loading}
                setLoading={setLoading}
                values={campaignData}
                setValues={setCampaignData}
            />
        </>
    );
};

export default AddCampaign;
