import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewContact from "./ViewContact";
import ContactsList from "./ContactsList";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";
import CreateContact from "./CreateContact";
import EditContact from "./EditContact";

const Contacts = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/create`} component={CreateContact}/>
                <Route path={`${match.path}/edit/:id`} component={EditContact}/>
                <Route path={`${match.path}/:id`} component={ViewContact}/>
                <Route path={`${match.path}`} component={ContactsList}/>
            </Switch>
        </>
    );
};

export default Contacts;
