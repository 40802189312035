import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

// Sentry error reporting imports
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { merge, isEmpty } from "lodash";
import App from "./App";
import { ThemeProvider, ToastProvider } from "@cortexglobal/rla-components";
import theme from "./config/theme";

require("./config/icons");
require("./config/axiosConfig");

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (!isEmpty(sentryDsn)) {
    const sentrySamplerRate =
        process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 1;

    Sentry.init({
        dsn: sentryDsn,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: sentrySamplerRate,
    });
}

const renderApp = () => {
    return (
        <HelmetProvider>
            <ThemeProvider theme={merge(theme)}>
                <ToastProvider>
                    <Router>
                        <App />
                    </Router>
                </ToastProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
};

ReactDOM.render(renderApp(), document.getElementById("root"));
