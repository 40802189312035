import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
    FormikInput,
    SelectField,
    useAddToast,
    SubmitButton,
    Button,
    FormLabel,
    MultiCheckbox,
} from "@cortexglobal/rla-components";
import {
    StyledTable,
    StyledTableRow,
    CubeLoader,
} from "@cortexglobal/lens-components";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { animated, useSpring } from "react-spring";

const Schema = Yup.object().shape({
    dealer_code: Yup.string().required("Required"),
    dealer_name: Yup.string().required("Required"),
    trade_team_customer: Yup.string().nullable(),
    trade_team_customer_type: Yup.string().nullable(),
});

const CreateNewCustomer = ({
    initialValues,
    successCallback,
    cancelCallback,
    customerTypes, // this should be in a customer types select
}) => {
    const [loading, setLoading] = useState(false);
    const addToast = useAddToast();
    const [customerTypesExpanded, setCustomerTypesExpanded] = useState(false);
    const expandableContainerRef = useRef(null);
    const [style, animate] = useSpring(() => ({ height: "0px" }), []);
    const [phoneMatchError, setPhoneMatchError] = useState(false);

    useEffect(() => {
        animate({
            height:
                (customerTypesExpanded
                    ? expandableContainerRef.current.offsetHeight + 2
                    : 300) + "px",
        });
    }, [animate, expandableContainerRef, customerTypesExpanded]);

    const csvStringToArray = (csvString) => {
        if (!csvString) {
            csvString = "";
        }

        let csvArray = csvString.split(",").map(function (item) {
            return item.trim();
        });

        return csvArray;
    };

    const handleSubmit = (values, { setErrors, setSubmitting, ...rest }) => {
        setSubmitting(true);
        setLoading(true);

        axios
            .post("/api/v1/customers", values)
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: "Customer successfully created.",
                    showFor: 5000,
                });
                successCallback(data.data);
            })
            .catch((e) => {
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                });
            })
            .finally(() => {
                setSubmitting(false);
                setLoading(false);
            });
    };

    const handlePhoneBlur = async (event, trade_team_id) => {
        const phone = event.target.value;
        const data = { phone };

        if (trade_team_id !== null && trade_team_id !== 0) {
            data.trade_team_id = trade_team_id;
        }
        try {
            const response = await axios.post(
                "/api/v1/customers/phone-number-check",
                data
            );
            const found = response.data.data.found;
            setPhoneMatchError(found);
        } catch (error) {
            console.error("Error checking phone number:", error);
            setPhoneMatchError(false);
        }
    };

    return (
        <Formik
            initialValues={{ ...initialValues, trade_team_customer_type: null }}
            onSubmit={handleSubmit}
            validationScheme={Schema}
        >
            {({ values, errors, setFieldValue }) => {
                return (
                    <Form>
                        <FormikInput
                            label="Company Number"
                            name="company_number"
                            value={values.company_number}
                        />

                        <FormikInput
                            label="Dealer Code"
                            name="dealer_code"
                            value={values.dealer_code}
                            error={errors.dealer_code}
                        />

                        <FormikInput
                            label="Dealer Name"
                            name="dealer_name"
                            value={values.dealer_name}
                        />

                        <FormikInput
                            label="Customer Name"
                            name="trade_team_customer"
                            value={values.trade_team_customer}
                        />

                        <FormikInput
                            value={values.alternative_customer_name}
                            name="alternative_customer_name"
                            label="Alternative Customer Name"
                        />

                        <FormLabel>Distrigo Customer Type</FormLabel>

                        <div style={{ position: "relative" }}>
                            <Button
                                style={{
                                    position: "absolute",
                                    top: "1rem",
                                    right: "1.4rem",
                                    padding: "0 0.6rem",
                                    zIndex: 1,
                                }}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setCustomerTypesExpanded(
                                        !customerTypesExpanded
                                    );
                                }}
                                height={26}
                            >
                                {customerTypesExpanded ? (
                                    <>
                                        Collapse{" "}
                                        <FontAwesomeIcon icon={faMinus} />
                                    </>
                                ) : (
                                    <>
                                        Expand <FontAwesomeIcon icon={faPlus} />
                                    </>
                                )}
                            </Button>

                            <animated.div
                                style={{
                                    minHeight: "calc(15px + 2.4rem)",
                                    margin: "0 auto 1rem",
                                    border: "1px solid #aaafb7",
                                    overflow: "auto",
                                    width: "100%",
                                    ...style,
                                }}
                            >
                                <div
                                    style={{
                                        padding: "1.2rem 1.2rem 1px",
                                    }}
                                    ref={expandableContainerRef}
                                >
                                    {!customerTypes.length && (
                                        <CubeLoader size={20} margin="0.5rem" />
                                    )}

                                    <MultiCheckbox
                                        name="trade_team_customer_type"
                                        value={csvStringToArray(
                                            values.trade_team_customer_type
                                        )}
                                        options={customerTypes}
                                        onChange={({ value }) => {
                                            setFieldValue(
                                                "trade_team_customer_type",
                                                value.join(", ")
                                            );
                                        }}
                                    />
                                </div>
                            </animated.div>
                        </div>

                        <FormikInput
                            label="Address 1"
                            name="address_1"
                            value={values.address_1}
                        />

                        <FormikInput
                            label="Address 2"
                            name="address_2"
                            value={values.address_2}
                        />

                        <FormikInput
                            label="Address 3"
                            name="address_3"
                            value={values.address_3}
                        />

                        <FormikInput
                            label="City/Town"
                            name="town"
                            value={values.town}
                        />

                        <FormikInput
                            label="County"
                            name="county"
                            value={values.county}
                        />

                        <FormikInput
                            label="Postcode"
                            name="postcode"
                            value={values.postcode}
                        />

                        {phoneMatchError && (
                            <span style={{ margin: "1rem" }}>
                                Phone Number Already Exists
                            </span>
                        )}
                        <FormikInput
                            type="text"
                            name="phone"
                            label="Phone Number"
                            value={values.phone}
                            onBlur={(event) =>
                                handlePhoneBlur(event, values.trade_team_id)
                            }
                            style={{
                                backgroundColor: phoneMatchError
                                    ? "#D9544D"
                                    : "",
                                color: phoneMatchError ? "white" : "",
                            }}
                        />

                        <FormikInput
                            label="Mobile Number"
                            name="mobile"
                            value={values.mobile}
                        />

                        <FormikInput
                            label="Email"
                            name="email"
                            value={values.email}
                        />

                        <div style={{ textAlign: "right" }}>
                            <Button
                                onClick={() => cancelCallback()}
                                style={{ marginRight: "1rem" }}
                                disabled={loading}
                            >
                                Cancel
                            </Button>

                            <SubmitButton
                                submitting={loading}
                                disabled={loading}
                            >
                                Create
                            </SubmitButton>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CreateNewCustomer;
