import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    Modal,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast, SubmitButton
} from "@cortexglobal/rla-components";
import { useHistory } from "react-router";
import {
    StyledTable,
    StyledTableRow,
    CubeLoader,
    TrueFalseIcon
} from "@cortexglobal/lens-components";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {TabordionGroup} from "../components/TabordionGroup";
import {Container} from "../components/generalLayoutComponents";

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div`
`;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const BoldTd = styled.td`
    font-weight: bold;
    width: 300px;
`;

const DealerTable = styled.table`
    border-spacing: 0;
    
    tr:nth-child(odd) {
      background: #f7f7f7;
    }
    
    td {
        padding: 1rem;
    }
`;

const ViewDealer = ({ match }) => {

    const history = useHistory();
    const addToast = useAddToast();

    const [loading, setLoading] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [dealerStatusType, setDealerStatusType] = useState('Delete');

    const [dealerData, setContactData] = useState();


    const [tab, setTab] = useState(0);

    const restoreDelete = () => {
        axios
            .put(`/api/v1/dealers/restore/${match.params.id}`)
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: "Dealer restored successfully.",
                    showFor: 5000
                });
                history.push("/dealers");
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error restoring the dealer.",
                    showFor: 5000
                });
            });
    }

    const deleteDealer = () => {
        axios
            .delete(`/api/v1/dealers/${match.params.id}`)
            .then(({ data }) => {
                addToast({
                    type: "success",
                    content: "Dealer deleted successfully.",
                    showFor: 5000
                });
                history.push("/dealers");
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error deleting the dealer.",
                    showFor: 5000
                });
            });
    }
    const getDealer = () => {
        setLoading(true);

        axios
            .get(`/api/v1/dealers/${match.params.id}`)
            .then(({ data }) => {
                setContactData(data.data);
                setLoading(false);
                if (data.data.deleted_at) {
                    setDealerStatusType('Restore');
                }
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting the dealer.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getDealer();
    }, [match]);

    return (
        <>
            <PageTitle title={dealerData ? `${dealerData.dealer_name} - ${dealerData.dealer_code}` : `Loading Dealer`} expanded>
                <Link to="/dealers">
                    <Button style={{ marginRight: "1em" }}><FontAwesomeIcon icon={ faCaretLeft } /> Back to dealers</Button>
                </Link>

                <Link to={`/dealers/edit/${match.params.id}`}>
                    <Button style={{ height: "100%" }}>Edit Dealer</Button>
                </Link>

                <Button
                    onClick={() => setShowWarningModal(true)}
                    style={{ height: "100%",  marginLeft: "1em" }}>{dealerStatusType} Dealer</Button>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && dealerData && (
            <Row expanded>
                <Column>
                    <StyledPanel>
                        <PanelHeader>
                        </PanelHeader>
                        <Tabordion current={tab} unmounts={false}>
                            <TabordionGroup
                                heading={
                                    "Details"
                                }
                                onTabClick={() => setTab(0)}
                            >

                                <PanelBody>
                                    <DealerTable style={{ width: '100%' }}>
                                        <tbody>
                                        <tr>
                                            <BoldTd>Dealer Name:</BoldTd>
                                            <td>
                                                {dealerData.dealer_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Dealer Code:</BoldTd>
                                            <td>
                                                {dealerData.dealer_code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Parts Zone:</BoldTd>
                                            <td>
                                                {dealerData.parts_zone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Group View:</BoldTd>
                                            <td>
                                                {dealerData.group_view}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Brand:</BoldTd>
                                            <td>
                                                {dealerData.brand}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Type:</BoldTd>
                                            <td>
                                                {dealerData.type}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>RRDI Code:</BoldTd>
                                            <td>
                                                {dealerData.rrdi_code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Address 1:</BoldTd>
                                            <td>
                                                {dealerData.address_1}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Address 2:</BoldTd>
                                            <td>
                                                {dealerData.address_2}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Town:</BoldTd>
                                            <td>
                                                {dealerData.town}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>County:</BoldTd>
                                            <td>
                                                {dealerData.county}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Postcode:</BoldTd>
                                            <td>
                                                {dealerData.postcode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Parent Retailer:</BoldTd>
                                            <td>
                                            {dealerData.parent_dealer ?
                                                <Link to={`/dealers/${dealerData.parent_dealer.id}`}>
                                                    <Button>View {dealerData.parent_dealer.dealer_name} - {dealerData.parent_dealer.dealer_code}</Button>
                                                </Link>
                                                : "None"
                                            }
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Hub Retailer:</BoldTd>
                                            <td>
                                                {dealerData.hub_id ?
                                                    <Link to={`/dealers/${dealerData.hub_id}`}>
                                                        <Button>View {dealerData.hub_id}</Button>
                                                    </Link>
                                                    : "None"
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Email:</BoldTd>
                                            <td>
                                                {dealerData.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Parts Manager Email:</BoldTd>
                                            <td>
                                                {dealerData.parts_manager_email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Phone:</BoldTd>
                                            <td>
                                                {dealerData.phone_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Parts Phone Number:</BoldTd>
                                            <td>
                                                {dealerData.parts_phone_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Fax Number:</BoldTd>
                                            <td>
                                                {dealerData.fax_number}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Website:</BoldTd>
                                            <td>
                                                {dealerData.website}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Latitude:</BoldTd>
                                            <td>
                                                {dealerData.latitude}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Group Name:</BoldTd>
                                            <td>
                                                {dealerData.group_name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Group Code:</BoldTd>
                                            <td>
                                                {dealerData.group_code}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Trade Team ID:</BoldTd>
                                            <td>
                                                {dealerData.trade_team_id}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Longitude:</BoldTd>
                                            <td>
                                                {dealerData.longitude}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Dealer Logo Url:</BoldTd>
                                            <td>
                                                {dealerData.dealer_logo}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Single Address:</BoldTd>
                                            <td>
                                                {dealerData.single_address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Single Opening Hours:</BoldTd>
                                            <td>
                                               {dealerData.single_opening_hours}
                                            </td>
                                        </tr>



                                        </tbody>
                                    </DealerTable>
                                </PanelBody>

                            </TabordionGroup>
                            {dealerData.brand === "Multi Brand" &&

                            <TabordionGroup
                                heading={
                                    "Brands"
                                }
                                onTabClick={() => setTab(0)}
                            >

                                <PanelBody>
                                    <DealerTable style={{ width: '100%' }}>

                                        <tbody>

                                        <tr>
                                            <BoldTd>Fiat:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_fiat} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Alfa Romeo:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_alfa_romeo} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Abarth:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_abarth} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Fiat Professional:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_fiat_professional} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Jeep:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_jeep} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Chrysler:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_chrysler} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <BoldTd>Dodge:</BoldTd>
                                            <td>
                                                <TrueFalseIcon status={dealerData.sub_brand_dodge} />
                                            </td>
                                        </tr>

                                        </tbody>
                                    </DealerTable>
                                </PanelBody>
                            </TabordionGroup>
                            }
                            <TabordionGroup
                                heading={
                                    "Types"
                                }
                                onTabClick={() => setTab(0)}
                            >

                                <PanelBody>
                                    <DealerTable style={{ width: '100%' }}>

                                    <tbody>

                                <tr>
                                    <BoldTd>Authorised Repairer:</BoldTd>
                                    <td>
                                        <TrueFalseIcon status={dealerData.authorised_repairer} />
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Market:</BoldTd>
                                    <td>
                                        <TrueFalseIcon status={dealerData.market} />
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Parts Distributor:</BoldTd>
                                    <td>
                                        <TrueFalseIcon status={dealerData.parts_distributor} />
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Relay:</BoldTd>
                                    <td>
                                        <TrueFalseIcon status={dealerData.relay} />
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Club Ricambi:</BoldTd>
                                    <td>
                                        <TrueFalseIcon status={dealerData.club_ricambi} />
                                    </td>
                                </tr>
                                <tr>
                                    <BoldTd>Motion:</BoldTd>
                                    <td>
                                        <TrueFalseIcon status={dealerData.motion} />
                                    </td>
                                </tr>

                                </tbody>
                                    </DealerTable>
                                </PanelBody>
                            </TabordionGroup>
                    {dealerData.parts_distributor && (

                            <TabordionGroup
                            heading={
                                "Part Brands"
                            }
                                onTabClick={() => setTab(0)}
                            >
                                <PanelBody>
                                    <DealerTable style={{ width: '100%' }}>
                                    <tbody>
                                    <tr>
                                        <BoldTd>Genuine:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.genuine} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Distrigo:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.distrigo} />
                                        </td>
                                    </tr>

                                    <tr>
                                        <BoldTd>Vauxhall:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.vauxhall} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Peugeot:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.peugeot} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Citroen:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.citroen} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>DS Automobiles:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.ds_automobiles} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Eurorepar:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.eurorepar} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Fiat:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.fiat} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Fiat Professional:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.fiat_professional} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Jeep:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.jeep} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Alfa Romeo:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.alfa_romeo} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Abarth:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.abarth} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Supplier Brands:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.supplier_brands} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>SUSTAINera:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.renew} />
                                        </td>
                                    </tr>

                                    </tbody>
                                    </DealerTable>
                                </PanelBody>
                            </TabordionGroup>
                    )}
                            <TabordionGroup
                                heading={
                                    "Comms"
                                }
                                onTabClick={() => setTab(0)}
                            >
                                <PanelBody>
                                    <DealerTable style={{ width: '100%' }}>
                                    <tbody>
                                    <tr>
                                        <BoldTd>Brand For Comms:</BoldTd>
                                        <td>
                                            {dealerData.brand_for_comms}
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Dealer Name For Comms:</BoldTd>
                                        <td>
                                            {dealerData.dealer_name_for_comms}
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Area For Comms:</BoldTd>
                                        <td>
                                            {dealerData.area_for_comms}
                                        </td>
                                    </tr>

                                    </tbody>
                                    </DealerTable>
                                </PanelBody>
                            </TabordionGroup>
                            <TabordionGroup
                                heading={
                                    "Distrigo"
                                }
                                onTabClick={() => setTab(0)}
                            >
                                <PanelBody>
                                    <DealerTable style={{ width: '100%' }}>
                                    <tbody>
                                    <tr>
                                        <BoldTd>Can Be Picked As Preferred PD:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.can_be_picked_as_preferred_pd} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <BoldTd>Shown In Centre Locator:</BoldTd>
                                        <td>
                                            <TrueFalseIcon status={dealerData.shown_in_centre_locator} />
                                        </td>
                                    </tr>


                                    </tbody>
                                    </DealerTable>
                                </PanelBody>
                            </TabordionGroup>
                        </Tabordion>

                    </StyledPanel>
                </Column>

            </Row>
            )}


            <Modal
                visible={!!showWarningModal}
                onClose={() => setShowWarningModal(null)}
                maxWidth="40%"
            >

                <Container style={{marginTop: '2rem'}}>

                    <p style={{textTransform: 'uppercase'}}>
                        Are you sure you want {dealerStatusType} this dealer?
                    </p>
                    <p>
                        Name : {dealerData?.dealer_name}
                    </p>
                    <p>
                        Dealer Code : {dealerData?.dealer_code}
                    </p>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                            onClick={() => {
                                setShowWarningModal(null)
                            }}
                        >
                            Cancel
                        </Button>


                        <SubmitButton
                            onClick={() => {
                                (dealerStatusType === 'Delete' ? deleteDealer() : restoreDelete())
                            }}
                        >
                            {dealerStatusType}
                        </SubmitButton>
                    </div>
                </Container>

            </Modal>

        </>
    );
};

export default ViewDealer;