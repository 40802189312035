import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import axios from "axios";

const ViewCustomer = (props) => {
    let match = useRouteMatch();

    axios
        .get(`/api/v1/customers/trade_team/${match.params.id}`)
        .then(({ data }) => {
            window.location.href = `/companies/${data.data.customer_id}`;
        });
};

export default ViewCustomer;
