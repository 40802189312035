import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PointsCampaigns from "./campaigns/PointsCampaigns";
import Adjustments from "./adjustments/Adjustments";
import PointsReport from "./PointsReport";
import RewardOrders from "./reward-orders/RewardOrders";
import SnapOnVoucherDispatch from "./SnapOnVoucherDispatch";

import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";

const Rewards = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/campaigns`} component={PointsCampaigns}/>
                <Route path={`${match.path}/adjustments`} component={Adjustments}/>
                <Route path={`${match.path}/reports`} component={PointsReport}/>
                <Route path={`${match.path}/orders`} component={RewardOrders}/>
                <Route path={`${match.path}/snap-on-voucher-dispatch`} component={SnapOnVoucherDispatch}/>
                <Route path={`${match.path}`} component={PointsCampaigns}/>
            </Switch>
        </>
    );
};

export default Rewards;
