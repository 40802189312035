import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Row,
    Column,
    InputField,
    SubmitButton,
    useAddToast,
    Button,
    SelectField,
} from "@cortexglobal/rla-components";
import { Title, Container } from "../components/generalLayoutComponents";
import CreateNewCustomer from "./CreateNewCustomer";

const CustomerSummary = ({ customerSummary }) => {
    return (
        <>
            <p>
                <strong>Distrigo Number: </strong>
                {customerSummary.trade_team_id ?? "-"}
            </p>
            <p>
                <strong>Company Number: </strong>
                {customerSummary.company_number ?? "-"}
            </p>
            <p>
                <strong>Customer: </strong>
                {customerSummary.trade_team_customer ?? "-"}
            </p>
            <p>
                <strong>Customer Type: </strong>
                {customerSummary.trade_team_customer_type ?? "-"}
            </p>
            {/*
            <p>
                <strong>Customer Address: </strong>
                {[
                    customerSummary.address_1 ?? "",
                    customerSummary.address_2 ?? "",
                    customerSummary.town ?? "",
                    customerSummary.postcode ?? "",
                ]
                    .filter((n) => n)
                    .join(", ")}
            </p>
            */}
            <p>
                <strong>Address 1: </strong>
                {customerSummary.address_1}
            </p>
            <p>
                <strong>Address 2: </strong>
                {customerSummary.address_2}
            </p>
            <p>
                <strong>City/Town: </strong>
                {customerSummary.town}
            </p>
            <p>
                <strong>Postcode: </strong>
                {customerSummary.postcode}
            </p>
            <p>
                <strong>Phone: </strong>
                {customerSummary.phone}
            </p>
            <p>
                <strong>Mobile: </strong>
                {customerSummary.mobile}
            </p>
            <p>
                <strong>Email: </strong>
                {customerSummary.email}
            </p>
        </>
    );
};
const IndividualManualMatch = ({
    customerMap,
    onClose,
    handleAcceptCustomerMapping,
    loading,
    customerTypes,
}) => {
    const [accountCode, setAccountCode] = useState();
    const [dealerNameOptions, setDealerNameOptions] = useState([]);
    const [dealerName, setDealerName] = useState(false);
    const addToast = useAddToast();

    const getMappingFilters = () => {
        axios
            .get(`/api/v1/customers-mapping-filters`)
            .then(({ data }) => {
                setDealerNameOptions(data.data.dealers_with_code);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error getting customer mapping filters.",
                    showFor: 5000,
                });
            });
    };

    useEffect(() => {
        getMappingFilters();
    }, []);

    const convertMap = (customerMap) => {
        return {
            account_code: accountCode,
            dealer_code: dealerName,
            customer_from: customerMap.mappable_entry.id,
        };
    };

    return (
        <Row>
            <Column>
                <Title>Manual Match</Title>
            </Column>

            <Column medium={6}>
                <Container>
                    <Title>This Customer</Title>
                    <CustomerSummary
                        customerSummary={customerMap.mappable_entry}
                    />
                    <div
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                        }}
                    ></div>
                </Container>
            </Column>
            <Column medium={6}>
                <Container>
                    <SelectField
                        name=""
                        label="Distributor"
                        options={dealerNameOptions}
                        onChange={({ value }) => setDealerName(value)}
                    />
                    <div
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                        }}
                    ></div>
                </Container>
            </Column>
            <Column medium={6}>
                <Container>
                    <InputField
                        value={accountCode}
                        width="100%"
                        labelWidth={100}
                        name="accountCode"
                        label="Account Code"
                        onChange={({ value }) => setAccountCode(value)}
                    />
                    <div
                        style={{
                            marginTop: "2rem",
                            textAlign: "center",
                        }}
                    ></div>
                </Container>
            </Column>
            <Column medium={6}>
                <SubmitButton
                    loading={!!loading}
                    onClick={() =>
                        handleAcceptCustomerMapping([
                            {
                                ...convertMap(customerMap),
                            },
                        ])
                    }
                    disabled={!accountCode || !!loading || !dealerName}
                >
                    Accept
                </SubmitButton>
            </Column>
        </Row>
    );
};

export default IndividualManualMatch;
