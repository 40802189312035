import React, { useState } from "react";
import axios from "axios";
import {
    Row,
    Column,
    Button,
    InputField,
    useAddToast,
} from "@cortexglobal/rla-components";
import styled from "styled-components";
import { CubeLoader } from "@cortexglobal/lens-components";

const DetailsTable = styled.table`
    border-spacing: 0;

    tr:nth-child(odd) {
        background: #f7f7f7;
    }

    td {
        padding: 1rem;
    }
`;

const BoldTd = styled.td`
    font-weight: bold;
`;

const ViewAmazonOrder = ({ rewardOrderData }) => {
    const addToast = useAddToast();
    const [amazonDetails, setAmazonDetails] = useState({
        voucher_code: "HIDDEN",
        voucher_value: "HIDDEN",
        reveals: [
            // { revealed_at: "2021-01-01 12:02", revealed_by: "user1" },
            // { revealed_at: "2021-01-02 15:03", revealed_by: "user2" },
        ],
    });
    const [revealStatus, setRevealStatus] = useState("");
    const [revealPurpose, setRevealPurpose] = useState("");

    const revealAmazonCode = (rewardOrderId) => {
        if (!revealPurpose) {
            setRevealStatus("error");
            return addToast({
                type: "alert",
                content: "Please provide a reveal purpose.",
                showFor: 5000,
            });
        }
        setRevealStatus("loading");
        axios
            .post(`/api/v1/reward-orders/${rewardOrderId}/reveal-code`, {
                reveal_purpose: revealPurpose,
            })
            .then(({ data }) => {
                setAmazonDetails(data.data);
                setRevealStatus("loaded");
                setRevealPurpose("");
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error getting amazon voucher details.",
                    showFor: 5000,
                });
                setAmazonDetails({
                    voucher_code: "HIDDEN",
                    voucher_value: "HIDDEN",
                    reveals: [],
                });
                setRevealStatus("error");
            });
    };

    if (revealStatus === "loading") {
        return (
            <Row>
                <Column
                    style={{
                        margin: "10px 0",
                        textAlign: "center",
                    }}
                >
                    <CubeLoader />
                    Loading...
                </Column>
            </Row>
        );
    }

    return (
        <>
            {revealStatus !== "loaded" && (
                <Row>
                    <Column
                        style={{
                            margin: "10px 0",
                            textAlign: "center",
                        }}
                    >
                        <InputField
                            label="Reveal Purpose:"
                            name="reveal_purpose"
                            onChange={(e) => setRevealPurpose(e.value)}
                            value={revealPurpose}
                            style={
                                revealStatus === "error"
                                    ? {
                                          borderColor: "red",
                                          color: "red",
                                      }
                                    : {}
                            }
                        />
                        <Button
                            onClick={() => revealAmazonCode(rewardOrderData.id)}
                            style={{
                                cursor: "pointer",
                            }}
                            disabled={revealStatus === "loading"}
                        >
                            Reveal Voucher Details
                        </Button>
                        <p
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            Please note: upon revealing this code your user
                            details and IP address will be recorded for audit
                            purposes.
                        </p>
                    </Column>
                </Row>
            )}

            <DetailsTable style={{ width: "100%" }}>
                <tbody>
                    <tr>
                        <BoldTd>Voucher Code:</BoldTd>
                        <td>{amazonDetails.voucher_code}</td>
                    </tr>
                    <tr>
                        <BoldTd>Voucher Value:</BoldTd>
                        <td>{amazonDetails.voucher_value}</td>
                    </tr>
                    {revealStatus === "loaded" && (
                        <tr>
                            <td colSpan="100%">
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        marginBottom: "10px",
                                    }}
                                >
                                    Reveal Audit Log:
                                </div>

                                <DetailsTable
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <tbody>
                                        {amazonDetails.reveals.length > 0 ? (
                                            amazonDetails.reveals.map(
                                                (reveal, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {reveal.created_at}
                                                        </td>
                                                        <td>{reveal.user}</td>
                                                        <td>
                                                            {reveal.purpose}
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        ) : (
                                            <tr>
                                                <td colSpan="100%">
                                                    Not revealed
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </DetailsTable>
                            </td>
                        </tr>
                    )}
                </tbody>
            </DetailsTable>
        </>
    );
};

export default ViewAmazonOrder;
