import React, { useContext } from "react";
import styled from "styled-components";
import {
    Column,
    Row,
    Panel,
    Button
} from "@cortexglobal/rla-components";
import { AuthContext } from "@cortexglobal/lens-auth";
import { Link } from "react-router-dom";

const HomeContainer = styled.div`
    padding: 3em;
`;

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const PanelBody = styled.div`
    padding: 2rem;
`;

const Home = () => {
    const { user } = useContext(AuthContext);

    return (
        <HomeContainer>
            <Row>
                <Column medium={12}>
                    <h1 style={{ marginBottom: "3rem" }}>Welcome, {user.first_name}!</h1>
                </Column>

                <Column medium={6}>
                    <StyledPanel>
                        <PanelHeader>
                            Promotions
                        </PanelHeader>
                        <PanelBody>
                            <h3>Campaigns</h3>
                            <p>Manage or create new campaigns</p>
                            <Link to={`/campaigns`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>View Campaigns</Button>
                            </Link>
                            <Link to={`/campaigns/add`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>Add New Campaign</Button>
                            </Link>

                        </PanelBody>
                        <PanelBody style={{ borderTop: "1px solid #002a5b" }}>
                            <h3>Point Campaigns</h3>
                            <p>Manage or create new points promotions</p>
                            <Link to={`/rewards/campaigns`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>View Points Promotion</Button>
                            </Link>
                            <Link to={`/rewards/campaigns/add`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>Add New Points Promotion</Button>
                            </Link>
                        </PanelBody>
                    </StyledPanel>
                </Column>

                <Column medium={6}>
                    <StyledPanel>
                        <PanelHeader>
                            Rewards
                        </PanelHeader>
                        <PanelBody>
                            <h3>Reward orders and adjustments</h3>
                            <Link to={`/rewards/orders`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>View Reward Orders</Button>
                            </Link>
                            <Link to={`/rewards/adjustments`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>View Adjustments</Button>
                            </Link>
                            <Link to={`/rewards/adjustments/add`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>Add New Adjustment</Button>
                            </Link>
                        </PanelBody>
                    </StyledPanel>

                    <StyledPanel style={{ marginTop: "1rem" }}>
                        <PanelHeader>
                            Reports
                        </PanelHeader>
                        <PanelBody>
                            <h3>View Reports</h3>
                            <Link to={`/reporting`}>
                                <Button type='secondary' style={{ marginRight: "1rem" }}>View Reports</Button>
                            </Link>
                        </PanelBody>
                    </StyledPanel>
                </Column>
            </Row>


        </HomeContainer>
    );
};

export default Home;
