import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    useAddToast,
    SelectField,
} from "@cortexglobal/rla-components";

import {
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    CubeLoader,
    ButtonDropdown,
    currentPage,
} from "@cortexglobal/lens-components";
import queryString from "querystring";
import { debounce } from "lodash";

const AdjustmentSection = styled.div`
    padding: 1rem;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
`;

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SelectedAdjustments = styled.div`
    position: absolute;
    background: white;
    bottom: -10px;
    display: flex;
    justify-content: space-between;
    padding: 2em;
    align-items: center;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    width: calc(100% - 150px);
    position: fixed;
`;

const AdjustmentsList = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const { orderBy, orderDir, search, searchBy, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [adjustments, setAdjustments] = useState([]);
    const [selectedAdjustments, setSelectedAdjustments] = useState([]);
    const [totalAdjustments, setTotalAdjustments] = useState();
    const [totalPages, setTotalPages] = useState(0);
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [searchValue, setSearchValue] = useState(search);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );

    const [loading, setLoading] = useState(false);
    const [multiLoading, setMultiLoading] = useState(false);
    const [singleLoading, setSingleLoading] = useState();

    const getAdjustments = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/points-adjustments`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setAdjustments(data.data);
                setTotalAdjustments(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting adjustments.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getAdjustments();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.search_val ? `&search=${data.search_val}` : ""
            }${data.search_val ? `&searchBy=${data.search_by}` : ""}${
                data.global_search_val
                    ? `&globalSearch=${data.global_search_val}`
                    : ""
            }`,
        });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch(() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
            getAdjustments({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
        });
    };

    const handleGlobalSearch = ({ value }) => {
        setGlobalSearchValue(value);
        setSearchValue("");
        setSearchByValue("");

        debounceSearch(() => {
            setParams({
                global_search_val: value,
                page: 1,
            });
            getAdjustments({
                global_search_val: value,
                page: 1,
            });
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getAdjustments({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getAdjustments({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getAdjustments({
            page: selected + 1,
        });
    };

    const handleClickAdjustment = (id) => {
        if (selectedAdjustments.includes(id)) {
            setSelectedAdjustments([
                ...selectedAdjustments.filter((customer) => customer !== id),
            ]);
        } else {
            setSelectedAdjustments([...selectedAdjustments, id]);
        }
    };

    const handleSelectAll = () => {
        setSelectedAdjustments(adjustments.map((campaign) => campaign.id));
    };

    const updateStatusVisuals = (updatedIds, status) => {
        setAdjustments(
            adjustments.map((adjustment) => {
                if (updatedIds.includes(adjustment.id)) {
                    return {
                        ...adjustment,
                        status: status,
                    };
                }
                return adjustment;
            })
        );
    };

    const cancelAllLoading = () => {
        setMultiLoading(false);
        setSingleLoading();
        setSelectedAdjustments([]);
    };

    return (
        <>
            <PageTitle title="Adjustments" expanded>
                <Link to="/rewards/adjustments/add">
                    <Button>Points Adjustment</Button>
                </Link>
            </PageTitle>
            <Row collapse expanded>
                <Column collapse>
                    <Row expanded>
                        <Column>
                                <SearchSection>
                                    <InputField
                                        name="search"
                                        placeholder="Search"
                                        style={{
                                            maxWidth: "400px",
                                            margin: "0",
                                        }}
                                        value={globalSearchValue}
                                        onChange={handleGlobalSearch}
                                    />
                                    <Button>
                                        Search
                                    </Button>
                                </SearchSection>
                        </Column>
                    </Row>
                    <Panel style={{ paddingTop: "0" }}>
                            <StyledTable className="dashboard-table">
                                <thead>
                                    <tr>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={
                                                orderByValue === "trade_team_id"
                                                    ? orderDirectionValue
                                                    : undefined
                                            }
                                            searchValue={
                                                searchByValue === "trade_team_id"
                                                    ? searchValue
                                                    : ""
                                            }
                                            onSortChange={handleSortChange}
                                            onSearchChange={handleSearch}
                                            hasSearch={true}
                                            name="trade_team_id"
                                        >
                                            Distrigo Number
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={
                                                orderByValue === "title"
                                                    ? orderDirectionValue
                                                    : undefined
                                            }
                                            searchValue={
                                                searchByValue === "title"
                                                    ? searchValue
                                                    : ""
                                            }
                                            onSortChange={handleSortChange}
                                            onSearchChange={handleSearch}
                                            hasSearch={true}
                                            name="title"
                                        >
                                            Title
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={false}
                                            searchValue={
                                                searchByValue ===
                                                "adjustment"
                                                    ? searchValue
                                                    : ""
                                            }
                                            onSearchChange={handleSearch}
                                            hasSearch={true}
                                            name="adjustment"
                                        >
                                            Points Adjustment
                                        </StyledTableHeader>
                                        <StyledTableHeader
                                            isSortable={true}
                                            sortDirection={
                                                orderByValue ===
                                                "updated_at"
                                                    ? orderDirectionValue
                                                    : undefined
                                            }
                                            onSortChange={handleSortChange}
                                            hasSearch={false}
                                            name="updated_at"
                                        >
                                            When Applicable
                                        </StyledTableHeader>
                                        <StyledTableHeader />
                                    </tr>
                                </thead>

                                {!loading && !!adjustments.length && (
                                    <tbody>
                                        {adjustments.map((adjustment) => (
                                            <StyledTableRow key={adjustment.id}>
                                                <td>
                                                    <Link
                                                        to={`/companies/${adjustment.customer_id}`}
                                                    >
                                                        {adjustment.trade_team_id}
                                                    </Link>
                                                </td>
                                                <td>{adjustment.title}</td>
                                                <td>
                                                    {adjustment.points.toLocaleString()}
                                                </td>
                                                <td>
                                                    {new Date(
                                                        adjustment.date
                                                    ).toDateString()}
                                                </td>
                                                <td style={{textAlign:"right"}}>
                                                    <div
                                                        style={
                                                            singleLoading
                                                                ? {
                                                                    minWidth:
                                                                        "125px",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }
                                                                : {
                                                                    minWidth:
                                                                        "75px",
                                                                }
                                                        }
                                                    >
                                                        <Button
                                                            onClick={() => {
                                                                history.push(
                                                                    `/rewards/adjustments/${adjustment.id}`
                                                                );
                                                            }}
                                                            name="primary"
                                                            actions={
                                                                [
                                                                ]
                                                            }
                                                        >
                                                            View
                                                        </Button>
                                                        {singleLoading ===
                                                            adjustment.id && (
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        "2em",
                                                                }}
                                                            >
                                                                <CubeLoader
                                                                    size={20}
                                                                    margin="0"
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                            </StyledTableRow>
                                        ))}
                                    </tbody>
                                )}
                            </StyledTable>

                            {loading && <CubeLoader />}

                            {!adjustments.length && !loading ? (
                                <p
                                    style={{
                                        textAlign: "center",
                                        margin: "3em 0",
                                    }}
                                >
                                    No results
                                </p>
                            ) : (
                                <PaginationContainer>
                                    <PerPageContainer>
                                        Per Page:
                                        <SelectField
                                            name="perPage"
                                            style={{ margin: "0 0 0 1em" }}
                                            value={perPageValue}
                                            options={[
                                                { value: 10, text: "10" },
                                                { value: 20, text: "20" },
                                                { value: 30, text: "30" },
                                                { value: 40, text: "40" },
                                                { value: 50, text: "50" },
                                            ]}
                                            onChange={handlePerPage}
                                        />
                                    </PerPageContainer>
                                    <Pagination
                                        currentPage={currentPage(
                                            selectedPageValue
                                        )}
                                        total={totalAdjustments}
                                        pageCount={totalPages}
                                        onPageChange={handlePageChange}
                                        previousLabel="&laquo;"
                                        nextLabel="&raquo;"
                                    />
                                    <TotalContainer>
                                        Total: {totalAdjustments}
                                    </TotalContainer>
                                </PaginationContainer>
                            )}
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default AdjustmentsList;
