import React, { useState, useRef, useEffect } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePopper } from "react-popper";
//@ts-ignore cortex
import styled, { css } from "styled-components";

const StyledButtonDropdownContainer = styled.div`
    display: inline-block;
    border-radius: 0;
    overflow: hidden;
`;

const StyledButtonDropdown = styled.button`
    background: #002a5b;
    cursor: pointer;
  border: none;
  color: #ffffff;
  text-align: left;
  font-family: inherit;
  min-height: 36px;
  font-size: 14px;
  text-transform: none;
  border-radius: 0px;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
  box-shadow: inset 0 0 0 2px#002a5b;
  padding: 0 2em;
  display: inline-block;
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba( 0,42,91,1 );
    &:hover,
    &:active,
    &:focus,
    &:visited {
        background-color: #002249;
        border: 1px solid #002249;
        box-shadow: none;
        outline: none;
    }
`;

const DropdownContainer = styled.div`
    background: white;
    padding: 0.5em 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0;
    min-width: 100px;
`;

const DropdownButton = styled.button`
    background: unset;
    width: 100%;
    outline: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    padding: 0.5em 0;

    &:hover,
    &:focus {
        background: #f5f5f5;
    }

    &:active {
        background: #002a5b;
        color: white;
    }

    ${(props) =>
        props.active &&
        `
        background: #002a5b;
        color: white;

        &:hover, &:focus {
            background: #002a5b;
            color: white;
        }
    `}
`;

const PopperElement = styled.div`
    z-index: 2;
`;

const ButtonDropdown = ({
    onClick,
    children,
    color = "default",
    actions = [],
    hidePrimaryAction,
}) => {
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const [isDropdownShowing, setIsDropdownShowing] = useState(false);
    const wrapperRef = useRef(null);
    const buttonWrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target) &&
                buttonWrapperRef.current &&
                !buttonWrapperRef.current.contains(event.target)
            ) {
                setIsDropdownShowing(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const { styles, attributes } = usePopper(referenceElement, popperElement);

    return (
        <>
            <StyledButtonDropdownContainer>
                {!hidePrimaryAction && (
                    <StyledButtonDropdown
                        type="button"
                        onClick={(event) => {
                            onClick();
                            event.stopPropagation();
                        }}
                        color={color}
                    >
                        {children}
                    </StyledButtonDropdown>
                )}

                {!!actions.length && (
                    <span ref={buttonWrapperRef}>
                        <StyledButtonDropdown
                            type="button"
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsDropdownShowing(!isDropdownShowing);
                            }}
                            ref={setReferenceElement}
                            color={color}
                        >
                            <FontAwesomeIcon icon={faCaretDown} />
                        </StyledButtonDropdown>
                    </span>
                )}
            </StyledButtonDropdownContainer>
            {isDropdownShowing && (
                <PopperElement
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <DropdownContainer ref={wrapperRef}>
                        {actions.map((action, index) => {
                            return (
                                <DropdownButton
                                    key={index}
                                    active={action.active}
                                    color={color}
                                    onClick={(event) => {
                                        setIsDropdownShowing(false);
                                        event.stopPropagation();
                                        action.onClick();
                                    }}
                                >
                                    {action.name}
                                </DropdownButton>
                            );
                        })}
                    </DropdownContainer>
                </PopperElement>
            )}
        </>
    );
};

export default ButtonDropdown;
