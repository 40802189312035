import React, {
    useEffect,
    useState,
} from "react";
import {
    Link,
    useHistory
} from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    FormikInput,
    FormikSelect,
    FormikDatePicker,
    FormikCheckbox,
    FormikTextarea,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";

import { CubeLoader } from "@cortexglobal/lens-components";
import DealerForm from "./DealerForm";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const EditDealer = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [dealerData, setDealerData] = useState();
    const history = useHistory();
    const addToast = useAddToast();

    const getDealer = () => {
        setLoading(true);

        axios
            .get(`/api/v1/dealers/${match.params.id}`)
            .then(({ data }) => {
                setDealerData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting dealer.",
                    showFor: 5000
                });
                setLoading(false);
            });
    };

    const handleSubmit = (value) => {
        setLoading(true);

        axios
            .put(`/api/v1/dealers/${match.params.id}`, value)
            .then(({ data }) => {
                console.log(data);
                history.push(`/dealers/${data.data.id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error updating your dealer.",
                    showFor: 5000
                });
            });
    };

    useEffect(() => {
        getDealer();
    }, []);

    return (
        <>
                    <PageTitle title={dealerData ? `Editing: ${dealerData.dealer_name} -  ${dealerData.dealer_code}` : `Editing`} expanded>
                        <Link to={`/dealers/${match.params.id}`}>
                            <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to Dealer</Button>
                        </Link>
                    </PageTitle>

            {loading && <CubeLoader />}

            {!loading && dealerData && (
                    <ContactContainer>
                        <Row>
                            <Column>
                                <DealerForm
                                    handleSubmit={handleSubmit}
                                    loading={loading}
                                    values={dealerData}
                                    formType="edit"
                                />
                            </Column>
                        </Row>
                    </ContactContainer>
            )}
        </>
    );
}

export default EditDealer;