import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewDealer from "./ViewDealer";
import DealersList from "./DealersList";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";
import CreateDealer from "./CreateDealer";
import EditDealer from "./EditDealer";

const Dealers = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route path={`${match.path}/create`} component={CreateDealer}/>
                <Route path={`${match.path}/edit/:id`} component={EditDealer}/>
                <Route path={`${match.path}/:id`} component={ViewDealer}/>
                <Route path={`${match.path}`} component={DealersList}/>
            </Switch>
        </>
    );
};

export default Dealers;
