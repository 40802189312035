import React, { useState, useEffect } from "react";
import {Link, useHistory} from "react-router-dom";
import { Formik, Form } from "formik";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    InputField,
    useAddToast,
} from "@cortexglobal/rla-components";
import PointsCampaignBuilder from "./PointsCampaignBuilder";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CampaignContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const AddPointsCampaign = (props) => {
    const addToast = useAddToast();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const handleSubmit = (value) => {
        setLoading(true);

        axios
            .post(`/api/v1/points`, value)
            .then(({ data }) => {
                history.push(`/rewards/campaigns/${data}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error creating your campaign.",
                    showFor: 5000
                });
            });
    };

    return (
        <>
            <PageTitle title="Add New Points Campaign" expanded>
                <Link to="/rewards/campaigns">
                    <Button><FontAwesomeIcon icon={ faCaretLeft } /> Back to Campaigns</Button>
                </Link>
            </PageTitle>

            <CampaignContainer>
                <Row>
                    <Column>
                        <PointsCampaignBuilder handleSubmit={handleSubmit} loading={loading}/>
                    </Column>
                </Row>
            </CampaignContainer>
        </>
    );
};

export default AddPointsCampaign;
